import React from 'react'
import PropTypes from 'prop-types'
import { Table, Empty, Button } from 'antd'

import { FloatingButton, AdminContent } from 'components'
import { generate, t, i18next } from 'tools'

import columns from './columns'

class ListsContent extends React.Component {

    static propTypes = {
        open:   PropTypes.bool,
        search: PropTypes.bool,

        lists: PropTypes.array,

        onClose:  PropTypes.func,
        onEdit:   PropTypes.func,
        onRemove: PropTypes.func,
        onAdd:    PropTypes.func
    }

    constructor ( props ) {
        super( props )

        this.state = {
            order: 'asc',
            sort:  'first_name',
            lists: props.lists || [],
            lock: null
        }

        this.set = generate.set( this )
        this.columns = columns( this )

        this.stub = {
            columns: this.columns.map( c => ({ ...c, render: c.stub || c.render }) ),
            lists: ( new Array(20) ).fill( 1 ).map( ( o,i ) => ({ id: i }) )
        }

        i18next.on( 'languageChanged', this.updateColumns )
    }

    updateColumns = () => this.columns = columns( this )

    componentDidUpdate ( prevProps ) {
        const
            { lists } = this.props

        ;( lists !== prevProps.lists ) && ( this.set.lists( lists ) )
    }

    rowProps = r => ({ 
        onClick: (
            this.props.lists && this.props.onRow 
                ? this.props.onRow( r )
                : null
        ),
        className: this.state.lock === r.id ? 'hover-lock' : '',
        style: r._removing ? { opacity: .5 } : {}
    })

    onFav    = r => () => console.log( 'fav', r )
    onRemove = r => () => console.log( 'remove', r )

    lock = list => state =>
        (
            state
                ? this.set.lock( list.id )
                : this.set.lock( null )
        )

    content = ordered =>
        (
            <Table
                sticky
                rowKey     = "id"
                pagination = { false }
                showSorterTooltip = { false }
                scroll = {{ x: '100%' }}

                dataSource = { ordered }
                columns    = { 
                    !!this.props.lists && !!this.state.lists
                        ? ( 
                            this.props.collapsed 
                                ? this.columns.slice( 0, 2 )
                                : this.columns 
                        )
                        : this.stub.columns
                }
                
                locale     = {{
                    emptyIcon: null,
                    emptyText: this.empty()
                }}

                onRow = { this.rowProps }
            />
        )

    empty = () =>
        (
            <Empty image={null} description={ t('global.noItemsMatchingFilters') }>
                {/*<Button type="primary" onClick={this.props.onReset}>Reset Filters</Button>*/}
            </Empty>
        )
    
    render () {
        const 
            { open, search } = this.props,
            { sort, order, lists } = this.state,
            ordered = this.props.lists && lists
                ? (
                    order === 'desc'
                        ? lists.sort( ( a, b ) => a[sort] < b[sort] )
                        : lists.sort( ( a, b ) => a[sort] > b[sort] )
                )
                : this.stub.lists
        
        return (
            <AdminContent>
                { this.content( ordered ) }
                {
                    ( this.props.lists && search ) && (
                        <div className="admin-footer">
                            <Button onClick={this.props.onReset}>
                                { t('global.resetSearch') }
                            </Button>
                        </div>
                    )
                }
                { ( !open ) && ( <FloatingButton onClick={this.props.onAdd} /> ) }
            </AdminContent>
        )
    }
}

export default ListsContent