import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { AdminHeader, ToolsRow, Spinner } from 'components'
import { t } from 'tools'

class ListsHeader extends React.Component {

    static propTypes = {
        loading:  PropTypes.bool,
        account:  PropTypes.object,
        search:   PropTypes.string,

        total:  PropTypes.number,
        hidden: PropTypes.number,

        onTogglePanel: PropTypes.func,
        onSearch:      PropTypes.func
    }

    render() {
        const 
            { search, loading, account } = this.props

        return (
            <AdminHeader account={account} onTogglePanel={this.props.onTogglePanel}>
                <div className="title-fix">
                    { loading && <Spinner style={{ marginRight: 20 }} /> }
                    {
                        loading
                            ? t('sharing.loadingLists')
                            : t('sharing.customAndSharedLists')
                    }
                </div>
                
                <ToolsRow>
                    <Input
                        allowClear
                        prefix = { <SearchOutlined /> }
                        value  = { search }
                        placeholder = { t('global.search') }

                        onChange = { this.props.onSearch }
                    />
                </ToolsRow>
            </AdminHeader>
        )
    }
}

export default ListsHeader