import moment from 'moment'

export const fav = handler => ( a, b, order ) => {
    const
        dir = order === 'descend' ? -1 : 1

    if ( a.id === 'reset' ) return dir
    if ( b.id === 'reset' ) return -dir
    if ( a.is_favourite && !b.is_favourite ) return -dir
    if ( !a.is_favourite && b.is_favourite ) return dir
    if ( a.is_todo && !b.is_todo ) return -dir
    if ( !a.is_todo && b.is_todo ) return dir

    return handler( a, b )
}

export const bool = key => ( a, b ) =>
    {
        if ( !a[key] && b[key] ) return 1
        if ( a[key] && !b[key] ) return -1
        return 0
    }

export const locale = key => ( a, b ) => 
    (
        typeof key === 'function'
            ? rawLocale( 'nkey' )( { nkey: key( a ) }, { nkey: key( b ) } )
            : rawLocale( key )( a, b )
    )

export const rawLocale = key => ( a, b ) =>
    {
        const
            check = empty( key )( a, b )

        if ( check !== 0 ) return check
        if ( none( key )( a, b ) ) return 0

        return a[key].localeCompare( b[key] )
    }

export const date = key => ( a, b ) =>
    {
        const
            check = empty( key )( a, b )

        if ( check !== 0 ) return check
        if ( none( key )( a, b ) ) return 0

        if ( moment(b[key]).isBefore( moment(a[key]) ) ) return -1
        if ( moment(a[key]).isBefore( moment(b[key]) ) ) return 1
        return 0
    }

export const unix = key => ( a, b ) =>
    {
        const
            check = empty( key )( a, b )

        if ( check !== 0 ) return check
        if ( none( key )( a, b ) ) return 0

        if ( moment.unix(b[key]).isBefore( moment.unix(a[key]) ) ) return -1
        if ( moment.unix(a[key]).isBefore( moment.unix(b[key]) ) ) return 1
        return 0
    }

export const weight = key => ( a, b ) => a[key] - b[key]
export const empty  = key => ( a, b ) => ~~!!b[key] - ~~!!a[key]
export const none   = key => ( a, b ) => !a[key] && !b[key]