import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Drawer } from 'antd'

import { Option, OptionItems, Spinner, SelectedOptionsGroup } from 'components'
import { generate, copy, t, strings } from 'tools'

import defaultFilters from './config'
import './wallets-filters.scss'

const
    { OptionSummary } = Option

class WalletsFilters extends React.Component {

    static propTypes = {
        open:      PropTypes.bool,
        filtered:  PropTypes.bool,
        corporate: PropTypes.bool,

        cards:   PropTypes.array,
        wallets: PropTypes.array,
        tags:    PropTypes.array,
        filters: PropTypes.object,

        onClose:  PropTypes.func,
        onFilter: PropTypes.func,
        onReset:  PropTypes.func,
        onShare:  PropTypes.func
    }

    constructor ( props ) {
        super( props )

        this.state = {
            sub: null,
            loading: false
        }

        this.set = generate.set( this )
    }

    componentDidUpdate ( prevProps ) {
        const
            { cards, wallets, open, filters } = this.props,
            { sub } = this.state

        if ( !!sub ) {
            if ( cards !== prevProps.cards || wallets !== prevProps.wallets ) {
                const
                    n = defaultFilters( this ),
                    found = n.find( option => option.id === sub.id )

                ;( found ) && ( this.set.sub( found ) )
            }
        }

        ;( filters !== prevProps.filters ) && ( this.set.loading( false ) )

        ;( open !== prevProps.open && !open ) && ( 
            setTimeout( () => this.set.sub( null ), 300 )
        )
        
    }

    cards = () =>
        {
            const
                { cards, corporate } = this.props

            return (
                corporate
                    ? cards
                        .filter( card => !card.is_deleted && !card.parent )
                        .map( card => ({
                            id: card.id,
                            name: `${card.first_name} ${card.last_name}`.trim(),
                            key: card.id,
                            tagged: card.is_archive && 'archived',
                            image: card.owner_user.avatar
                        }))
                    : cards.map( card => ({
                        id:    card.id,
                        name:  card.company,
                        desc:  card.position,
                        key:   card.id,
                        tagged: card.is_archive && 'archived',
                        image: card.company_owner 
                            ? card.company_owner.square_logo 
                            : null
                    }))
            ).sort( ( a, b ) => {
                if ( a.tagged !== 'archived' && b.tagged === 'archived' ) return -1
                if ( a.tagged === 'archived' && a.tagged === 'archived' ) return 1
                return 0
            })
        }

    extract = ( key, asArray, filterBy ) =>
        {
            const
                { wallets } = this.props

            let
                res = []

            if ( !wallets ) return res

            wallets.forEach( link => {
                ;( asArray )
                    ? ( !!link[key] && ( res = [ ...res, ...link[key] ] ) )
                    : res.push( link[key] )
            })

            return res.filter( ( item, index ) => !!item && (
                filterBy
                    ? res.find( i => !!i && i[filterBy] === item[filterBy] ) === item
                    : res.indexOf( item ) === index
                )
            )
        }

    apply = ( item, state ) =>
        {
            const
                filters = copy.deep( this.props.filters )

            filters[item.id] = state

            setTimeout( () => this.props.onFilter( filters ), 100 )
            this.set.loading( true )
        }

    delayApply = item => e => this.apply( item, e.target.checked )

    filter = option =>
        {
            const
                { filters } = this.props

            if ( option.sub ) {
                return (
                    option.items.length > 1
                        ? (
                            <OptionSummary
                                key    = { option.id }
                                option = { option }
                                filter = { filters }

                                onSelect = { this.set.delayed( 'sub' ) }
                            />
                        )
                        : null
                )
            }

            return (
                <OptionItems
                    key      = { option.id }
                    name     = { option.name }
                    data     = { option }
                    selected = { filters }

                    onCheck = { this.delayApply }
                />
            )
        }

    sub = option =>
        (
            <SelectedOptionsGroup
                option = { option }
                filter = { this.props.filters[option.id] || [] }

                onSelect = { this.delayApply }
                onAccept = { this.apply }
                onClose  = { this.set.delayed( 'sub' )( null ) }
            />
        )

    render () {
        const 
            { open, cards, wallets, filtered } = this.props,
            { sub, loading } = this.state

        if ( !cards || !wallets ) return null

        return (
            <Drawer
                title        = ""
                placement    = "right"
                closable     = { false }
                getContainer = { false }
                className    = "wallets-filters"
                width        = { 320 }

                visible = { open }
                style   = {{ position: 'absolute' }}

                onClose = { this.props.onClose }
            >
                <div className="options">
                    {
                        sub
                            ? this.sub( sub )
                            : defaultFilters( this ).map( this.filter )
                    }
                </div>
                <div className="filters-tools">
                    <div className="filters-share">
                        <Button 
                            type      = "primary"
                            className = "centered"

                            onClick = { this.props.onShare }
                        >
                            { t('wallets.createDynamicList') }
                        </Button>
                    </div>
                    <Button
                        type      = "link"
                        className = "fit-container high"
                        disabled  = { !filtered }

                        onClick = { this.props.onReset( sub ) }
                    >
                        { t(`global.reset${sub ? strings.capitalize( sub.id ) : 'All'}`) } 
                    </Button>
                </div>

                <div className={ classNames( "filters-overlay", loading && 'visible' ) }>
                    <Spinner />
                </div>
            </Drawer>
        )
    }
}

export default WalletsFilters