import React from 'react'

import { TabeeLogo } from 'components'

import './account-container.scss'

const AccountContainer = ({ children, title }) => (
        <div className="account-container">
            <div className="account-header">
                <div className="logo">
                    <TabeeLogo size="large" />
                </div>
                {
                    title && (
                        <div className="title-fix">
                            { title }
                        </div>
                    )
                }
            </div>
            <div className="account-content">
                { children }
            </div>
        </div>
)

export default AccountContainer