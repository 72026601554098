import moment from 'moment'

export const connections = show => item => !!item.recipient ? show : true
export const contacts = show => item => !item.recipient ? show : true

export const event = events => item =>
    {
        if ( events.length < 1 ) return true
        if ( !item.event ) return false
        return events.indexOf( item.event.id ) > -1
    }

export const owner = ids => item =>
    {
        if ( ids.length < 1 ) return true
        return ids.indexOf( item.owner_card ) > -1
    }

export const tags = ids => item =>
    {
        if ( ids.length < 1 ) return true
        
        let
            res = false

        ids.forEach( id => res = res || item.link_management.tags_list.find( tag => tag.id === id ) )

        return res
    }

export const dates = date => item =>
    {
        const
            created = moment.unix( item.created ),
            now = moment()

        switch ( date[0] ) {
            case 'week':
                return created.isSame( now, 'week' ) && created.isSame( now, 'year' )
            case 'prevweek':
                now.subtract( 1, 'week' )
                return created.isSame( now, 'week' ) && created.isSame( now, 'year' )
            case 'month':
                return created.isSame( now, 'month' ) && created.isSame( now, 'year' )
            case 'prevmonth':
                now.subtract( 1, 'month' )
                return created.isSame( now, 'month' ) && created.isSame( now, 'year' )
            case 'quarter':
                return now.isSame( created, 'year' ) && now.isSame( created, 'quarter' )
            case 'prevquarter':
                now.subtract( 1, 'quarter' )
                return now.isSame( created, 'year' ) && now.isSame( created, 'quarter' )
            case 'specific':
                const
                    d1 = !date[1] || ( created.isAfter( moment( date[1] ) ) || created.isSame( moment( date[1] ) ) ),
                    d2 = !date[2] || ( created.isBefore( moment( date[2] ) ) || created.isSame( moment( date[2] ) ) )

                return d1 && d2
            default:
                return true
        }
    }