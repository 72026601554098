import React, { useState, useEffect } from 'react'
import { Input, Button } from 'antd'
import classNames from 'classnames'

import { Spinner, LabelIn } from 'components'
import { strings, t, adjust } from 'tools'

const MailForm = ({ user, card, company, email, clearErrors, clearCard, searchCard, getCompany, setMail }) => {
    const
        [ form, $form ] = useState({ email }),
        [ working, $working ] = useState(false),
        [ error, $error ] = useState(false),

        fetchCompany = mail =>
            {
                getCompany( ( ( mail || form.email?.trim() ).split( '@' )[1] || '').trim() )
            },

        check = {
            email: ( email, ignoreCompany ) => (
                ignoreCompany
                    ? strings.validateEmail( email?.trim() )
                    : strings.validateEmail( email?.trim() ) && ( !company || !company.is_invalid )
            )
        },

        update = ( key, value ) =>
            {
                $form({
                    ...form,
                    [key]: value
                })
            },

        sendRequest = e =>
            {
                ;( e ) && ( e.preventDefault() )

                clearErrors()
                clearCard()
                $error( false )

                if ( !check.email( form.email, true ) ) {
                    $error( true )
                    return
                }

                adjust.track( 'form_started' )
                $working( true )
                searchCard( form.email.trim() )
            }

    useEffect(() => {
        if ( !user ) return

        $form({
            email: user.email
        })

        ;( user.email ) && ( fetchCompany(user.email) )
        // eslint-disable-next-line
    },[user])

    useEffect(() => {
        if ( company ) {
            if ( company.is_invalid ) {
                $error( true )
                $working( false )
                return
            }

            setMail( form.email )
        }
        // eslint-disable-next-line
    },[company])

    useEffect(() => {
        if ( card ) {
            fetchCompany( form.email )
        }
        //eslint-disable-next-line
    },[card])

    return (
        <div className="public-card-form">
            <h2><span>{t('publicCard.SHARE_DETAILS')}</span></h2>
            
            <form className="pcf-form" onSubmit={sendRequest}>
                <div className="pcf-form-item">
                    <LabelIn label={t('publicCard.ENTER_WORK_EMAIL')}>
                        <Input 
                            value        = { form.email }
                            autoComplete = 'email'
                            disabled     = { working }
                            className    = {classNames('force-lowercase', error && 'has-error' )}

                            onChange = { e => update( 'email', e.target.value.replace( / /g, '' ) ) }
                            onBlur = {() => (
                                (
                                    form.email && 
                                    check.email( form.email, true )
                                ) && ( fetchCompany() )
                            )}
                        />
                    </LabelIn>
                </div>
                {
                    working
                        ? <Spinner />
                        : (
                            <Button 
                                className = "pcf-submit"
                                onClick={sendRequest}
                            >
                                {t('publicCard.SUBMIT')}
                            </Button>
                        )
                }
            </form>
        </div>
    )
}

export default MailForm