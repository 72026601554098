import React from 'react'
import { LoginForm } from '@tabee/frontend-auth'

import { 
    StaticPage, 
    SignMethods,
    SiteNavigation,
    SocialNetworks,
    AccountContainer,
    AccountFooter
} from 'components'

import { ui, t } from 'tools'

import LoginLinks from './LoginLinks'
import connector from './connector'
import './login.scss'

class Login extends React.Component {

    componentDidUpdate ( prevProps ) {
        const
            { logged, lastTry, history, info } = this.props

        if ( lastTry !== prevProps.lastTry || logged !== prevProps.logged ) {
            if ( logged ) {
                this.props.setAccount( info.id )
                history.push( '/' )
            } else {
                ui.focusFirstInput()
            }
        }
    }
    
    render () {
        const 
            { msg } = this.props

        return (
            <StaticPage>

                <SignMethods />

                <AccountContainer title={t('login.loginTitle')}>

                    <LoginForm
                        withRemember
                        
                        i18nPrefix = 'login'
                        inputClassName = 'dark-theme'
                        signInType = '2fa'

                        errorMessage = { msg } 
                        translator = { t }
                        
                        onResult = { this.props.setAuthData }
                    />

                    <LoginLinks />

                </AccountContainer>

                <AccountFooter>
                    <SiteNavigation />
                    <SocialNetworks />
                </AccountFooter>

            </StaticPage>
        )
    }
}

export default connector( Login )