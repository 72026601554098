import React from 'react'
import { Form, Button, Input } from 'antd'
import TabeeAuth from '@tabee/frontend-auth'

import { 
    StaticPage, 
    SignMethods,
    SiteNavigation,
    SocialNetworks,
    AccountContainer,
    AccountFooter
} from 'components'

import { generate, request, ui, t, strings, users } from 'tools'

import connector from './connector'

class SignUp extends React.Component {

    constructor ( props ) {
        super( props )

        this.state = {
            username: '',
            email:    '',
            message:  '',
            locked:   false,
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        this.props.setMessage( '' )
        ui.focusFirstInput()
    }
    
    componentDidUpdate ( prevProps ) {
        const
            { msg, registered, lastTry, history } = this.props

        if ( lastTry !== prevProps.lastTry || msg !== prevProps.msg ) {
            ;( !!msg ) && ( this.set.locked( false ) )
            this.set.message( msg )

            if ( registered ) {
                if ( request.storage.get( 'redirect' ) ) {
                    history.push( request.storage.get( 'redirect' ) )
                    request.storage.remove( 'redirect' )
                } else {
                    history.push( '/' )
                }
            } else {
                ui.focusFirstInput()
            }
        }
    }

    register = () => 
        {
            this.props.setMessage( '' )

            const
                { locked, username, email } = this.state

            if ( locked || !username || !email ) return

            this.set.locked( true )
            TabeeAuth.register({ username, email })
                .then( data => this.props.setAuthData({ 
                    ...data, 
                    registered: data.status,                    
                    current: data.status ? users.prepare( data.user ) : null,
                    account: data.status ? data.user.id : null
                })
            )
        }

    next = () => [].slice.call( document.querySelectorAll( 'input' ) )[1].focus()

    makeUsername = () =>
        {
            const
                { email, username } = this.state
                
            if ( !!username ) return

            const
                res = email.replace( /\.com$/, '' ).replace( /[^0-9a-zA-Z]+/g, '' )

            this.set.username( res )
        }
    
    render () {
        const 
            { locked, username, email, message } = this.state,
            cls = locked ? "locked" : ''

        return (
            <StaticPage extraClassName={cls}>

                <SignMethods hideSignUp />

                <AccountContainer title={t('register.registerTitle')}>
                    <Form>

                        <Form.Item>
                            <Input
                                autoFocus
                                className   = "dark-theme"
                                placeholder = { t('global.email') }
                                disabled    = { locked }
                                value       = { email }

                                onChange     = { this.set.byval( 'email' ) }
                                onBlur       = { this.makeUsername }
                                onPressEnter = { this.next }
                            />
                        </Form.Item>

                        <Form.Item>
                            <Input
                                className   = "dark-theme"
                                placeholder = { t('global.username') }
                                disabled    = { locked }
                                value       = { username }

                                onChange     = { this.set.byval( 'username' ) }
                                onPressEnter = { this.register }
                            />
                        </Form.Item>

                        <Form.Item className="offset">
                            <Button
                                className = "fit-container"
                                type      = "primary"
                                disabled  = { locked || !username || !email }
                                
                                onClick = { this.register }
                            >
                                { t('register.signUp') }
                            </Button>
                        </Form.Item>

                    </Form>

                    {
                        message && (
                            <div style={{ textAlign: 'center' }}>
                                { t(`errors.${strings.toKey( message )}`) }
                            </div>
                        )
                    }

                </AccountContainer>

                <AccountFooter>
                    <SiteNavigation />
                    <SocialNetworks />
                </AccountFooter>

            </StaticPage>
        )
    }
}

export default connector( SignUp )