import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Input, Button, Form } from 'antd'

import { generate, request, t } from 'tools'

import './passcode-popover.scss'

class PasscodePopover extends React.Component {
    
    static propTypes = {
        visible:   PropTypes.bool,
        lock:      PropTypes.bool,
        loading:   PropTypes.bool,
        checked:   PropTypes.bool,
        login:     PropTypes.bool,
        wrongCode: PropTypes.any,

        user:     PropTypes.object,  
        list:     PropTypes.object,
        passcode: PropTypes.string,

        onAuth:     PropTypes.func,
        onPasscode: PropTypes.func,
        onAccess:   PropTypes.func
    }

    constructor ( props ) {
        super( props )

        this.state = {
            signin: props.login,
            locked: false,

            message: '',
            username: '',
            password: '',
        }

        this.set = generate.set( this )
    }

    componentDidUpdate ( prevProps ) {
        const
            { user } = this.props

        ;( user !== prevProps.user && user ) && ( this.set.signin( false ) )
    }

    passcode = e => this.props.onPasscode( e.target.value )
    next = () => document.querySelector( 'input[type="password"]' ).focus()

    update = ( key, handler ) => e => 
        {
            this.set.message( '' )
            this.set[handler]( key )( e )
        }

    login = () => 
        {
            const
                { locked, username, password } = this.state

            if ( locked || !username || !password ) return

            this.set.state({
                locked: true,
                message: ''
            })

            this.props.onAuth({ username, password })
        }

    signUp = () =>
        {
            request.storage.set( 'redirect', window.location.pathname )
            window.location = '/sign-up'
        }

    signin = () =>
        {
            const
                { user } = this.props,
                { locked, username, password } = this.state

            return (
                <React.Fragment>
                    <h1>{ t('passcode.signIntoAccount') }</h1>
                    <p><span className="link" onClick={() => this.set.signin( false )}>{ t('global.goBack') }</span></p>
                    <section className="passcode-popover-form">
                        <Form>

                            <Form.Item>
                                <Input
                                    autoFocus
                                    className    = "dark-theme"
                                    placeholder  = { t('global.email') }
                                    disabled     = { locked }
                                    value        = { username }

                                    onChange     = { this.update( 'username', 'byval' ) }
                                    onPressEnter = { this.next }
                                />
                            </Form.Item>

                            <Form.Item>
                                <Input.Password
                                    type        = "password"
                                    className   = "dark-theme"
                                    placeholder = { t('global.password') }
                                    disabled    = { locked }
                                    value       = { password }

                                    onChange     = { this.update( 'password', 'byval' ) }
                                    onPressEnter = { this.login }
                                />
                            </Form.Item>

                            <Form.Item className="offset">
                                <Button
                                    className = "fit-container"
                                    type      = "primary"
                                    disabled  = { locked || !username || !password }

                                    onClick = { this.login }
                                >
                                    { t('login.signIn') }
                                </Button>
                            </Form.Item>

                        </Form>
                    </section>
                    {
                        !user && (
                            <footer>
                                { t('login.dontHaveAccount') } <span className="link" onClick={this.signUp}>{ t('login.loginSignUp') }</span>.
                            </footer>
                        )
                    }
                </React.Fragment>
            )
        }

    access = () =>
        {
            const
                { user, passcode, loading, wrongCode } = this.props

            return (
                <React.Fragment>
                    <h1>{ t('passcode.listIsProtected') }</h1>
                    <p>{ t('passcode.pleaseEnterPasscode') }{ !user && ( t('passcode.orLogIn') ) }.</p>
                    <section className="passcode-popover-form">
                        <div className="passcode-popover-form-row">
                            <Input.Password
                                className   = "dark-theme"
                                placeholder = { t('global.passcode') }
                                disabled    = { loading }

                                value = { passcode }

                                onChange = { this.passcode }
                            />
                            <Button type="primary" disabled={ loading || !passcode} onClick={this.props.onAccess}>
                                { t('passcode.viewList') }
                            </Button>
                        </div>
                    </section>
                    {
                        ( !!wrongCode && wrongCode.status > 299 ) && ( 
                            <span className="passcode-error">
                                { t('passcode.wrongPasscode') }
                            </span>
                        )
                    }
                    {
                        !user && (
                            <footer>
                                { t('register.haveAccount') } <span className="link" onClick={() => this.set.signin( true )}>{ t('register.signInNow') }</span>.
                            </footer>
                        )
                    }
                </React.Fragment>
            )
        }

    checking = () =>
        (
            <React.Fragment>
                <h1>{ t('passcode.checkingAccess') }</h1>
                <p>{ t('passcode.pleaseWait') }</p>
            </React.Fragment>
        )

    render () {
        const
            { visible, lock, checked } = this.props,
            { signin } = this.state,
            cls = classNames( 'passcode-popover', visible && 'visible' )

        return (
            <div className={cls}>
                {
                    lock && (
                        <div className="passcode-popover-mask" />
                    )
                }
                <div className="passcode-popover-content">
                    {
                        !checked
                            ? this.checking()
                            : (
                                signin
                                    ? this.signin()
                                    : this.access()
                            )
                    }
                </div>
            </div>
        )
    }
}

export default PasscodePopover