/* IMPORT */
import { connect } from 'react-redux'
import { sharingActions, authActions, userActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    user:      state.user.info,
    companies: state.user.companies,
    account:   state.user.account,
    current:   state.user.current,
    cards:     state.user.cards,
    tags:      state.user.tags,
    list:      state.sharing.current,
    all:       state.sharing.lists,
    passerr:   state.sharing.error,
    neterr:   state.sharing.neterror
})

const allActions = {
    ...authActions,
    ...sharingActions,
    ...userActions
}

export default connect( mapStateToProps, allActions )