import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Empty } from 'antd'

import { AdminContent, Table } from 'components'
import { generate, hash, compare, t, i18next } from 'tools'

import columns from './columns'
import './wallets-content.scss'

const
    defConfig = {
        type: 'unified',
        sort: 'firstName',
        
        photo:      false,
        logo:       false,
        first_name: true,
        last_name:  true,
        company:    true,
        title:      true,

        phone:    true,
        email:    true,
        tags:     true,
        event:    true,
        industry: true
    },
    columnsConfig = localStorage.getItem( `_tabee_columns_config` )
        ? JSON.parse( localStorage.getItem( `_tabee_columns_config` ) )
        : defConfig,
    exCols = ( type, show ) =>
        {
            if ( type === 'separate' ) return show

            const
                exclude = [ 'photo', 'logo', 'first_name', 'last_name', 'company', 'title' ]
            
            return (
                Array.isArray( show )
                    ? show.filter( key => exclude.indexOf( key.toLowerCase() ) < 0 )
                    : show.split( ',' ).filter( key => exclude.indexOf( key.toLowerCase() ) < 0 ).join( ',' )
            )
        },
    applyHash = columns =>
        {
            const
                res = { ...columns },
                params = hash.extract(),
                show = params.show
                    ? params.show.split( ',' )
                    : [ 'photo', 'logo', 'first_name', 'last_name', 'company', 'title', 'phone', 'email', 'tags', 'event', 'industry']

            Object.keys( res ).forEach( key => {
                res[key] = ( show.indexOf( key.toLowerCase() ) > -1 )
            })

            res.type = params.type || columns.type
            res.sort = params.sort || columns.sort

            return res
        }

class WalletsContent extends React.Component {

    static propTypes = {
        all:      PropTypes.array,
        wallets:  PropTypes.array,
        tags:     PropTypes.array,
        selected: PropTypes.array,
        filters:  PropTypes.object,

        search:    PropTypes.string,
        open:      PropTypes.bool,
        filtered:  PropTypes.bool,
        corporate: PropTypes.bool,

        onReset:  PropTypes.func,
        onRow:    PropTypes.func,
        onClose:  PropTypes.func,
        onFilter: PropTypes.func,
        onSelect: PropTypes.func
    }

    static defaultProps = {
        selected: []
    }

    constructor ( props ) {
        super( props )

        this.state = {
            columns: applyHash( compare.join( columnsConfig, defConfig ) ),
            cols: []
        }

        this.set = generate.set( this )
        this.state.cols = columns( this )
        
        this.stub = {
            columns: () => this.state.cols.map( c => ({ ...c, render: c.stub, lockWidth: c.lockWidth === 'deny' ? false : true }) ),
            wallets: ( new Array(20) ).fill( 1 ).map( ( o,i ) => ({ id: i }) )
        }
    }

    componentDidMount () {
        i18next.on( 'languageChanged', this.updateColumns )
    }

    componentWillUnmount () {
        i18next.off( 'languageChanged', this.updateColumns )
    }

    updateColumns = () => this.set.cols( columns( this ) )

    componentDidUpdate ( prevProps, prevState ) {
        if ( this.state.columns !== prevState.columns ) { 
            const
                { type, sort, ...rest } = this.state.columns

            localStorage.setItem( `_tabee_columns_config`, JSON.stringify( this.state.columns ) )
            hash.apply({ type, sort: type === 'unified' ? sort : null, show: exCols( type, hash.join( rest ) ) }, true)

            ;( sort !== prevState.columns.sort ) && (
                this.set.state({
                    cols: columns( this )
                })
            )
        }
    }

    toggle = id => () =>
        {
            const
                { selected } = this.props

            ;( selected.indexOf( id ) > -1 )
                ? selected.splice( selected.indexOf( id ), 1 )
                : selected.push( id )

            this.props.onSelect([ ...selected ])
        }

    allChecked = () => this.props.wallets && this.props.selected.length === this.props.wallets.length

    toggleAll = () =>
        {
            const
                { wallets } = this.props,
                check = !this.allChecked()

            ;( check )
                ? this.props.onSelect( wallets.map( w => w.id ) )
                : this.props.onSelect( [] )
        }

    rowProps = r => ({
        className: classNames(
            ( this.props.current && this.props.current.id === r.id ) && 'current-row'
        ),
        onClick: (
            this.props.wallets 
                ? this.props.onRow( r )
                : null
        ) 
    })

    setSort = value => this.setColumn( 'sort', value )

    setColumn = ( id, value ) =>
        {
            const
                sep = [ 'first_name', 'last_name', 'company', 'title', 'photo', 'logo' ],
                res = {
                    ...this.state.columns,
                    [id]: value
                }

            if ( sep.indexOf( id ) > -1 ) {
                res.type = 'separate'
            }

            if ( id === 'type' && value === 'separate' ) {
                res.first_name = true
                res.last_name = true
                res.company = true
                res.title = true
            }

            this.set.columns( res, () => this.set.cols( columns( this ) ) )
        }

    saveWidthConfig = data =>
        {
            const
                toSave = {}

            data.forEach( col => toSave[col.dataIndex] = col.width )

            localStorage.setItem( '_tabee_wallets_width', JSON.stringify( toSave ) )
        }

    content = ordered =>
        (
            <Table
                sticky
                rowKey     = "id"
                pagination = { false }
                showSorterTooltip = { false }
                scroll = {{ x: '100%' }}

                dataSource = {[
                    ...ordered,
                    {
                        id: 'reset',
                        full: true,
                        content: () => ({
                            props: {
                                colSpan: this.state.cols ? this.state.cols.length - 1 : 1
                            },
                            children: (
                                ( this.props.filtered || this.props.search ) && (
                                    <div className="admin-footer">
                                        <Button onClick={this.props.onReset()}>
                                            {
                                                this.props.filtered 
                                                    ? ( 
                                                        this.props.search 
                                                            ? t('global.resetSearchAndFilters') 
                                                            : t('global.resetFilters')
                                                    )
                                                    : t('global.resetSearch')
                                            }
                                        </Button>
                                    </div>
                                )
                            )
                        })
                    } 
                ]}

                columns    = { 
                    !!this.props.wallets
                        ? this.state.cols
                        : this.stub.columns()
                }
                
                locale     = {{
                    emptyIcon: null,
                    emptyText: this.empty()
                }}

                onRow = { this.rowProps }
                onResize = { this.saveWidthConfig }
            />
        )

    empty = () =>
        (
            <Empty image={null} description={t('global.noItemsMatchingFilters')}>
                {/*<Button type="primary" onClick={this.props.onReset}>Reset Filters</Button>*/}
            </Empty>
        )
    
    render () {
        const 
            { wallets } = this.props,
            
            ordered = !!wallets
                ? wallets
                : this.stub.wallets

        return (
            <AdminContent className="wallets-content">
                { this.content( ordered ) }
            </AdminContent>
        )
    }
}

export default WalletsContent