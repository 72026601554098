import React from 'react'
import classNames from 'classnames'

import { strings } from 'tools'

import './option.scss'

const OptionGroup = ({ name, children }) => (
    <div className="option-group">
        {
            name && (
                <div className="option-group-label">
                    {name}
                </div>
            )
        }
        <div className="option-group-content">
            {children}
        </div>
    </div>
)

const OptionItem = ({ children, className, onClick }) => (
    <label className={classNames("option-item", className)} onClick={onClick}>
        {children}
    </label>
)

const OptionName = ({ children }) => (
    <span className="option-name">
        {children}
    </span>
)

const OptionSection = ({ name, children, onClick }) => (
    <div className="options-section" onClick={onClick}>
        {
            name && (
                <div className="option-group-label">
                    {name}
                </div>
            )
        }
        <div className="option-section-content">
            {children}
        </div>
    </div>
)

const OptionSummary = ({ option, filter, onSelect }) => {
    const 
        { items, selection, all } = option,
        selected = filter 
            ? ( filter[option.key || option.id] || [] )
            : []

    let 
        checked,
        summary

    if ( selection ) {
        checked = selected.length

        summary = checked > 0 
            ? `${strings.encount( checked, option.counter || 'item' )} selected`  
            : all
    } else {
        checked = items.find( item => item.id === selected[0] )

        summary = checked 
            ? checked.name 
            : (
                !!option.all
                    ? option.all
                    : (
                        items[0]
                            ? items[0].name
                            : '...'
                    )
            )
    }

    return (
        <div className="option-group">
            {
                option.name && (
                    <div className="option-group-label">
                        <span>
                            {option.name}
                        </span>
                    </div>
                )
            }
            <div className="option-summary-content">
                <div className="option-item" onClick={onSelect(option)}>
                    { option.icon }
                    <div>{summary}</div>
                    <div>
                        <span className="more" />
                    </div>
                </div>
            </div>
        </div>
    )
}

const exp = {
    OptionGroup,
    OptionItem,
    OptionName,
    OptionSection,
    OptionSummary
}

export default exp