import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './navigation-item.scss'

class NavigationItem extends React.Component  {

    static propTypes = {
        extraClassName: PropTypes.string,
        link: PropTypes.string,
        name: PropTypes.string,
        icon: PropTypes.any,

        active:   PropTypes.bool,
        isParent: PropTypes.bool,

        onClick: PropTypes.func
    }

    render () {
        const
            { active, isParent, extraClassName, icon, name } = this.props,
            cls = classNames(
                'navigation-item',
                active && 'active',
                extraClassName
            ),
            contentCls = classNames(
                'name',
                isParent && 'parent-name'
            )

        return (
            <div className={cls} onClick={this.props.onClick} title={name}>
                {
                    icon && (
                        <span className="icon">
                            {icon}
                        </span>
                    )
                }
                <span className={contentCls}>
                    {name}
                </span>
            </div>
        )
    }
}

export default NavigationItem