import React from 'react'

import { ListItem } from 'components'
import { t } from 'tools'

const LoginLinks = () => 
{
    return (
        <div className="login-links">
            <ListItem link="/restore-password" extraClassName="login-link">
               { t('login.forgotYourPassword') }
            </ListItem>
            {/*<ListItem link="/code-login" extraClassName="login-link">
                Sign in with a code instead
            </ListItem>*/}
        </div>
    )
}

export default LoginLinks