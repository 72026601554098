import moment from 'moment'
import config from 'config'

export const by = ( key, list, value ) => list.find( item => item[key] === value )

export const predate = data =>
    {
        if ( !data.date_from ) return [ 'any' ]

        /* БОЛЬШЕ ИФОВ БОГУ ИФОВ */
        
        if (
            data.date_from === moment().startOf( 'week' ).format( config.ui.dates.date ) &&
            data.date_to === moment().endOf( 'week' ).format( config.ui.dates.date )
        ) {
            return [ 'week', data.date_from, data.date_to ]
        }
        
        if (
            data.date_from === moment().subtract( 1, 'week' ).startOf( 'week' ).format( config.ui.dates.date ) &&
            data.date_to === moment().subtract( 1, 'week' ).endOf( 'week' ).format( config.ui.dates.date )
        ) {
            return [ 'prevweek', data.date_from, data.date_to ]
        }
        
        if (
            data.date_from === moment().startOf( 'month' ).format( config.ui.dates.date ) &&
            data.date_to === moment().endOf( 'month' ).format( config.ui.dates.date )
        ) {
            return [ 'month', data.date_from, data.date_to ]
        }
        
        if (
            data.date_from === moment().subtract( 1, 'month' ).startOf( 'month' ).format( config.ui.dates.date ) &&
            data.date_to === moment().subtract( 1, 'month' ).endOf( 'month' ).format( config.ui.dates.date )
        ) {
            return [ 'prevmonth', data.date_from, data.date_to ]
        }
        
        if (
            data.date_from === moment().startOf( 'quarter' ).format( config.ui.dates.date ) &&
            data.date_to === moment().endOf( 'quarter' ).format( config.ui.dates.date )
        ) {
            return [ 'quarter', data.date_from, data.date_to ]
        }
        
        if (
            data.date_from === moment().subtract( 1, 'quarter' ).startOf( 'quarter' ).format( config.ui.dates.date ) &&
            data.date_to === moment().subtract( 1, 'quarter' ).endOf( 'quarter' ).format( config.ui.dates.date )
        ) {
            return [ 'prevquarter', data.date_from, data.date_to ]
        }

        return [ 'specific', data.date_from, data.date_to ]
    }

export const dates = ( filter, data ) =>
    {
        switch ( filter[0] ) {
            case 'week':
                data.date_from = moment().startOf( 'week' ).format( config.ui.dates.date )
                data.date_to = moment().endOf( 'week' ).format( config.ui.dates.date )
                break
            case 'prevweek':
                data.date_from = moment().subtract( 1, 'week' ).startOf( 'week' ).format( config.ui.dates.date )
                data.date_to = moment().subtract( 1, 'week' ).endOf( 'week' ).format( config.ui.dates.date )
                break
            case 'month':
                data.date_from = moment().startOf( 'month' ).format( config.ui.dates.date )
                data.date_to = moment().endOf( 'month' ).format( config.ui.dates.date )
                break
            case 'prevmonth':
                data.date_from = moment().subtract( 1, 'month' ).startOf( 'month' ).format( config.ui.dates.date )
                data.date_to = moment().subtract( 1, 'month' ).endOf( 'month' ).format( config.ui.dates.date )
                break
            case 'quarter':
                data.date_from = moment().startOf( 'quarter' ).format( config.ui.dates.date )
                data.date_to = moment().endOf( 'quarter' ).format( config.ui.dates.date )
                break
            case 'prevquarter':
                data.date_from = moment().subtract( 1, 'quarter' ).startOf( 'quarter' ).format( config.ui.dates.date )
                data.date_to = moment().subtract( 1, 'quarter' ).endOf( 'quarter' ).format( config.ui.dates.date )
                break
            case 'specific':
                data.date_from = filter[1]
                data.date_to = filter[2]
                break
            case 'any':
            default:
                return null
        }
    }