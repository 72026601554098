import React, { useState } from 'react'
import { Button } from 'antd'
import GoogleLogin from 'react-google-login'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import AppleSignin from 'react-apple-signin-auth'
import VkLogin from 'react-vkontakte-login'
import axios from 'axios'

import { Spinner } from 'components'
import appConfig from 'config'

const SocialAuth = ({ config, onGoogle, onLinkedin, onFacebook, onApple, onVK }) => {
    const
        [ linkedin, $linkedin ] = useState(false),

        { linkedInLogin } = useLinkedIn({
            clientId: '7883zgun4eun2q',
            redirectUri: `${window.location.origin}/linkedin`,
            scope: 'r_liteprofile r_emailaddress',
            onSuccess: code => {
                $linkedin( true )
                axios.post( 
                    `${appConfig.api.url}/api/oauth/linkedin`,
                    {
                        grant_type: 'authorization_code',
                        code,
                        client_secret: 'r8R2N4HDtHbMybSU',
                        client_id: '7883zgun4eun2q',
                        redirect_uri: `${window.location.origin}/linkedin`,
                    },
                ).then(resp => {
                    const
                        { data } = resp

                    axios.get(
                        `${appConfig.api.url}/api/oauth/linkedin_proxy`,
                        {
                            headers: {
                                Authorization: `Bearer ${data.access_token}`
                            }
                        }
                    ).then( infoResp => {
                        axios.get(
                            `${appConfig.api.url}/api/oauth/linkedin_proxy`,
                            {
                                headers: {
                                    Authorization: `Bearer ${data.access_token}`,
                                    'X-LI-URL': 'https://api.linkedin.com/v2/clientAwareMemberHandles?q=members&projection=(elements*(primary,type,handle~))'
                                }
                            }
                        ).then( mailResp => {
                            $linkedin( false )
                            onLinkedin({
                                ...infoResp.data,
                                ...mailResp.data
                            })
                        })
                    })
                })
            },
            onError: error => {
                console.log(error);
            },
        }),

        mapping = {
            'vk': (
                <VkLogin
                    key      = 'vk'
                    apiId    = "8033350"
                    callback = { onVK }
                    render   = {({ onClick }) => (
                        <Button className='pcf-social-auth vk' onClick={onClick}>
                            <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <circle id="a" cx="20" cy="20" r="20"/>
                                </defs>
                                <g fill="none" fillRule="evenodd">
                                    <mask id="b" fill="#fff">
                                        <use xlinkHref="#a"/>
                                    </mask>
                                    <use fill="#0077B7" xlinkHref="#a"/>
                                    <path d="M20.007 0C11.657 0 9.215.009 8.74.048c-1.713.142-2.78.412-3.941.99A7.984 7.984 0 0 0 2.5 2.722C1.23 4.04.463 5.661.184 7.587.05 8.523.01 8.713.001 13.492c-.003 1.592 0 3.689 0 6.5 0 8.345.01 10.786.05 11.26.138 1.667.4 2.717.953 3.865 1.059 2.197 3.08 3.846 5.46 4.461.825.213 1.736.33 2.905.385.495.022 5.543.037 10.595.037 5.051 0 10.102-.006 10.585-.03 1.354-.064 2.14-.17 3.01-.395a8.374 8.374 0 0 0 5.46-4.47c.543-1.12.818-2.21.943-3.791.027-.345.038-5.84.038-11.327 0-5.489-.012-10.973-.04-11.318-.126-1.606-.4-2.686-.961-3.828a7.929 7.929 0 0 0-1.712-2.346C35.964 1.231 34.345.462 32.417.183c-.934-.135-1.12-.175-5.9-.183h-6.51Z"
                                    fill="#07F"
                                    fillRule="nonzero"
                                    mask="url(#b)"/>
                                        <g fill="#FFF">
                                            <path d="M20.438 28C13.263 28 9.17 22.745 9 14h3.594c.118 6.418 2.768 9.137 4.866 9.698V14h3.384v5.536c2.073-.239 4.25-2.761 4.985-5.536h3.384a10.95 10.95 0 0 1-1.62 4.003 10.188 10.188 0 0 1-2.984 2.976 10.395 10.395 0 0 1 3.4 2.893c.94 1.224 1.604 2.6 1.991 4.128h-3.725a7.128 7.128 0 0 0-.771-1.847 6.78 6.78 0 0 0-1.238-1.53 6.405 6.405 0 0 0-1.598-1.083 6.129 6.129 0 0 0-1.824-.543V28h-.406Z"/>
                                        </g>
                                </g>
                            </svg>
                        </Button>
                    )}
                />
            ),
            'google': (
                <GoogleLogin
                    key        = 'google'
                    className  = "pcf-social-auth google"
                    clientId   ="448891810866-d3mg98g0e6knbki3ta148ah3s7kevthd.apps.googleusercontent.com"
                    buttonText = "Fill up with Google"

                    onSuccess = { onGoogle }
                    onFailure = { onGoogle }
                />
            ),
            'linkedin': (
                <Button
                    key       = 'linkedin'
                    className = 'pcf-social-auth linkedin'

                    onClick = { !linkedin && linkedInLogin }
                >
                    {
                        linkedin
                            ? <Spinner />
                            : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
                                    <g fill="none" fillRule="evenodd">
                                        <path fill="#0077B7" d="M20 0c11.045695 0 20 8.954305 20 20s-8.954305 20-20 20S0 31.045695 0 20 8.954305 0 20 0Zm-3.9694194 14.9766719h-3.7343993V27h3.7343993V14.9766719Zm9.4945669-.2981338c-1.8148091 0-3.0302701.9965785-3.5290283 1.9413686h-.051692v-1.6432348h-3.5737349V27h3.7260168v-5.9472784c0-1.5676516.2961813-3.0877138 2.2353306-3.0877138 1.9140019 0 1.9391494 1.7930015 1.9391494 3.1870917V27H30v-6.5939347c0-3.2388802-.6971437-5.7275272-4.4748525-5.7275272ZM14.1654766 9C12.9667805 9 12 9.9713841 12 11.1667185c0 1.1967341.9667805 2.1681182 2.1654766 2.1681182 1.1917106 0 2.1612853-.9713841 2.1612853-2.1681182C16.3267619 9.9713841 15.3571872 9 14.1654766 9Z"/>
                                        <path fill="#FFF" d="M30 27h-3.7288109v-5.8479005c0-1.3940902-.0251475-3.1870917-1.9391494-3.1870917-1.9391493 0-2.2353306 1.5200622-2.2353306 3.0877138V27h-3.7260168V14.9766719h3.5737349v1.6432348h.051692c.4987582-.9447901 1.7142192-1.9413686 3.5290283-1.9413686C29.3028563 14.6785381 30 17.1671851 30 20.4060653V27Z" opacity=".9"/>
                                        <path fill="#FFF" d="M14.1654766 13.3348367C12.9667805 13.3348367 12 12.3634526 12 11.1667185 12 9.9713841 12.9667805 9 14.1654766 9c1.1917106 0 2.1612853.9713841 2.1612853 2.1667185 0 1.1967341-.9695747 2.1681182-2.1612853 2.1681182Z" opacity=".9"/>
                                        <polygon fill="#FFF" points="12.2961813 14.9766719 16.0305806 14.9766719 16.0305806 27 12.2961813 27" opacity=".9"/>
                                    </g>
                                </svg>
                            )
                    }
                </Button>
            ),
            'facebook': (
                <FacebookLogin
                    key    = 'facebook'
                    appId  = "664032940338241"
                    scope  = "public_profile, email, user_birthday, user_link"
                    fields = "first_name,last_name,email,birthday,gender,languages,link"
                    isMobile = { false }

                    callback = { onFacebook }
                    render   = { ({ onClick }) => (
                        <Button className='pcf-social-auth facebook' onClick={onClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
                                <g fill="none" fillRule="evenodd">
                                    <path fill="#FFF" d="M21.6499086 20h2.9570384L25 16.2077054h-3.3500914l.0045704-1.8969292c0-.9883423.0914077-1.5184816 1.476234-1.5184816h1.8479585V9h-2.9585618c-3.5527118 0-4.804997 1.8406312-4.804997 4.9323287v2.2753767H15V20h2.2151127v11h4.4347959V20Z"/>
                                    <path fill="#0042A4" d="M20 0c11.045695 0 20 8.954305 20 20s-8.954305 20-20 20S0 31.045695 0 20 8.954305 0 20 0Zm4.9786715 9h-2.9585618c-3.5527118 0-4.804997 1.8406312-4.804997 4.9323287v2.2753767H15V20h2.2151127v11h4.4347959V20h2.9570384L25 16.2077054h-3.3500914l.0045704-1.8969292c0-.9883423.0914077-1.5184816 1.476234-1.5184816h1.8479585V9Z"/>
                                </g>
                            </svg>
                        </Button>
                    )}
                />
            ),
            'apple': (
                <AppleSignin
                    key       = "apple"
                    uiType    = "dark"
                    className = "pfc-social-auth apple"
                    authOptions = {{
                        clientId: 'tabee.app.auth',
                        scope: 'email name',
                        redirectURI: 'https://go-dev.tabee.mobi',
                        state: 'state',
                        nonce: 'tabee-nonce',
                        usePopup: true,
                    }}
                    onSuccess={ onApple }
                    render   = { ({ onClick }) => (
                        <Button className='pcf-social-auth apple' onClick={onClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
                                <g fill="none" fillRule="evenodd">
                                    <path fill="#FFF" fillRule="nonzero" d="M20.2024147 13.0769231c.7563921 0 1.7045455-.5270763 2.2691762-1.2298446.5113636-.6368838.8842329-1.5263249.8842329-2.415766 0-.1207883-.0106533-.2415766-.0319602-.3404034-.8416193.0329423-1.8536931.58198-2.4609375 1.3176905-.4794034.5600186-.9161932 1.4384789-.9161932 2.3389009 0 .131769.0213069.263538.0319603.307461.053267.0109809.1384943.0219616.2237215.0219616Zm-2.6633522 13.2867133c1.0333807 0 1.4914773-.7137491 2.7805398-.7137491 1.3103693 0 1.5980113.6917875 2.7485795.6917875 1.1292614 0 1.8856535-1.0761139 2.5994318-2.1302663.7990057-1.2078831 1.1292614-2.3938046 1.1505682-2.4487084-.0745738-.0219615-2.2372159-.9333642-2.2372159-3.49188 0-2.2181125 1.7045455-3.2173612 1.8004261-3.2942265-1.1292613-1.6690747-2.8444602-1.7129977-3.3132102-1.7129977-1.2677556 0-2.3011363.7906144-2.9509943.7906144-.703125 0-1.6299716-.7466914-2.7272728-.7466914-2.0880682 0-4.2080965 1.7788823-4.2080965 5.1389933 0 2.0863433.7883523 4.2934751 1.7578125 5.7209732.8309658 1.2078831 1.5553977 2.196151 2.5994318 2.196151Z"/>
                                    <path fill="#000" d="M20 0c11.045695 0 20 8.954305 20 20s-8.954305 20-20 20S0 31.045695 0 20 8.954305 0 20 0Zm3.0681818 13.2635959c-1.2677556 0-2.3011363.7906144-2.9509943.7906144-.703125 0-1.6299716-.7466914-2.7272728-.7466914-2.0880682 0-4.2080965 1.7788823-4.2080965 5.1389933 0 2.0863433.7883523 4.2934751 1.7578125 5.7209732.8309658 1.2078831 1.5553977 2.196151 2.5994318 2.196151 1.0333807 0 1.4914773-.7137491 2.7805398-.7137491 1.3103693 0 1.5980113.6917875 2.7485795.6917875 1.1292614 0 1.8856535-1.0761139 2.5994318-2.1302663.7990057-1.2078831 1.1292614-2.3938046 1.1505682-2.4487084-.0745738-.0219615-2.2372159-.9333642-2.2372159-3.49188 0-2.2181125 1.7045455-3.2173612 1.8004261-3.2942265-1.1292613-1.6690747-2.8444602-1.7129977-3.3132102-1.7129977Zm.2556818-4.1726868c-.8416193.0329423-1.8536931.58198-2.4609375 1.3176905-.4794034.5600186-.9161932 1.4384789-.9161932 2.3389009 0 .131769.0213069.263538.0319603.307461.053267.0109809.1384943.0219616.2237215.0219616.7563921 0 1.7045455-.5270763 2.2691762-1.2298446.5113636-.6368838.8842329-1.5263249.8842329-2.415766 0-.1207883-.0106533-.2415766-.0319602-.3404034Z"/>
                                </g>
                            </svg>
                        </Button>
                    )}
                />
            )
        },
        show = ( config || [] ).map( key => mapping[key] )

    return (
        <div className='social-form-auth'>
            { show }
        </div>
    )
}

export default SocialAuth