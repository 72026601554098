import moment from 'moment'

import { request } from 'tools'

export const setMessage = request.update(
    'storage.auth.flush.message',
    ( msg, state ) => ({
        ...state,
        auth: {
            ...state.auth,
            msg,
            lastTry: moment()
        }
    })
)

export const signOut = request.update(
    'storage.auth.logout',
    ( n, state ) => {
        request.storage.remove( 'token' )
        request.storage.remove( 'user_id' )
        request.storage.remove( 'account' )

        window.location = '/login'

        return {
            ...state,
            auth: {
                ...state.auth,
                logged: false,
                lastTry: moment(),
                token: null,
                info: {}
            }
        }
    }
)

export const setAuthData = request.update(
    'storage.auth.login',
    ({ status, user, token, message, registered, current, account }, state) => {
        if ( status ) {
            request.storage.set( 'token', token )
            request.storage.set( 'user_id', user.id )
            request.storage.set( 'account', user.id )
        }
        
        return {
            ...state,
            auth: {
                ...state.auth,
                info: { 
                    token: token ?? state.auth.info?.token
                },
                logged: status,
                lastTry: moment(),
                msg: message,
                registered: registered ?? state.auth.registered
            },
            user: {
                ...state.user,
                info: user,
                current: status && current ? current : state.auth.current,
                account: status && account ? account : state.auth.account,
            }
        }
    }
)

export const setResetPassData = request.update(
    'storage.auth.resetpassword',
    ({ message, status }, state ) => ({
        ...state,
        auth: {
            ...state.auth,
            msg: message,
            resetSent: status,
            lastTry: moment()
        }
        
    })
)