import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const getSize = size =>
    {
        switch ( size ) {
            case 'small':
                return 12
            case 'large':
                return 32
            default:
                return 24
        }
    }

const Spinner = ({ style, size, color, className }) => (
    <Spin 
        size      = { size }
        className = { className }
        style     = { style }
        indicator = { <LoadingOutlined style={{ fontSize: getSize( size ), lineHeight: 1, color }} spin /> }
    />
)

export default Spinner