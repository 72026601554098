import React from 'react'
import PropTypes from 'prop-types'

import { NavigationItem, UserImage, Icons } from 'components'
import { t } from 'tools'

import './account-options.scss'

class AccountsOptions extends React.Component {

    static propTypes = {
        accounts: PropTypes.array,

        onReturn:  PropTypes.func,
        onSelect:  PropTypes.func,
        onSignOut: PropTypes.func,
    }

    render () {
        const
            { accounts } = this.props

        return (
            <div className="account-options">
                <div className="section">

                    <div
                        className = "control"
                        onClick   = { this.props.onReturn }
                    >
                        <div className="back-control">
                            <span>{ t('admin.accounts') }</span>
                        </div>
                    </div>

                    <div className="accounts-list">
                        <ul className="accounts-list-items">
                            {
                                accounts.map( account => (
                                    <li key={account.id}>
                                        <NavigationItem
                                            name = { account.name }
                                            icon = { <UserImage src={account.image} fullName={account.name} /> }

                                            onClick = { this.props.onSelect( account.id ) }
                                        />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className="section">
                    <div className="account-navigation">
                        {/*<NavigationItem
                            name = "Add account"
                            icon = { <Icons.AddAccount extraClassName="navigation-item-icon" /> }
                        />*/}
                        <NavigationItem
                            name = { t('admin.signOut') }
                            icon = { <Icons.SignOut extraClassName="signout" /> }
                            
                            onClick = { this.props.onSignOut }
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default AccountsOptions