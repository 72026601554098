import React from 'react'
import classNames from 'classnames'

import './static-page.scss'

const StaticPage = ({ children, extraClassName }) => 
    {
        const
            cls = classNames( 'static-page', extraClassName )

        return (
            <div className={cls}>
                { children }
            </div>
        )
    }

export default StaticPage