import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'antd'
import Icon from '@ant-design/icons'

import { Icons, Image } from 'components'
import { generate } from 'tools'

import './contact-card-viewer.scss'

const 
    MAX_SCALE = 5,
    SCALE_STEP = 0.25

class ContactCardViewer extends React.Component {

    static propTypes = {
        file:  PropTypes.string,
        photo: PropTypes.string,
        logo:  PropTypes.string,
    }

    constructor ( props ) {
        super( props )

        this.state = {
            scale: 1,
            fullscreen: false
        }

        this.set = generate.set( this )
    }

    enlarge    = () => this.set.scale( Math.min( this.state.scale + SCALE_STEP, MAX_SCALE ) )
    reduce     = () => this.set.scale( Math.max( this.state.scale - SCALE_STEP, SCALE_STEP ) )
    fullscreen = () => this.set.fullscreen( !this.state.fullscreen )

    render() {
        const 
            { file, photo, logo } = this.props,
            { scale, fullscreen } = this.state,
            cls = classNames(
                'contact-card-viewer',
                fullscreen && 'full-screen'
            )

        return (
            <div className={cls}>
                {
                    file || ( !photo && !logo )
                        ? (
                            <div className="viewer-image" style={{ transform: `scale(${scale})` }}>
                                <Image
                                    className = "only-card"
                                    fallback  = '//tabee.app/files/placeholder.jpg'
                                    src       = { file }
                                    alt       = ""
                                    width     = "368"
                                    height    = "208"
                                />
                            </div>
                        )
                        : (
                            <div className="viewer-image" style={{ transform: `scale(${scale})` }}>
                                <Image
                                    className = "photo"
                                    fallback  = '//tabee.app/files/placeholder_avatar@2x.jpg' 
                                    src       = { photo }
                                    alt       = ""
                                    width     = "160"
                                    height    = "208"
                                />
                                <Image
                                    className = "logo"
                                    fallback  = {`https://server.tabee.mobi/static/tabee/images/list_company_placeholder.png`}
                                    src       = { logo }
                                    alt       = ""
                                    width     = "208"
                                    height    = "208"
                                />
                            </div>
                        )
                }
                <div className='viewer-tools'>
                    <Button
                        icon    = { <Icon component={Icons.ZoomIn} /> }
                        onClick = { this.enlarge }
                    />
                    <Button
                        icon    = { <Icon component={Icons.ZoomOut} /> }
                        onClick = { this.reduce }
                    />
                    <Button
                        icon = {
                            fullscreen
                                ? <Icon component={Icons.Minimize} />
                                : <Icon component={Icons.Maximize} />
                        }
                        onClick = { this.fullscreen }
                    />
                </div>
            </div>
        )
    }
}

export default ContactCardViewer
