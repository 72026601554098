import React from 'react'

import { Image } from 'components'

import './user-image.scss'

const UserImage = ({ src, fullName }) => (
    <span className="user-image">
        <Image
            className = "image"
            width     = "48"
            height    = "48"
            
            src = { src }
            alt = { fullName }
            fallback = {`https://tabee.app/files/placeholder_avatar@2x.jpg`}
        />
    </span>
)

export default UserImage