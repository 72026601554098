import React from 'react'
import moment from 'moment'

import { AdminPanel, TodoHeader, TodoContent } from 'components'
import { generate, hash } from 'tools'

import connector from './connector'
import './todo.scss'

const
    params = hash.extract()

class ToDo extends React.Component {

    constructor ( props ) {
        super ( props )

        this.state = {
            search: params.search || '',
            filters: [],
            filtered: null
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        const
            { todos } = this.props

        ;( !todos.data ) && ( this.load() )
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            { todos } = this.props,
            { filters } = this.state

        if ( todos && ( todos !== prevProps.todos || filters !== prevState.filters ) ) {
            this.set.filtered( this.filter() )
        }
    }

    load = () =>
        {
            this.props.getTodos()
        }

    filter = () =>
        {
            const
                { todos } = this.props,
                { filters } = this.state,
                orFilters = filters.filter( f => f.type === 'or' ).map( f => f.handler ),
                andFilters = filters.filter( f => f.type === 'and' ).map( f => f.handler )

            let
                res = []

            if ( !todos.data ) return null

            orFilters.forEach( handler => res = [ ...res, ...todos.data.filter( handler ) ])
            andFilters.forEach( handler => res = res.filter( handler ) )

            return res.filter( ( item, index ) => res.findIndex( i => i.id === item.id ) === index )
        }

    catchPanel = ref => this._panel = ref
    panelToggle = () => this._panel.expand( 'admin' )()

    render () {
        const
            { current, user, companies, match, todos, tags } = this.props,
            { search, filtered } = this.state,
            count = todos.data
                ? todos.data.filter( item => moment( item.due_date ).isBefore( moment() ) && !item.completed_date ).length
                : null
 
        return (
            <React.Fragment>

                <AdminPanel 
                    account     = { current }
                    user        = { user } 
                    companies   = { companies }
                    currentPath = { match.path }

                    catchBack = { this.catchPanel }
                    onAccount = { this.props.setAccount }
                    onSignOut = { this.props.signOut }
                />

                <div className="admin-dashboard sharing-lists-dashboard">
                    <TodoHeader 
                        account  = { current }
                        loading  = { !todos.data }
                        search   = { search }

                        total = { todos.data ? todos.data.length : null }
                        count = { count }

                        onTogglePanel = { this.panelToggle }
                        onSearch = { this.set.byval( 'search' ) }
                    />
                    <div className="admin-dashboard-content">
                        <TodoContent
                            search   = { search }
                            todos    = { todos.data ? todos.data : null }
                            filtered = { filtered }
                            tags     = { tags }

                            onFilter = { this.set.filters }
                            getTag   = { this.props.getTag }
                        />
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default connector( ToDo )