import React from 'react'
import { useParams, useHistory } from 'react-router-dom'

import TabeeAuth from '@tabee/frontend-auth'

const AuthCheck = () => {
    const
        { mode } = useParams(),
        history = useHistory()

    switch ( mode ) {
        case '2fa':
            const
                query = new URLSearchParams( history.location.search ),
                email = query.get( 'email' ),
                code = query.get( 'code' )
            
            TabeeAuth.signInCode({ email, code })
                .then(() => { history.push( '/' ) })
                .catch(() => { history.push( '/login' ) })

            break

        default:
            history.push( '/' )
    }

    return <></>
}

export default AuthCheck