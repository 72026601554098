import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { OptionItems } from 'components'
import { generate, copy, strings, t } from 'tools'

class SelectedOptionsGroup extends React.Component {

    static propTypes = {
        option:   PropTypes.object,
        filter:   PropTypes.array,
        
        onSelect: PropTypes.func, 
        onAccept: PropTypes.func, 
        onClose:  PropTypes.func
    }

    constructor ( props ) {
        super( props )

        this.state = {
            search: '',
            selected: props.filter || []
        }

        this.set = generate.set( this )
    }

    componentDidUpdate ( prevProps ) {
        const
            { filter } = this.props

        ;( filter !== prevProps.filter ) && ( this.set.selected( filter ) )
    }

    append = ( item, value ) =>
        {
            const
                { option } = this.props,
                selected = [ ...this.state.selected ]

            selected[item.id] = value
                
            this.set.selected( selected )
            this.props.onAccept( option, selected )
        }

    select = item => e => 
        {
            const
                { option } = this.props,
                selected = copy.array( this.state.selected )

            if ( !item.id ) {
                this.set.selected( [] )
                this.props.onAccept( option, [] )
                return
            }

            if ( !option.selection ) {
                const
                    res = [ item.id ]

                if ( option.secondary && res && res[0] === option.secondary.when ) {
                    option.secondary.items.forEach( sec => {
                        res[sec.id] = sec.def
                    })
                }

                this.set.selected(res)
                this.props.onAccept( option, res )
                return
            }

            ;( e.target.checked )
                ? selected.push( item.id )
                : selected.splice( selected.indexOf( item.id ), 1 )

            this.set.selected( selected )
            this.props.onAccept( option, selected )
        }

    accept = () => 
        {
            const
                { option } = this.props,
                { selected } = this.state

            this.props.onAccept( option, selected )
        }

    test = item => e => console.log( 'test', item, e )
            
    render () {
        const
            { option, filter } = this.props,
            { selected, search } = this.state,
            filtered = {
                ...option,
                items: option.items.filter( item => strings.search( item, [ 'name', 'desc' ], search ) )
            }

        return (
            <React.Fragment>
                <div
                    className = "options-back"
                    onClick   = { this.props.onClose }
                >
                    <span>{ option.name }</span>
                </div>

                 {
                     option.search && (
                        <div className="options-search">
                            <Input
                                allowClear
                                prefix={ <SearchOutlined /> }
                                value = { search }
                                placeholder = { t('global.search') }

                                onChange = { this.set.byval( 'search' ) }
                            />
                        </div>
                    )
                }

                <div className="options-content">
                    <OptionItems
                        allowAll = { option.all }
                        key      = { option.id }
                        name     = { option.name }
                        data     = { filtered }
                        selected = { selected || [] }

                        onCheck  = { this.select }
                    />
                    {
                        ( option.secondary && selected && selected[0] === option.secondary.when ) && (
                            <OptionItems
                                allowAll = { option.secondary.all }
                                key      = { option.secondary.id }
                                name     = { option.secondary.name }
                                data     = { option.secondary }
                                selected = { filter }

                                onModify = { this.append }
                            />
                        )
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default SelectedOptionsGroup