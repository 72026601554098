import React from 'react'

const ValidateForm = ({ data, name }) => {
    return (
        <div className="public-card-card">
            <div className="pcc-inner">
                <p className="pcc-name">{ name }</p>
                <p className="pcc-title">{ data?.position }</p>
            </div>
        </div>
    )
}

export default ValidateForm