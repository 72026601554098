import React, { useState, useEffect } from 'react'
import { Input, Button } from 'antd'
import classNames from 'classnames'

import { Spinner, LabelIn } from 'components'
import { strings, t, adjust } from 'tools'
import config from 'config'

import SocialAuth from './SocialAuth'

const AuthForm = ({ target_id, event_id, steps, user, company, email, personal, socials, clearErrors, clearCompany, onRegister, getCompany }) => {
    const
        [ form, $form ] = useState({
            ...(
                user
                    ? {
                        personal_email: email && personal ? email : '',
                        email: email && !personal ? email : '',
                        first_name: user.first_name,
                        last_name: user.last_name
                    }
                    : (
                        !!email
                            ? (
                                personal
                                    ? { personal_email: email }
                                    : { email }
                            )
                            : {}
                    )
            ),
            phone: ''
        }),
        [ working, $working ] = useState(false),
        [ scroll, $scroll ]   = useState(false),
        [ errors, $errors ]   = useState({}),
        [ lockreg, $lockreg ] = useState(false),

        fetchCompany = mail =>
            {
                getCompany( ( ( mail || form.email?.trim() ).split( '@' )[1] || '').trim() )
            },

        check = {
            empty: val => !!val,
            email: ( email, ignoreCompany ) => (
                ignoreCompany
                    ? strings.validateEmail( email?.trim() )
                    : strings.validateEmail( email?.trim() ) && ( !company || !company.is_invalid )
            ),
            phone: phone => /^.{8,14}$/.test( phone.replace( /[^0-9]/g, '' ) ),// /^\+[0-9]{5,12}$/.test( phone?.trim() ),
            submit: () => 
                {
                    const
                        res = {
                            email: !check.email( form.email ),
                            personal: !(!form.personal_email || check.email( form.personal_email, true )),
                            phone: !(!form.phone || check.phone( form.phone )),
                            firstName: !check.empty( form.first_name ),
                            lastName: !check.empty( form.last_name ),
                            position: false,
                            company: false,
                        }

                    if ( Object.values( res ).includes( true ) ) {
                        $working( false )
                        $errors( res )
                        setTimeout(() => {
                            const
                                target = document.querySelector( '.has-error' )
                                
                            ;( target ) && ( target.scrollIntoView({ behavior: 'smooth', block: 'center' }) )
                        },100)
                        return false
                    }

                    return true
                }
        },

        joinData = key =>
            ({
                ...form, 
                company_info: company, 
                owner_id: steps.data.final?.card?.id,
                phone_id: steps.data.phone?.id,
                email_id: steps.data.contacts ? steps.data.contacts[0]?.id : steps.data.email?.id ,
                target_id,
                event_id,
                key,

            }),

        update = ( key, value ) =>
            {
                $form({
                    ...form,
                    [key]: value
                })
            },

        oneRegister = () => 
            {
                onRegister(joinData())
            },
            
        sendRequest = () =>
            {
                $errors({})
                clearErrors()
                //clearCompany()
                ;( !!form.email ) && ( fetchCompany( form.email ) )

                setTimeout(() => {
                    if ( check.submit() || company?.is_invalid ) {
                        $working( true )
                    }
                },0)
            },

        getBranded = () =>
            {
                if ( !company ) return {}

                return {
                    backgroundColor: company?.company_bg_color,
                    color: company && company.company_bg_color === company.company_text_color ? '#111' : company?.company_text_color,
                    border: [ '#000', '#000000', 'black', 'rgb(0,0,0)'].includes( company?.company_bg_color?.toLowerCase() )
                        ? `1px solid ${company?.company_bg_color !== company?.company_custom_text_color ? company?.company_custom_text_color : '#111111'}66`
                        : 'none'
                }
            },

        onFacebook = res =>
            {
                adjust.track( 'form_social', { type: 'facebook' } )
                $form({
                    ...form,
                    first_name: res.first_name,
                    last_name: res.last_name,
                    personal_email: res.email,
                })
            },

        onVK = res =>
            {
                adjust.track( 'activation_social', { type: 'vk' } )
                localStorage.setItem( 'tabee-social-contact', res.session?.user?.href )
                $form({
                    ...form,
                    first_name: res.session?.user?.first_name,
                    last_name: res.session?.user?.last_name,
                    personal_email: res.session?.user?.email,
                })
            },

        onLinkedin = res =>
            {
                adjust.track( 'form_social', { type: 'linkedin' } )
                $form({
                    ...form,
                    first_name: res.localizedFirstName,
                    last_name: res.localizedLastName,
                    personal_email: res.elements?.[0]?.['handle~']?.emailAddress,
                })
            },

        onGoogle = res =>
            {
                adjust.track( 'form_social', { type: 'google' } )
                $form({
                    ...form,
                    first_name: res.profileObj?.givenName || res.profileObj?.name?.split(' ')[0],
                    last_name: res.profileObj?.familyName || res.profileObj?.name?.split(' ')[1],
                    personal_email: res.profileObj?.email,
                })
            },

        onApple = res =>
            {
                adjust.track( 'form_social', { type: 'apple' } )
                $form({
                    ...form,
                    first_name: res.user?.name?.firstName,
                    last_name: res.user?.name?.lastName,
                    personal_email: res.user?.email
                })
            }

    useEffect(() => {
        if ( !lockreg && working && ( company?.id || company?.is_empty || company?.is_public ) ) {
            $lockreg( true )
            oneRegister()
        }
        // eslint-disable-next-line
    },[working, company])

    useEffect(() => {
        if ( email ) {
            ;( personal )
                ? update( 'personal_email', email )
                : update( 'email', email )
        }
        //eslint-disable-next-line
    },[email, personal])

    useEffect(() => {
        if ( !user ) return

        $form({
            ...form,
            email:      form.email || email || user.email,
            first_name: form.first_name || user.first_name,
            last_name:  form.last_name || user.last_name,
            company:    form.company || null
        })

        ;( user.email ) && ( fetchCompany(user.email) )
        // eslint-disable-next-line
    },[user])
            
    useEffect(() => {
        if ( company?.name ) update( 'company', company.name )
        if ( !(company?.id || company?.is_empty || company?.is_public) && working ) {
            $working( false )
            check.submit()
        }

        //eslint-disable-next-line
    }, [company])

    if ( steps.done ) {
        if ( !scroll ) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            $scroll( true )
        }

        return (
            <div className="public-card-form success-send">
                <h2>{t('publicCard.THANKS_FOR_SHARING')}</h2>
            </div>
        )
    }

    return (
        <div className="public-card-form auth-form" style={ getBranded() }>
            <h2>
                {
                    ( company?.company_logo_for_passbook ) 
                        ? (
                            <img 
                                src       = { `${config.api.url}/media/${company.company_logo_for_passbook}` }
                                alt       = { company.name }
                                width     = "100"
                                height    = "32"
                            />
                        )
                        : (
                            <span>{t('publicCard.SHARE_DETAILS')}</span>
                        )
                }
            </h2>
            <form className="pcf-form">
                <div className="pcf-form-item">
                    <LabelIn label={t('publicCard.WORK_EMAIL')}>
                        <Input 
                            value        = { form.email }
                            disabled     = { working }
                            className    = {classNames('force-lowercase', errors.email && 'has-error' )}

                            onChange = { e => update( 'email', e.target.value.replace( / /g, '' ) ) }
                            onBlur = {() => (
                                (
                                    form.email && 
                                    check.email( form.email, true )
                                ) && ( fetchCompany() )
                            )}
                        />
                    </LabelIn>

                    <SocialAuth
                        config     = { socials }

                        onLinkedin = { onLinkedin }
                        onFacebook = { onFacebook }
                        onGoogle   = { onGoogle }
                        onApple    = { onApple }
                        onVK       = { onVK }
                    />
                </div>
                <div className="pcf-form-item">
                    <LabelIn label={`${t('publicCard.PERSONAL_EMAIL')} ${t('publicCard.OPTIONAL')}`}>    
                        <Input 
                            value        = { form.personal_email }
                            type         = "email"
                            autoComplete = "email"
                            disabled     = { working }
                            className    = { classNames( 'force-lowercase', errors.personal && 'has-error' ) }

                            onChange = { e => e.target.value !== form.email && update( 'personal_email', e.target.value ) }
                        />
                    </LabelIn>
                </div>
                <div className="pcf-form-item">
                    <LabelIn label={t('publicCard.ENTER_YOUR_PHONE')}>    
                        <Input 
                            value        = { form.phone }
                            autoComplete = "tel"
                            disabled     = { working }
                            className    = { errors.phone && 'has-error' }

                            onChange = { e => update( 'phone', e.target.value.replace( /[^0-9\- +]/g, '' ) === '+' ? '' : `+${e.target.value.replace( /[^0-9\- ]/g, '' )}` ) }
                        />
                    </LabelIn>
                </div>
                <div className="pcf-form-item">
                    <LabelIn label={t('publicCard.ENTER_YOUR_NAME')}>    
                        <Input 
                            value        = { form.first_name }
                            autoComplete = "given-name"
                            disabled     = { working }
                            className    = { errors.firstName && 'has-error' }

                            onChange = { e => update( 'first_name', e.target.value ) }
                        />
                    </LabelIn>
                </div>
                <div className="pcf-form-item">
                    <LabelIn label={t('publicCard.ENTER_YOUR_SURNAME')}>    
                        <Input 
                            value        = { form.last_name }
                            autoComplete = "family-name"
                            disabled     = { working }
                            className    = { errors.lastName && 'has-error' }

                            onChange = { e => update( 'last_name', e.target.value ) }
                        />
                    </LabelIn>
                </div>
                <div className="pcf-form-item">
                    <LabelIn label={`${t('publicCard.ENTER_YOUR_JOB_TITLE')} ${t('publicCard.REQUIRED')}`}>    
                        <Input 
                            value        = { form.position }
                            autoComplete = "organization-title"
                            disabled     = { working }
                            className    = { errors.position && 'has-error' }

                            onChange = { e => update( 'position', e.target.value ) }
                        />
                    </LabelIn>
                </div>
                <div className="pcf-form-item">
                    <LabelIn label={t('publicCard.ENTER_YOUR_COMPANY_NAME')}>    
                        <Input 
                            value        = { form.company }
                            autoComplete = "organization"
                            disabled     = { working }
                            className    = { errors.company && 'has-error' }

                            onChange = { e => update( 'company', e.target.value ) }
                        />
                    </LabelIn>
                </div>
                <small>
                    {t('publicCard.AGREEMENT_START', { buttonText: t('publicCard.SHARE_CONTACT') })} <a href="https://tabee.app/tos/" target="_blank" rel="noreferrer">{t('publicCard.AGREEMENT_TERMS')}</a>{t('publicCard.AGREEMENT_AND')}<a href="https://tabee.app/privacy-policy/" target="_blank" rel="noreferrer">{t('publicCard.AGREEMENT_PRIVACY')}</a>.
                </small>
                <Button 
                    disabled  = { working }
                    className = "pcf-submit"
                    style = {{
                        color: company?.company_bg_color,
                        backgroundColor: (
                            !company?.company_bg_color
                                ? ''
                                : company && company.company_bg_color !== company.company_custom_text_color ? company.company_custom_text_color : '#111'
                        ),
                        border: `1px solid ${company?.company_bg_color}`
                    }}
                
                    onClick={sendRequest}
                >
                    {
                        working
                            ? <Spinner color={company && company.company_bg_color ? company.company_bg_color : '#FFFFFF'} />
                            : t('publicCard.SHARE_CONTACT')
                    }
                </Button>
            </form>
        </div>
    )
}

export default AuthForm