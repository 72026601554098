/* eslint-disable max-len */
import React from 'react'

export const Check = () => (
    <svg viewBox="0 0 32 32" width="32" height="32" xmlns="http://www.w3.org/2000/svg">
        <path d="M 13.333 23.667 L 6.667 17 L 8.547 15.12 L 13.333 19.893 L 23.453 9.773 L 25.333 11.667 Z" />
    </svg>
)
  

export const IOSApp = ({ extraClassName }) => (
    <svg viewBox="0 0 16 16" width="16" height="16" xmlns="http://www.w3.org/2000/svg" className={extraClassName}>
        <path fill="#000000" fillRule="evenodd" d="M13.545 11.305c-.356.784-.528 1.132-.988 1.826-.643.967-1.55 2.173-2.673 2.182-.997.01-1.253-.642-2.607-.634-1.353.007-1.636.645-2.634.636-1.122-.01-1.98-1.097-2.623-2.065C.223 10.546.034 7.372 1.143 5.683c.788-1.198 2.03-1.9 3.2-1.9 1.188 0 1.936.645 2.92.645.954 0 1.536-.646 2.912-.646 1.04 0 2.142.56 2.927 1.527-2.573 1.394-2.155 5.024.443 5.996zM9.132 2.701c.499-.634.879-1.53.742-2.444-.817.055-1.773.569-2.33 1.238-.507.607-.925 1.509-.762 2.385.893.028 1.815-.5 2.35-1.179z" />
    </svg>
)

export const AndroidApp = ({ extraClassName }) => (
    <svg viewBox="0 0 16 16" width="16" height="16" xmlns="http://www.w3.org/2000/svg" className={extraClassName}>
        <path fill="#a4c639" fillRule="evenodd" d="M9.048 1.34l.168-.262L9.38.82l.371-.58A.158.158 0 0 0 9.71.026a.144.144 0 0 0-.206.044l-.398.62-.168.261-.169.265A4.72 4.72 0 0 0 7 .878c-.632 0-1.232.121-1.77.338L5.062.951l-.166-.26-.4-.621A.145.145 0 0 0 4.29.026a.158.158 0 0 0-.041.215l.37.58.165.257.169.262c-1.264.612-2.118 1.772-2.118 3.1h8.33c0-1.328-.854-2.488-2.117-3.1zM5.216 3.2a.455.455 0 0 1-.447-.464c0-.257.199-.464.447-.464.245 0 .445.207.445.464 0 .256-.2.464-.445.464zm3.568 0a.456.456 0 0 1-.445-.464c0-.257.2-.464.445-.464.248 0 .447.207.447.464a.455.455 0 0 1-.447.464zM2.9 5.058h8.266v6.813c0 .541-.422.981-.942.981h-.68a.963.963 0 0 1 .036.258v1.961c0 .513-.4.929-.893.929s-.892-.416-.892-.929V13.11c0-.09.012-.176.035-.258H6.172a.963.963 0 0 1 .036.258v1.961c0 .513-.401.929-.894.929s-.892-.416-.892-.929V13.11c0-.09.013-.176.035-.258h-.68c-.519 0-.942-.44-.942-.98V5.057h.064zm-1.55 0c.492 0 .891.416.891.929V9.96c0 .513-.4.929-.892.929-.493 0-.893-.416-.893-.929V5.987c0-.513.4-.929.893-.929zm11.304 0c.492 0 .892.416.892.929V9.96c0 .513-.4.929-.892.929-.493 0-.893-.416-.893-.929V5.987c0-.513.4-.929.893-.929z" />
    </svg>
)

export const Facebook = ({ extraClassName }) => (
    <svg viewBox="0 0 16 16" width="16" height="16" xmlns="http://www.w3.org/2000/svg" className={extraClassName}>
        <path fill="#3b5998" fillRule="evenodd" d="M8.99 15H6.33V8.5H5V6.26h1.33V4.913C6.33 3.087 7.08 2 9.212 2h1.775v2.24h-1.11c-.83 0-.885.314-.885.898L8.99 6.259H11l-.235 2.24H8.989V15z" />
    </svg>
)

export const VK = ({ extraClassName }) => (
    <svg viewBox="0 0 16 16" width="16" height="16" xmlns="http://www.w3.org/2000/svg" className={extraClassName}>
        <path fill="#45668e" fillRule="evenodd" d="M8.785 11.962s.288-.031.436-.188c.136-.143.131-.414.131-.414s-.018-1.265.578-1.452c.586-.183 1.34 1.223 2.14 1.764.604.41 1.063.32 1.063.32l2.137-.03s1.117-.068.587-.934c-.043-.071-.309-.641-1.588-1.812-1.34-1.227-1.16-1.028.453-3.15.983-1.291 1.376-2.08 1.253-2.417-.117-.322-.84-.237-.84-.237l-2.405.015s-.179-.024-.311.054c-.13.076-.213.255-.213.255s-.38.999-.888 1.85c-1.072 1.792-1.5 1.887-1.675 1.776-.408-.26-.306-1.043-.306-1.6 0-1.738.268-2.462-.52-2.65-.262-.062-.454-.103-1.123-.11-.859-.009-1.585.003-1.997.201-.273.132-.485.427-.356.444.16.02.52.096.71.352.247.33.238 1.073.238 1.073s.142 2.046-.33 2.3c-.325.175-.77-.181-1.725-1.808-.489-.834-.858-1.755-.858-1.755s-.072-.172-.2-.265c-.153-.112-.369-.146-.369-.146l-2.285.014s-.344.01-.47.157c-.112.13-.009.401-.009.401S1.833 8.1 3.86 10.18c1.859 1.907 3.968 1.782 3.968 1.782h.957z" />
    </svg>
)

export const Odnoklassniki = ({ extraClassName }) => (
    <svg viewBox="0 0 16 16" width="16" height="16" xmlns="http://www.w3.org/2000/svg" className={extraClassName}>
        <path fill="#ed812b" fillRule="evenodd" d="M7.99 3.117c.815 0 1.477.671 1.477 1.496 0 .824-.662 1.495-1.476 1.495a1.488 1.488 0 0 1-1.476-1.495c0-.825.663-1.496 1.476-1.496zm0 5.108c-1.965 0-3.563-1.62-3.563-3.612S6.025 1 7.99 1s3.564 1.62 3.564 3.613c0 1.992-1.598 3.612-3.564 3.612zm3.522 2.08a6.63 6.63 0 0 1-2.07.869l1.993 2.02a1.068 1.068 0 0 1 0 1.496 1.034 1.034 0 0 1-1.476 0l-1.96-1.985-1.957 1.985a1.036 1.036 0 0 1-1.477 0 1.069 1.069 0 0 1 0-1.497l1.992-2.02a6.64 6.64 0 0 1-2.07-.868 1.067 1.067 0 0 1-.326-1.46 1.035 1.035 0 0 1 1.44-.333c1.459.93 3.338.93 4.798 0a1.035 1.035 0 0 1 1.44.333c.308.495.16 1.148-.327 1.46z" />
    </svg>
)

export const Twitter = ({ extraClassName }) => (
    <svg viewBox="-2 -2 16 16" width="16" height="16" xmlns="http://www.w3.org/2000/svg" className={extraClassName}>
        <path fill="#55acee" fillRule="evenodd" d="M12.469 3.74a2.76 2.76 0 0 0 1.172-1.537 5.21 5.21 0 0 1-1.694.674A2.61 2.61 0 0 0 10 2C8.527 2 7.334 3.243 7.334 4.777c0 .217.022.43.068.632-2.216-.116-4.18-1.22-5.497-2.901-.23.411-.36.888-.36 1.396 0 .963.47 1.813 1.186 2.312a2.596 2.596 0 0 1-1.21-.347v.034c0 1.346.92 2.469 2.141 2.723-.224.065-.46.097-.703.097a2.54 2.54 0 0 1-.502-.049c.34 1.103 1.324 1.907 2.492 1.928A5.218 5.218 0 0 1 1 11.752 7.339 7.339 0 0 0 5.088 13c4.907 0 7.588-4.231 7.588-7.9 0-.122-.001-.242-.007-.36A5.53 5.53 0 0 0 14 3.302c-.478.221-.993.37-1.531.437z" />
    </svg>
)

export const Instagram = ({ extraClassName }) => (
    <svg viewBox="0 0 16 16" width="16" height="16" xmlns="http://www.w3.org/2000/svg" className={extraClassName}>
        <path fill="#8a3ab9" fillRule="evenodd" d="M8 2.261c1.87 0 2.09.007 2.829.041.682.031 1.053.145 1.3.241.326.127.56.279.804.524.245.245.397.478.524.805.096.246.21.617.24 1.3.035.738.042.959.042 2.828 0 1.87-.007 2.09-.041 2.829-.031.682-.145 1.053-.241 1.3-.127.326-.279.56-.524.804a2.169 2.169 0 0 1-.805.524c-.246.096-.617.21-1.3.241-.737.034-.959.04-2.828.04-1.87 0-2.09-.006-2.829-.04-.682-.031-1.053-.145-1.3-.241a2.169 2.169 0 0 1-.804-.524 2.169 2.169 0 0 1-.524-.805c-.096-.246-.21-.617-.241-1.3-.034-.738-.04-.959-.04-2.828 0-1.87.006-2.09.04-2.829.031-.682.145-1.053.241-1.3.127-.326.279-.56.524-.804.245-.245.478-.397.805-.524.246-.096.617-.21 1.3-.24C5.91 2.267 6.13 2.26 8 2.26M8 1c1.901 0 2.14.008 2.886.042.745.034 1.254.152 1.7.326.46.178.85.418 1.24.807.388.389.628.78.806 1.24.174.445.292.954.326 1.699.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886-.034.745-.152 1.254-.326 1.7-.178.46-.418.85-.807 1.24-.389.388-.78.628-1.24.807-.445.173-.954.29-1.699.325C10.14 14.992 9.901 15 8 15c-1.901 0-2.14-.008-2.886-.042-.745-.034-1.254-.152-1.7-.325a3.431 3.431 0 0 1-1.24-.808c-.388-.389-.628-.78-.807-1.24-.173-.445-.29-.954-.325-1.699C1.008 10.14 1 9.901 1 8c0-1.901.008-2.14.042-2.886.034-.745.152-1.254.325-1.7.18-.46.419-.85.808-1.24.389-.388.78-.628 1.24-.806.445-.174.954-.292 1.699-.326C5.86 1.008 6.099 1 8 1zm.014 3.417a3.597 3.597 0 1 0 0 7.194 3.597 3.597 0 0 0 0-7.194zm0 5.932a2.335 2.335 0 1 1 0-4.67 2.335 2.335 0 0 1 0 4.67zm4.542-6.099a.833.833 0 1 0-1.667 0 .833.833 0 0 0 1.667 0z" />
    </svg>
)

export const Filter = () => (
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs></defs>
        <g id="Icon-/-Filters-/-Normal-/-Panel-Hidden" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="ic_filter_list_black" transform="translate(4.000000, 4.000000)">
                <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                <path d="M9.33333333,20 L14.6666667,20 L14.6666667,17.3333333 L9.33333333,17.3333333 L9.33333333,20 Z M1,4 L1,6.66666667 L23,6.66666667 L23,4 L1,4 Z M4,13.3333333 L20,13.3333333 L20,10.6666667 L4,10.6666667 L4,13.3333333 Z" id="Shape" fill="#1A2433" fillRule="nonzero"></path>
            </g>
        </g>
    </svg>
)

export const Activity = ({ extraClassName }) => (
    <svg viewBox="0 0 32 32" width="32" height="32" xmlns="http://www.w3.org/2000/svg" className={extraClassName || null}>
        <path d="M9.33333333,24 L12,24 L12,8 L9.33333333,8 L9.33333333,24 Z M14.6666667,29.3333333 L17.3333333,29.3333333 L17.3333333,2.66666667 L14.6666667,2.66666667 L14.6666667,29.3333333 Z M4,18.6666667 L6.66666667,18.6666667 L6.66666667,13.3333333 L4,13.3333333 L4,18.6666667 Z M20,24 L22.6666667,24 L22.6666667,8 L20,8 L20,24 Z M25.3333333,13.3333333 L25.3333333,18.6666667 L28,18.6666667 L28,13.3333333 L25.3333333,13.3333333 Z" fillRule="nonzero" />
    </svg>
)

export const Add = ({ extraClassName }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 156" className={extraClassName || null}>
        <rect width="11" height="71" x="72" y="42" fill="currentColor"/>
        <rect width="11" height="71" x="113" y="72" fill="currentColor" transform="rotate(90 113 72)"/>
    </svg>
)

export const AddAccount = ({ extraClassName }) => (
    <svg viewBox="0 0 32 32" width="32" height="32" xmlns="http://www.w3.org/2000/svg" className={extraClassName || null}>
        <path d="M 25.333 4 L 6.667 4 C 5.187 4 4 5.2 4 6.667 L 4 25.333 C 4 26.8 5.187 28 6.667 28 L 25.333 28 C 26.8 28 28 26.8 28 25.333 L 28 6.667 C 28 5.2 26.8 4 25.333 4 Z M 22.667 17.333 L 17.333 17.333 L 17.333 22.667 L 14.667 22.667 L 14.667 17.333 L 9.333 17.333 L 9.333 14.667 L 14.667 14.667 L 14.667 9.333 L 17.333 9.333 L 17.333 14.667 L 22.667 14.667 L 22.667 17.333 Z" id="Shape" transform="matrix(1, 0, 0, 1, 0, 4.440892098500626e-16)"/>
    </svg>
)

export const Help = ({ extraClassName }) => (
    <svg viewBox="0 0 32 32" width="32" height="32" xmlns="http://www.w3.org/2000/svg" className={extraClassName || null}>
        <path d="M16,2.66666667 C8.64,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.64,29.3333333 16,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,8.64 23.36,2.66666667 16,2.66666667 Z M17.3333333,25.3333333 L14.6666667,25.3333333 L14.6666667,22.6666667 L17.3333333,22.6666667 L17.3333333,25.3333333 Z M20.0933333,15 L18.8933333,16.2266667 C17.9333333,17.2 17.3333333,18 17.3333333,20 L14.6666667,20 L14.6666667,19.3333333 C14.6666667,17.8666667 15.2666667,16.5333333 16.2266667,15.56 L17.88,13.88 C18.3733333,13.4 18.6666667,12.7333333 18.6666667,12 C18.6666667,10.5333333 17.4666667,9.33333333 16,9.33333333 C14.5333333,9.33333333 13.3333333,10.5333333 13.3333333,12 L10.6666667,12 C10.6666667,9.05333333 13.0533333,6.66666667 16,6.66666667 C18.9466667,6.66666667 21.3333333,9.05333333 21.3333333,12 C21.3333333,13.1733333 20.8533333,14.24 20.0933333,15 Z" fillRule="nonzero" />
    </svg>
)
export const Team = ({ extraClassName }) => (
    <svg viewBox="0 0 32 32" width="32" height="32" xmlns="http://www.w3.org/2000/svg" className={extraClassName || null}>
        <path d="M21.3333333,14.6666667 C23.5466667,14.6666667 25.32,12.88 25.32,10.6666667 C25.32,8.45333333 23.5466667,6.66666667 21.3333333,6.66666667 C19.12,6.66666667 17.3333333,8.45333333 17.3333333,10.6666667 C17.3333333,12.88 19.12,14.6666667 21.3333333,14.6666667 Z M10.6666667,14.6666667 C12.88,14.6666667 14.6533333,12.88 14.6533333,10.6666667 C14.6533333,8.45333333 12.88,6.66666667 10.6666667,6.66666667 C8.45333333,6.66666667 6.66666667,8.45333333 6.66666667,10.6666667 C6.66666667,12.88 8.45333333,14.6666667 10.6666667,14.6666667 Z M10.6666667,17.3333333 C7.56,17.3333333 1.33333333,18.8933333 1.33333333,22 L1.33333333,25.3333333 L20,25.3333333 L20,22 C20,18.8933333 13.7733333,17.3333333 10.6666667,17.3333333 Z M21.3333333,17.3333333 C20.9466667,17.3333333 20.5066667,17.36 20.04,17.4 C21.5866667,18.52 22.6666667,20.0266667 22.6666667,22 L22.6666667,25.3333333 L30.6666667,25.3333333 L30.6666667,22 C30.6666667,18.8933333 24.44,17.3333333 21.3333333,17.3333333 Z" fillRule="nonzero" />
    </svg>
)

export const Wallets = ({ extraClassName }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" className={extraClassName}>
        <path fill="#fff" fillRule="evenodd" d="M18.995 5.3335h7.6717c1.48 0 2.6666 1.18666 2.6666 2.66666V24.0002c0 1.48-1.1866 2.6666-2.6666 2.6666H5.33333c-1.48 0-2.66666-1.1866-2.66666-2.6666L2.68 8.00016c0-1.48 1.17333-2.66666 2.65333-2.66666h7.68447S13.1312 8.50016 16 8.50016c2.8688 0 2.995-3.16666 2.995-3.16666zM5.33333 8.00016V24.0002H26.6667V8.00016h-5.2239S19.7318 11.5002 16 11.5002s-5.436-3.50004-5.436-3.50004H5.33333z" clipRule="evenodd"/>
    </svg>
)

export const CorporateWallets = Wallets

export const Account = ({ extraClassName }) => (
    <svg viewBox="0 0 32 32" width="32" height="32" xmlns="http://www.w3.org/2000/svg" className={extraClassName || null}>
        <path d="M 4 6.667 L 4 25.333 C 4 26.8 5.187 28 6.667 28 L 25.333 28 C 26.8 28 28 26.8 28 25.333 L 28 6.667 C 28 5.2 26.8 4 25.333 4 L 6.667 4 C 5.187 4 4 5.2 4 6.667 Z M 20 12 C 20 14.213 18.213 16 16 16 C 13.787 16 12 14.213 12 12 C 12 9.787 13.787 8 16 8 C 18.213 8 20 9.787 20 12 Z M 8 22.667 C 8 20 13.333 18.533 16 18.533 C 18.667 18.533 24 20 24 22.667 L 24 24 L 8 24 L 8 22.667 Z" transform="matrix(1, 0, 0, 1, 0, 4.440892098500626e-16)" />
    </svg>
)

export const Settings = ({ extraClassName }) => (
    <svg viewBox="0 0 32 32" width="32" height="32" xmlns="http://www.w3.org/2000/svg" className={extraClassName || null}>
        <path d="M 25.907 17.307 C 25.96 16.88 26 16.453 26 16 C 26 15.547 25.96 15.12 25.907 14.693 L 28.72 12.493 C 28.973 12.293 29.04 11.933 28.88 11.64 L 26.213 7.027 C 26.053 6.733 25.693 6.627 25.4 6.733 L 22.08 8.067 C 21.387 7.533 20.64 7.093 19.827 6.76 L 19.32 3.227 C 19.28 2.907 19 2.667 18.667 2.667 L 13.333 2.667 C 13 2.667 12.72 2.907 12.68 3.227 L 12.173 6.76 C 11.36 7.093 10.613 7.547 9.92 8.067 L 6.6 6.733 C 6.293 6.613 5.947 6.733 5.787 7.027 L 3.12 11.64 C 2.947 11.933 3.027 12.293 3.28 12.493 L 6.093 14.693 C 6.04 15.12 6 15.56 6 16 C 6 16.44 6.04 16.88 6.093 17.307 L 3.28 19.507 C 3.027 19.707 2.96 20.067 3.12 20.36 L 5.787 24.973 C 5.947 25.267 6.307 25.373 6.6 25.267 L 9.92 23.933 C 10.613 24.467 11.36 24.907 12.173 25.24 L 12.68 28.773 C 12.72 29.093 13 29.333 13.333 29.333 L 18.667 29.333 C 19 29.333 19.28 29.093 19.32 28.773 L 19.827 25.24 C 20.64 24.907 21.387 24.453 22.08 23.933 L 25.4 25.267 C 25.707 25.387 26.053 25.267 26.213 24.973 L 28.88 20.36 C 29.04 20.067 28.973 19.707 28.72 19.507 L 25.907 17.307 Z M 16 20.667 C 13.427 20.667 11.333 18.573 11.333 16 C 11.333 13.427 13.427 11.333 16 11.333 C 18.573 11.333 20.667 13.427 20.667 16 C 20.667 18.573 18.573 20.667 16 20.667 Z" transform="matrix(1, 0, 0, 1, 0, 4.440892098500626e-16)" />
    </svg>
)

export const SignIn = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
        <path fill="#fff" fillRule="evenodd" d="M4 6.66667V25.3333C4 26.8 5.18667 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V6.66667C28 5.2 26.8 4 25.3333 4H6.66667C5.18667 4 4 5.2 4 6.66667zM20 12c0 2.2133-1.7867 4-4 4-2.2133 0-4-1.7867-4-4 0-2.21333 1.7867-4 4-4 2.2133 0 4 1.78667 4 4zm-4 6.5333c-2.6667 0-8 1.4667-8 4.1334V24h16v-1.3333c0-2.6667-5.3333-4.1334-8-4.1334z" clipRule="evenodd"/>
    </svg>
)

export const SignOut = ({ extraClassName }) => (
    <svg viewBox="0 0 32 32" width="32" height="32" xmlns="http://www.w3.org/2000/svg" className={extraClassName || null}>
        <path d="M 13.453 20.787 L 15.333 22.667 L 22 16 L 15.333 9.333 L 13.453 11.213 L 16.893 14.667 L 4 14.667 L 4 17.333 L 16.893 17.333 L 13.453 20.787 Z M 25.333 4 L 6.667 4 C 5.187 4 4 5.2 4 6.667 L 4 12 L 6.667 12 L 6.667 6.667 L 25.333 6.667 L 25.333 25.333 L 6.667 25.333 L 6.667 20 L 4 20 L 4 25.333 C 4 26.8 5.187 28 6.667 28 L 25.333 28 C 26.8 28 28 26.8 28 25.333 L 28 6.667 C 28 5.2 26.8 4 25.333 4 Z" id="Shape" transform="matrix(1, 0, 0, 1, 0, 4.440892098500626e-16)" />
    </svg>
)

export const ZoomIn = () => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs></defs>
        <g id="Icon-/-Zoom-In" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <circle id="Oval" fill="#1A2433" opacity="0.5" cx="10" cy="10" r="10"></circle>
                <g id="ic_clear_white" transform="translate(3.333333, 3.333333)">
                    <polygon id="Shape" fill="#FFFFFF" fillRule="nonzero" transform="translate(6.666667, 6.666667) rotate(45.000000) translate(-6.666667, -6.666667) " points="11.6666667 2.67380952 10.6595238 1.66666667 6.66666667 5.65952381 2.67380952 1.66666667 1.66666667 2.67380952 5.65952381 6.66666667 1.66666667 10.6595238 2.67380952 11.6666667 6.66666667 7.67380952 10.6595238 11.6666667 11.6666667 10.6595238 7.67380952 6.66666667"></polygon>
                    <polygon id="Shape" points="0 0 13.3333333 0 13.3333333 13.3333333 0 13.3333333"></polygon>
                </g>
            </g>
        </g>
    </svg>
)

export const ZoomOut = () => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">\
        <defs></defs>
        <g id="Icon-/-ZoomOut" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <circle id="Oval" fill="#1A2433" opacity="0.5" cx="10" cy="10" r="10"></circle>
                <g id="ic_clear_white" transform="translate(3.333333, 3.333333)">
                    <polygon id="Shape" fill="#FFFFFF" fillRule="nonzero" transform="translate(6.666667, 6.666667) rotate(45.000000) translate(-6.666667, -6.666667) " points="11.6666667 2.67380952 10.6595238 1.66666667 1.66666667 10.6595238 2.67380952 11.6666667"></polygon>
                    <polygon id="Shape" points="0 0 13.3333333 0 13.3333333 13.3333333 0 13.3333333"></polygon>
                </g>
            </g>
        </g>
    </svg>
)

export const Maximize = () => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs></defs>
        <g id="Icon-/-Maximize" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <circle id="Oval" fill="#1A2433" opacity="0.5" cx="10" cy="10" r="10"></circle>
                <g id="ic_fullscreen_black_24px" transform="translate(1.000000, 1.000000)">
                    <polygon id="Shape" points="0 0 18 0 18 18 0 18"></polygon>
                    <path d="M5.25,10.5 L3.75,10.5 L3.75,14.25 L7.5,14.25 L7.5,12.75 L5.25,12.75 L5.25,10.5 Z M3.75,7.5 L5.25,7.5 L5.25,5.25 L7.5,5.25 L7.5,3.75 L3.75,3.75 L3.75,7.5 Z M12.75,12.75 L10.5,12.75 L10.5,14.25 L14.25,14.25 L14.25,10.5 L12.75,10.5 L12.75,12.75 Z M10.5,3.75 L10.5,5.25 L12.75,5.25 L12.75,7.5 L14.25,7.5 L14.25,3.75 L10.5,3.75 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
                </g>
            </g>
        </g>
    </svg>
)

export const Minimize = () => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs></defs>
        <g id="Icon-/-Minimize" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <circle id="Oval" fill="#1A2433" opacity="0.5" cx="10" cy="10" r="10"></circle>
                <g id="ic_fullscreen_exit_black_24px" transform="translate(1.000000, 1.000000)">
                    <polygon id="Shape" points="0 0 18 0 18 18 0 18"></polygon>
                    <path d="M3.75,12 L6,12 L6,14.25 L7.5,14.25 L7.5,10.5 L3.75,10.5 L3.75,12 Z M6,6 L3.75,6 L3.75,7.5 L7.5,7.5 L7.5,3.75 L6,3.75 L6,6 Z M10.5,14.25 L12,14.25 L12,12 L14.25,12 L14.25,10.5 L10.5,10.5 L10.5,14.25 Z M12,6 L12,3.75 L10.5,3.75 L10.5,7.5 L14.25,7.5 L14.25,6 L12,6 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
                </g>
            </g>
        </g>
    </svg>
)

export const Edit = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
        <path fill="#1A2433" fillRule="evenodd" d="M19.74 6.33723c.3467.34662.3467.90654 0 1.25316l-1.6264 1.62644-3.3329-3.33287 1.6265-1.62644c.3466-.34662.9065-.34662 1.2531 0L19.74 6.33723zM4 19.9976v-3.3329l9.8297-9.82976 3.3329 3.33286-9.82973 9.8298H4z" clipRule="evenodd"/>
    </svg>
)

export const Ellipsis = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
        <path fill="#1A2433" fillRule="evenodd" d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-8 2c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2z" clipRule="evenodd"/>
    </svg>
)

export const Sharing = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
        <g fill="none" fillRule="evenodd" transform="translate(-4 -4)">
            <polygon points="4 4 36 4 36 36 4 36"/>
            <path fill="#FFF" fillRule="nonzero" d="M6.4300973 19.76343c0-7.36 5.9733333-13.3333333 13.3333333-13.3333334 7.36 0 13.3333333 5.9733333 13.3333334 13.3333334 0 7.36-5.9733334 13.3333334-13.3333334 13.3333334-7.36-1e-7-13.3333333-5.9733334-13.3333333-13.3333334zm7.1036894 5.702783c-1.259534-1.259534-1.259534-3.3071973 0-4.5667312l2.9462783-2.9462783-1.3994822-1.3994822-2.9462783 2.9462783c-2.032932 2.032932-2.032932 5.3327636 0 7.3656956 2.032932 2.032932 5.3327637 2.032932 7.3656957 0l2.9462783-2.9462783-1.3994822-1.3994822-2.9462783 2.9462783c-1.259534 1.259534-3.3071974 1.259534-4.5667313 0zm3.7565048-2.2833656l5.8925565-5.8925565-1.4731391-1.4731391-5.8925565 5.8925565 1.4731391 1.473139zm2.2097087-11.0485435l-2.9462783 2.9462783 1.3994822 1.3994822 2.9462783-2.9462783c1.259534-1.259534 3.3071972-1.259534 4.5667312 0 1.259534 1.259534 1.259534 3.3071973 0 4.5667313l-2.9462782 2.9462782 1.3994822 1.3994822 2.9462783-2.9462782c2.032932-2.032932 2.0329319-5.3327637-1e-7-7.3656957-2.032932-2.032932-5.3327636-2.032932-7.3656956 0z"/>
        </g>
    </svg>
)

export const Linked = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
        <g fill="none" fillRule="evenodd">
            <rect width="31" height="31" x=".5" y=".5" fill="#FFF" stroke="#23B7F9" strokeOpacity=".3592052" rx="15.5"/>
            <g transform="translate(4 4)">
                <polygon points="0 0 24 0 24 24 0 24"/>
                <path fill="#23B7F9" fillRule="nonzero" d="M6.272435 17.727565c-1.2091525-1.2091527-1.2091525-3.1749095 0-4.3840621l2.8284272-2.8284271-1.3435029-1.343503L4.9289322 12c-1.9516147 1.9516147-1.9516147 5.1194531 0 7.0710678 1.9516147 1.9516147 5.119453 1.9516147 7.0710678 0l2.8284271-2.8284271-1.3435029-1.3435029-2.828427 2.8284271c-1.2091527 1.2091526-3.1749095 1.2091526-4.3840621 0zm3.6062447-2.192031l5.6568542-5.6568543-1.4142136-1.4142136-5.6568542 5.6568542 1.4142136 1.4142136zM12 4.9289321L9.1715729 7.7573593l1.3435029 1.3435029 2.828427-2.8284271c1.2091527-1.2091526 3.1749095-1.2091526 4.3840621 0 1.2091526 1.2091526 1.2091526 3.1749094 0 4.384062l-2.8284271 2.8284271 1.3435029 1.343503L19.0710678 12c1.9516147-1.9516147 1.9516147-5.1194531 0-7.0710678-1.9516147-1.9516147-5.119453-1.9516147-7.0710678 0z"/>
            </g>
        </g>
    </svg>
)

export const Unlinked = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
        <g fill="none" fillRule="evenodd">
            <rect width="31" height="31" x=".5" y=".5" fill="#FFF" stroke="#1A2433" strokeOpacity=".1" rx="15.5"/>
            <g opacity=".5" transform="translate(4 4)">
                <polygon points="0 0 24 0 24 24 0 24"/>
                <path fill="#1A2433" fillRule="nonzero" d="M6.2724354 17.727565c-1.2091526-1.2091525-1.2091527-3.1749094 0-4.384062L7.89408 11.7218584 6.5231614 10.405771l-1.594229 1.594229c-1.9516147 1.9516147-1.9516147 5.1194531 0 7.0710678 1.9516148 1.9516148 5.1194532 1.9516148 7.0710679 0l1.6422988-1.6422988-1.4201505-1.2668552-1.5656512 1.5656512c-1.2091526 1.2091526-3.1749094 1.2091526-4.384062 0zm.7778174-9.2630988l8.4852814 8.4852814 1.4142136-1.4142136-8.4852814-8.4852814-1.4142136 1.4142136zm4.914225-3.5000114l-1.7405731 1.740573 1.3426577 1.3443481 1.7414182-1.7414182c1.2091526-1.2091526 3.1749095-1.2091525 4.384062 0 1.2091527 1.2091526 1.2091527 3.1749095 0 4.384062l-1.7515603 1.7515605 1.4196223 1.2673835 1.675441-1.675441c1.9516147-1.9516147 1.9516147-5.1194531 0-7.0710678-1.9516148-1.9516148-5.1194532-1.9516148-7.0710679 0z"/>
            </g>
        </g>
    </svg>
)

export const Info = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
        <path fill="#1A2433" fillRule="evenodd" d="M7.99967 1.3335c-3.68 0-6.66666 2.98666-6.66666 6.66666 0 3.68004 2.98666 6.66664 6.66666 6.66664 3.68003 0 6.66663-2.9866 6.66663-6.66664 0-3.68-2.9866-6.66666-6.66663-6.66666zm-.66666 10v-4h1.33333v4H7.33301zm0-6.66667v1.33333h1.33333V4.66683H7.33301z" clipRule="evenodd" opacity=".5"/>
    </svg>
)

export const Merge = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
        <g fill="none" fillRule="evenodd">
        <rect width="31" height="31" x=".5" y=".5" fill="none" stroke="#1A2433" strokeOpacity=".1" rx="15.5"/>
        <polygon points="0 0 32 0 32 32 0 32" transform="rotate(45 16 16)"/>
        <path fill="#1A2433" fillRule="nonzero" d="M13.703125 7c1.8515625 0 3.5273438.7522651 4.7402344 1.9688189.777832.7801812 1.3666992 1.7528365 1.6875 2.8386254 1.1147461.3173618 2.109375.9153538 2.90625 1.7146355C24.25 14.7386336 25 16.419476 25 18.2766305s-.75 3.5379969-1.9628906 4.7545506C21.8242187 24.2477349 20.1484375 25 18.296875 25c-1.8515625 0-3.5273438-.7522651-4.7402344-1.9688189-.777832-.7801812-1.3666992-1.7528365-1.6875-2.8386254-1.114746-.3173618-2.109375-.9153538-2.90625-1.7146355C7.75 17.2598972 7 15.5790548 7 13.7233695c0-1.8571545.75-3.5379969 1.9628906-4.7545506C10.1757812 7.752265 11.8515625 7 13.703125 7zm6.6796875 6.1679863c.0117187.1513346.019043.3041384.0219727.4584115v.0969717c0 1.8571545-.75 3.5379969-1.9628907 4.7545507-1.2128906 1.2165537-2.8886718 1.9688188-4.7402343 1.9688188H13.6342773c-.1362304-.0014692-.2724609-.0073463-.4057617-.0161619.2783203.658232.6811524 1.2518162 1.1777344 1.749898.9960938.9991021 2.371582 1.6161946 3.8920898 1.6161946 1.519043 0 2.8959961-.6185618 3.890625-1.6161946.9960938-.9991022 1.6113282-2.3787446 1.6113282-3.9023754 0-1.5236307-.6152344-2.9047424-1.6113282-3.9038445-.5126953-.5142438-1.125-.927108-1.8061523-1.206269zM18.296875 11.553261h.0673828c.1362305.0014692.2709961.0073463.4057617.0161619-.2783203-.658232-.6811523-1.2518162-1.1777343-1.749898-.9960938-.999102-2.371582-1.6161946-3.8920899-1.6161946-1.519043 0-2.895996.6185618-3.890625 1.6161946-.9960937.9991021-1.6113281 2.3787446-1.6113281 3.9023753 0 1.5236308.6166992 2.9047425 1.6113281 3.9038446.5112305.5127745 1.1235352.9256388 1.8046875 1.2062689-.0117187-.1513346-.019043-.3041384-.0219726-.4584115v-.0969717c0-1.8571545.75-3.537997 1.9628906-4.7545507 1.2158203-1.2165537 2.8916015-1.9688188 4.7416992-1.9688188z"/>
        </g>
    </svg>
)

export const Remove = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="danger">
        <g fill="none" fillRule="evenodd">
            <rect width="31" height="31" x=".5" y=".5" fill="none" stroke="#1A2433" strokeOpacity=".1" rx="15.5"/>
            <g transform="rotate(45 16 16)">
            <polygon points="0 0 32 0 32 32 0 32"/>
            <polygon fill="#FF2638" fillRule="nonzero" points="24 17.1428571 17.1428571 17.1428571 17.1428571 24 14.8571429 24 14.8571429 17.1428571 8 17.1428571 8 14.8571429 14.8571429 14.8571429 14.8571429 8 17.1428571 8 17.1428571 14.8571429 24 14.8571429"/>
            </g>
        </g>
    </svg>
)

export const Tag = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
        <g fill="none" fillRule="evenodd">
            <rect width="31" height="31" x=".5" y=".5" fill="none" stroke="#1A2433" strokeOpacity=".1" rx="15.5"/>
            <path fill="#1A2433" fillRule="nonzero" d="M11.7182728 11.379538c0-.3731574-.1316483-.6917492-.3949447-.9557756-.2632965-.2640264-.5810076-.3960396-.9531333-.3960396-.3721256 0-.6898367.1320132-.9531332.3960396-.2632965.2640264-.3949447.5826182-.3949447.9557756 0 .3731573.1316482.6917491.3949447.9557755s.5810076.3960396.9531332.3960396c.3721257 0 .6898368-.1320132.9531333-.3960396.2632964-.2640264.3949447-.5826182.3949447-.9557755zm11.2374934 6.0831683c0 .3731573-.1298929.689989-.3896788.950495l-5.1711427 5.1960396C17.1211164 23.869747 16.80165 24 16.4365456 24c-.3721257 0-.6880815-.130253-.9478673-.390759l-7.5302791-7.5617162c-.2668071-.2605061-.4932421-.6160617-.679305-1.0666667C7.0930315 14.530253 7 14.1183718 7 13.7452145V9.3518152c0-.3661166.1334035-.6829483.4002106-.9504951C7.6670177 8.1337734 7.9829735 8 8.348078 8h4.3812533c.3721257 0 .7828682.0932893 1.2322275.279868.4493593.1865786.8074425.4136414 1.0742496.6811881l7.5302791 7.5405941c.2597859.2745874.3896788.5949395.3896788.9610561zm4.0442338 0c0 .3731573-.1298929.689989-.3896788.950495l-5.1711427 5.1960396C21.1653502 23.869747 20.8458838 24 20.4807794 24c-.2527647 0-.4598912-.049285-.6213797-.1478548-.1614885-.0985698-.3475514-.2569857-.5581885-.4752475l4.9499736-4.9636964c.2597859-.260506.3896788-.5773377.3896788-.950495 0-.3661166-.1298929-.6864687-.3896788-.9610561l-7.5302791-7.5405941c-.2668071-.2675467-.6248903-.4946095-1.0742496-.6811881C15.1972968 8.0932893 14.7865543 8 14.4144286 8h2.3591364c.3721257 0 .7828682.0932893 1.2322275.279868.4493593.1865786.8074425.4136414 1.0742496.6811881l7.5302791 7.5405941c.2597859.2745874.3896788.5949395.3896788.9610561z" />
        </g>
    </svg>
)

export const Sorter = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23">
        <defs>
            <polygon id="a" points="0 0 24 0 24 24 0 24"/>
        </defs>
        <g fill="none" fillRule="evenodd">
            <g mask="url(#b)">
            <path fill="#1A2433" d="M3.9027452 7.5H13v-3H3.9027452C3.4041952 4.5 3 5.1715971 3 5.9999706 3 6.8283442 3.4041953 7.5 3.9027452 7.5zM3.9027452 13.5H13v-3H3.9027452C3.4041952 10.5 3 11.1715971 3 12.0000294 3 12.8284616 3.4041953 13.5 3.9027452 13.5zM3 18.0000293C3 18.8284616 3.4231201 19.5 3.9450126 19.5H12l-3-3H3.9450126C3.4231201 16.5000587 3 17.1715971 3 18.0000293z"/>
            <g transform="translate(12 3)">
                <polygon fill="#1A2433" points="4.9999943 13.5 9.5121355 17.696547 10 18.196547 .0080708 18.196547 .4878532 17.696547" transform="matrix(1 0 0 -1 0 31.696546)"/>
                <path stroke="#1A2433" strokeWidth="3" d="M4.9999943 15L5 .5"/>
            </g>
            </g>
        </g>
    </svg>
)

export const Fav = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" style={{color:"#ffbf40"}}>
        <mask id="a" width="16" height="16" x="0" y="0" maskUnits="userSpaceOnUse">
            <rect width="16" height="16" fill="#fff"/>
        </mask>
        <g fill="#FFBF40" mask="url(#a)">
            <rect width="16" height="16" rx="8"/>
            <path fillRule="evenodd" stroke="#fff" d="M8.29478 10.9227c-.17562-.1282-.41394-.1282-.58956 0l-2.64091 1.9275c-.39214.2862-.921-.098-.76997-.5594l1.01714-3.1073c.06764-.20663-.006-.43329-.18218-.5607L2.47998 6.70679c-.39338-.28449-.19137-.90621.2941-.90515l3.26955.00715c.21742.00047.41022-.13961.47696-.34653l1.00355-3.11173c.14901-.46204.80271-.46204.95172 0l1.00355 3.11173c.06674.20692.25954.347.47696.34653l3.26953-.00715c.4855-.00106.6875.62065.2941.90515L10.8707 8.6228c-.1762.12741-.2498.35407-.1822.5607l1.0172 3.1073c.151.4614-.3779.8456-.77.5594l-2.64092-1.9275z" clipRule="evenodd"/>
        </g>
    </svg>
)

export const TodoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" style={{color:"#26B7FF"}}>
        <g clipPath="url(#clip0)">
            <circle cx="8" cy="8" r="6" fill="#fff"/>
            <path fill="#26B7FF" d="M7.99991.380859C3.79419.380859.380859 3.79419.380859 7.99991c0 4.20569 3.413331 7.61909 7.619051 7.61909 4.20569 0 7.61909-3.4134 7.61909-7.61909 0-4.20572-3.4134-7.619051-7.61909-7.619051zm.7619 12.952341H7.238v-1.5238h1.52381v1.5238zM10.339 7.42848l-.68576.70095c-.54857.55619-.89143 1.01334-.89143 2.15617H7.238v-.38093c0-.83809.34286-1.6.89143-2.15619l.94476-.96c.28191-.27429.44953-.65524.44953-1.07429 0-.83809-.68572-1.52381-1.52381-1.52381-.8381 0-1.52381.68572-1.52381 1.52381H4.95229c0-1.68381 1.36381-3.04762 3.04762-3.04762 1.68381 0 3.04759 1.36381 3.04759 3.04762 0 .67048-.2743 1.28-.7085 1.71429z"/>
        </g>
        <defs>
            <clipPath id="clip0">
            <rect width="16" height="16" fill="#fff"/>
            </clipPath>
        </defs>
    </svg>
)

export const Todo = ({ extraClassName }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className={extraClassName}>
        <g fill="none" fillRule="evenodd">
            <path fill="#FFF" fillRule="nonzero" d="M16 2.6666667C23.36 2.6666667 29.3333333 8.64 29.3333333 16 29.3333333 23.36 23.36 29.3333333 16 29.3333333 8.64 29.3333333 2.6666667 23.36 2.6666667 16 2.6666667 8.64 8.64 2.6666667 16 2.6666667zM10.5 25c1.9329966 0 3.5-1.5670034 3.5-3.5S12.4329966 18 10.5 18 7 19.5670034 7 21.5 8.5670034 25 10.5 25zm0-10c1.9329966 0 3.5-1.5670034 3.5-3.5S12.4329966 8 10.5 8 7 9.5670034 7 11.5 8.5670034 15 10.5 15zM15 8v2h9.5423392V8H15zm0 3.6666667v2h6.9398831v-2H15zm0 6.5v2h9.5423392v-2H15zm0 3.5v2h6.9398831v-2H15zm-5.9657445-10.511323l.8881204.8881939L11.9657445 10 12.5 10.4221627 9.922376 13 8.5 11.5775064l.5342555-.4221627zm0 10l.8881204.8881939L11.9657445 20 12.5 20.4221627 9.922376 23 8.5 21.5775064l.5342555-.4221627z"/>
        </g>
    </svg>
)

export const World = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
        <path fill="#343E4D" fillRule="evenodd" d="M17.577 8.10396c-.1844-.25138-.147-1.01866-.2024-1.86068.5771 1.12843.9051 2.40488.9051 3.75692 0 4.5655-3.7142 8.2797-8.2797 8.2797-1.01888 0-1.99487-.186-2.89713-.524.36612-.3263.74374-1.0437.81858-1.2747.09031-.2787-.21236-1.0077-.21269-1.1511-.00022-.1434-.85981-1.1468-1.07481-1.2902-.21501-.1433 0-.9321 0-1.4389 0-.507-1.14689-.783-1.57691-1.0699-.43001-.2866-.57339.7885-1.07515.5735-.50175-.215-.43012-.9317-.50175-1.5051-.05185-.4152-1.15397-.543-1.7592-.582 0-.0058-.00033-.0115-.00033-.0173 0-3.62935 2.34872-6.71893 5.60512-7.83388-.03095.03096-.06257.06191-.08689.09662-.27813.39541-.79304-.00022-.65287 1.23057.1405 1.23079-.04698 1.80219.84234 1.97796.88877.17576 1.21654-.04411 1.82519 0 .6081.04388.57284 1.57281.71334 1.59923 0 0 .79896 2.20015 3.14106 2.82837.4975.13353-.5149 1.15543-1.2167 1.46273-.7022.3075-1.3569.3515-1.4505 1.0989-.094.7471.9822.3076 1.2632.4395.281.1319 1.4929.5928 1.9889-.0933.4957-.6863 2.5253-.4536 2.3398-1.1868-.4212-1.66166 2.3513-2.41745 1.5444-3.51614zM10 0C4.48608 0 0 4.48575 0 10c0 5.5142 4.48608 10 10 10 5.5139 0 10-4.4858 10-10 0-5.51425-4.4861-10-10-10z" clipRule="evenodd"/>
    </svg>
)

export const Archive = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
        <g fill="none" fillRule="evenodd" stroke="red">
        <path d="M18.3380056 7.326923V17.61875c0 .3739338-.1516959.7124473-.3968484.9574995-.2452802.2451797-.5841168.3968274-.9583104.3968274H5.3803438c-.3738033 0-.7120782-.1523768-.9571783-.3973765-.2455635-.245463-.3979804-.5841231-.3979804-.9569504V7.326923h14.3128205Z"/>
        <path d="M19.3243659 2.676923c.3720494 0 .708731.151737.9525664.3962576.2447381.2454258.3961446.5843547.3961446.9581457v1.9373474c0 .3748124-.1500932.71443-.3948868.9599113-.243369.244053-.5801172.394492-.9538242.394492H3.0256341c-.3720494 0-.708731-.151737-.9525664-.3962576-.2447381-.2454259-.3961446-.5843547-.3961446-.9581457V4.0313263c0-.3748124.1500932-.71443.3948868-.9599113.243369-.244053.5801172-.394492.9538241-.394492Z"/>
        <path d="M12.8451386 10.326923c.2156956 0 .4125233.0807084.5601648.2149422.1409516.1281516.2369311.3048031.2614988.5046822-.0042772.2707252-.0911327.505818-.2445335.6734133-.1465803.1601435-.3518521.2531162-.5771301.2531162H9.5048614c-.2293605 0-.437384-.0913286-.5876339-.2410931-.1488622-.1483813-.2403044-.3538452-.2403044-.5819838 0-.2265532.0934627-.4312782.2424658-.5798.1504042-.1499183.35771-.243277.5854725-.243277Z"/>
        </g>
    </svg>
)

export const Mail = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <rect width="31.9" height="31.9" x=".05" y=".05" fill="#0C2D53" rx="8"/>
            <g fill="#FFF" fillRule="nonzero" transform="translate(6 9.5)">
                <path d="m12.0489796 7.007008-1.7877551 1.4734917c-.1428572.114869-.3510204.114869-.489796 0l-1.787755-1.4695308L.6816327 13h18.6367346l-7.2693877-5.992992Z"/>
                <polygon points="0 .4475929 0 12.5524071 7.3510204 6.5"/>
                <polygon points=".6816327 0 10 7.6724558 19.3183673 0"/>
                <polygon points="12.6489796 6.5 20 12.5524071 20 .4475929"/>
            </g>
        </g>
    </svg>
)

export const Link = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <rect width="31.9" height="31.9" x=".05" y=".05" fill="#0C2D53" rx="8"/>
            <g fill="#FFF" fillRule="nonzero">
                <path d="M24.2781746 8.2218254c-2.143981-2.1439809-5.6337007-2.1444738-7.7781594-.0000152-.5368743.5368743-.537389 1.407147.0000038 1.9445399.5373929.5373928 1.4076656.536878 1.9445398.0000038 1.0723813-1.0723813 2.8161778-1.0728944 3.8890797.0000076 1.072902 1.072902 1.0723889 2.8166984.0000076 3.8890797l-2.916804 2.916804c-1.0723813 1.0723813-2.8162082 1.072864-3.8890798-.0000075l-.9722718-.9722719-1.944536 1.944536.9722718.9722719c2.143981 2.1439809 5.6337008 2.1444738 7.7781594.0000152l2.916804-2.916804c2.1444587-2.1444588 2.1439658-5.6341786-.0000151-7.7781595Z"/>
                <path d="M16.5000418 21.8335892c-.5373625-.5373625-1.4076656-.536878-1.9446007.000057-1.0723205 1.0723205-2.816208 1.0728639-3.8890796-.0000077s-1.0723282-2.8167591-.0000076-3.8890796l2.9168648-2.9168649c1.0723812-1.0723812 2.8161778-1.0728944 3.8890797.0000076l.9722718.9722718 1.944536-1.944536-.9722717-.9722719c-2.143981-2.1439809-5.6337008-2.1444738-7.7781595-.0000151l-2.9168648 2.9168648c-2.1443979 2.144398-2.1439657 5.6341785.0000152 7.7781594 2.143981 2.1439809 5.6337615 2.1444131 7.7781594.0000152.536935-.536935.5374499-1.4072078.000057-1.9446006Z"/>
            </g>
        </g>
    </svg>
)

export const Phone = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
            <path fill="#FFF" d="M7.0243946 15.5498068c.0790534-4.6364098 4.4273052-8.6969823 9.1570758-8.5423026 2.2659904-.1029156 4.3963713.8637561 6.1321099 2.2215684-.7405402.8248564-1.5076398 1.6200021-2.3275458 2.3633837-2.0863235-1.4138646-5.0541084-1.8175634-7.140432-.1846968-2.9852829 2.0237008-3.1208923 6.8019259-.2499713 8.9803065 2.7921801 2.4837582 8.0700129 1.2503022 8.841487-2.5523686-1.7491746-.0257289-3.5024112 0-5.2512733-.0557459-.0043745-1.022724-.008749-2.045448-.0043745-3.0681719 2.9237275-.0085763 5.847455-.0128644 8.775557.0085763.1752924 2.4062652-.1490454 4.9672101-1.6569976 6.956912-2.2838009 3.1496467-6.8689005 4.0691488-10.4456782 2.7199128-3.5899012-1.3406597-6.1324224-5.0575675-5.829957-8.8473739"/>
            <rect width="31.9" height="31.9" x=".05" y=".05" fill="#0C2D53" rx="8"/>
            <path fill="#FFF" fillRule="nonzero" d="M6.2144242 21.1678873c.7444933-1.4076203 3.329828-2.9339019 3.4436524-3.000649.3322042-.1891165.6788682-.289237 1.0032863-.289237.4823635 0 .8772268.2210068 1.1163693.6229721.3781789.4523964.8471948.981181.9610193 1.0631315.8809343.5977566 1.5705546.5298971 2.3335861-.2332437l4.2585909-4.2595717c.7582115-.7583202.827915-1.456568.2343226-2.332808-.0830511-.1145824-.61176-.5840363-1.0640916-.9626402-.2884541-.1716881-.4864419-.42681-.5732006-.7397794-.1153074-.4164272-.0304026-.906276.241738-1.3835172.064142-.1097617 1.5909466-2.6958376 2.9976237-3.4400667.2625006-.1390563.55837-.212478.8549809-.212478.4886664 0 .9484133.1905998 1.293965.5358302l.940998.940762c1.4882452 1.4880875 2.0269647 3.1749328 1.6020696 5.0134421-.35445 1.5322147-1.3873974 3.1626958-3.0706642 4.8458329l-5.452079 5.45286C15.2061834 24.9194401 13.1751403 26 11.299818 26c-1.3796114 0-2.6650488-.5862612-3.8210898-1.742097l-.9406273-.940762c-.5717174-.5714286-.7014847-1.4354315-.3236766-2.1492537Z"/>
        </g>
    </svg>
)

export const SingleContact = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.7438293,0 L14.9938757,1.76578297 L19.2663161,6.07162283 L8.89823693,6.07162283 C3.98639035,6.07162283 0,10.0884801 0,15.0330711 C0,19.9786585 3.95917105,24 8.90813486,24 L8.90813486,21.5087712 C5.44386019,21.5087712 2.47448191,18.6030019 2.47448191,15.0310781 C2.47448191,11.4596525 5.35576864,8.5538832 8.9031859,8.5538832 L19.2638416,8.5538832 L14.9988246,12.8467687 L16.7472935,14.6080674 L24,7.30577757 L16.7443242,0 L16.7438293,0 Z" id="Path" fill="currentColor"></path>
    </svg>
)