import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Menu, Dropdown } from 'antd'

import { Icons } from 'components'
import { t, i18next, generate } from 'tools'

import './language-switch.scss'

class LanguageSwitch extends React.Component {

    static propTypes = {
        hide: PropTypes.bool
    }

    constructor ( props ) {
        super( props )

        this.state = {
            open: false
        }

        this.set = generate.set( this )
    }

    componentDidUpdate ( prevProps ) {
        const
            { hide } = this.props

        ;( hide !== prevProps.hide && hide ) && ( this.set.open( false ) )
    }

    setLang = lang => () => i18next.changeLanguage( lang )

    items = () =>
        {
            const
                res = [],
                langs = i18next.options.resources

            Object.keys( langs ).forEach( lang => {
                res.push(
                    <Menu.Item key={lang}>
                        <span onClick={this.setLang(lang)}>
                            { langs[lang].main.global.language }
                        </span>
                    </Menu.Item>
                )
            })

            return res
        }

    render () {
        const 
            { open } = this.state,
            menu = (
                <Menu 
                    className    = { classNames("dark-theme", "lang-dropdown", this.props.className ) }
                    selectedKeys = {[ i18next.language ]}
                >
                    { this.items() }
                </Menu>
            )

        return (
            <Dropdown 
                overlay   = { menu }
                trigger   = {['click']}
                placement = "topCenter"
                visible   = { open }

                onVisibleChange = { this.set.open }
            >
                <span className="lang-switch">
                    { t('global.language') }
                    <Icons.World />
                </span>
            </Dropdown>
      )
    }
}

export default LanguageSwitch
