/* IMPORT */
import { connect } from 'react-redux'
import { todoActions, userActions, authActions, tagsActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    user:      state.user.info,
    companies: state.user.companies,
    current:   state.user.current,
    account:   state.user.account,
    todos:     state.todo.list,
    tags:      state.tags
})

const allActions = {
    ...todoActions,
    ...userActions,
    ...authActions,
    ...tagsActions
}

export default connect( mapStateToProps, allActions )