import React from 'react'
import { Button, Input } from 'antd'
import classNames from 'classnames'

import { Spinner, LabelIn } from 'components'
import { t } from 'tools'

const LiteMailForm = ({ form, error, working, isDigital, onUpdate, fetchSearch }) => {
    return (
        <form className="pcf-form" onSubmit={fetchSearch}>
            <div className="pcf-form-item">
                <LabelIn label={t('publicCard.ENTER_BUSINESS_EMAIL')}>
                    <Input 
                        value        = { form.email }
                        autoComplete = 'email'
                        disabled     = { working }
                        className    = {classNames('force-lowercase', error.email && 'has-error' )}

                        onChange = { e => onUpdate( 'email', e.target.value.replace( / /g, '' ) ) }
                    />
                </LabelIn>
            </div>
            {
                isDigital && (                    
                    <small>
                        {t('publicCard.AGREEMENT_START', { buttonText: t('publicCard.CREATE_BUSINESS_CARD') })} <a href="https://tabee.app/tos/" target="_blank" rel="noreferrer">{t('publicCard.AGREEMENT_TERMS')}</a>{t('publicCard.AGREEMENT_AND')}<a href="https://tabee.app/privacy-policy/" target="_blank" rel="noreferrer">{t('publicCard.AGREEMENT_PRIVACY')}</a>.
                    </small>
                )
            }
            <Button type="primary" disabled={ working } onClick={fetchSearch}>
            {
                working 
                ? (
                    <Spinner color='#FFFFFF' />
                )
                : t('publicCard.GO')
            }
            </Button>
        </form>
    )
}

export default LiteMailForm