import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { AdminHeader, ToolsRow, Spinner } from 'components'
import { strings, t } from 'tools'

class TeamHeader extends React.Component {

    static propTypes = {
        loading:  PropTypes.bool,
        search:   PropTypes.string,

        account:  PropTypes.object,
        total:  PropTypes.number,
        hidden: PropTypes.number,

        onTogglePanel: PropTypes.func,
        onSearch:      PropTypes.func
    }

    render() {
        const 
            { search, loading, total, hidden, account } = this.props

        return (
            <AdminHeader account={account} onTogglePanel={this.props.onTogglePanel}>
                <div className="title-fix">
                    { loading && <Spinner style={{ marginRight: 20 }} /> }
                    {
                        loading
                            ? t('team.loading')
                            : (
                                !!hidden
                                    ? t( 'global.outOfTotal', { count: total - hidden, total } )
                                    : (
                                        hidden === null
                                            ? t('global.noResult')
                                            : strings.encount( total, t('counters.countersTeamMember') )
                                    )
                            )
                    }
                </div>
                
                <ToolsRow>
                    <Input
                        allowClear
                        prefix = { <SearchOutlined /> }
                        value  = { search }
                        placeholder = { t('global.search') }

                        onChange = { this.props.onSearch }
                    />
                </ToolsRow>
            </AdminHeader>
        )
    }
}

export default TeamHeader