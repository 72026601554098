import moment from 'moment'

import { t } from 'tools'
import config from 'config'

const cfg = cmp => [
    {
        id: 'strength',
        name: t('global.filterByType'),
        selection: true,
        items: [
            {
                name: t('global.connections'),
                id: 'connections'
            },
            {
                name: t('global.contacts'),
                id: 'contacts'
            }
        ]
    },
    {
        id: 'owner',
        name: t('global.filterByOwner'),
        type: 'members',
        all: t('global.allOwners'),
        sub: true,
        search: true,
        items: cmp.cards() || []
    },
    {
        id: 'event',
        name: t('global.event'),
        all: t('global.allEvents'),
        selection: true,
        sub: true,
        search: true,
        items: cmp.extract( 'event', false, 'name' )
    },
    {
        id: 'tags',
        name: t('global.filterByTags'),
        type: 'tags',
        all: t('global.allTags'),
        counter: t('counters.countersTag'),
        selection: true,
        sub: true,
        search: true,
        items: ( cmp.props.tags || [] ).filter( t => !t.is_deleted ).sort( ( a, b ) => a.name.localeCompare( b.name ) )
    },
    {
        id: 'dates',
        name: t('global.filterByDates'),
        def: 1,
        sub: true,
        secondary: {
            when: 'specific',
            type: 'range',
            id: 'dates-range',
            name: t('global.specifyDates'),
            simple: true,
            formatter: date => date ? date.format( config.ui.dates.date ) : date,
            parser: raw => raw ? moment( raw, config.ui.dates.date ) : raw,
            items: [
                {
                    id: 1,
                    name: t('global.from'),
                    nullable: true,
                    def: moment().subtract( 1, 'month' ).format( config.ui.dates.date )
                },
                {
                    id: 2,
                    name: t('global.to'),
                    nullable: true,
                    def: moment().format( config.ui.dates.date )
                }
            ] 
        },
        items: [
            {
                id: 'any',
                name: t('global.anytime'),
            },
            {
                id: 'week',
                name: t('global.thisWeek'),
            },
            {
                id: 'prevweek',
                name: t('global.previousWeek')
            },
            {
                id: 'month',
                name: t('global.thisMonth'),
            },
            {
                id: 'prevmonth',
                name: t('global.previousMonth')
            },
            {
                id: 'quarter',
                name: t('global.thisQuarter'),
            },
            {
                id: 'prevquarter',
                name: t('global.previousQuarter'),
            },
            {
                id: 'specific',
                name: t('global.specificDates'),
            },
        ]
    },
]

export default cfg