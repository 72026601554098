import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Spinner } from 'components'
import { generate } from 'tools'
import config from 'config'

import './image.scss'

const extmap = {
    'jpg': 'jpeg',
    'jpeg': 'jpeg',
    'png': 'png'
}

class Image extends React.Component {

    static propTypes = {
        src:      PropTypes.string,
        fallback: PropTypes.string,
        alt:      PropTypes.string,
        resize:   PropTypes.bool,
        width:    PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
        height:   PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    }

    static defaultProps = {
        width: 100,
        height: 100,
        resize: true
    }

    constructor ( props ) {
        super( props )

        const
            start = generate.uniq()

        this.state = {
            prev: start,
            current: start,
            loading: !!props.src,
            error: false
        }

        this.set = generate.set( this )
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            { src } = this.props,
            { error, loading } = this.state

        if ( src !== prevProps.src ) {
            this.set.state({
                error: false,
                loading: true,
                prev: this.state.current,
                current: !!src ? generate.uniq() : this.state.current
            })

            ;( !src ) && ( this.set.loading( false ) )
        }

        ;( src === prevProps.src && ( error !== prevState.error || loading !== prevState.loading ) ) && 
            ( this.set.state({
                prev: this.state.current,
                show: true 
            }))
    }

    error  = () => 
        {
            this.set.state({ loading: false, error: true })
            ;( this.props.onFail ) && ( this.props.onFail() )
        }

    loaded = errorRemains => this.set.state({ loading: false, error: errorRemains ? this.state.error : false }) 

    render () {
        const
            { resize, width, height, alt, src, fallback, className } = this.props,
            { error, loading, prev, current } = this.state,
            cls = classNames( 'image-container', className ),
            source = !!src 
                ? (
                    src.substr( 0, 2 ) === '//' || src.substr( 0, 4 ) === 'http' || src.substr( 0, 5 ) === 'data:'
                        ? src
                        : `${config.images.media}/${src}`
                )
                : '',
            fb = fallback || config.images.latestFallback,

            fbp = fb.substr( 0, 2 ) === '//'
                ? `${window.location.protocol}${fb}`
                : fb,

            ext = !error && src ? extmap[src.split('.').pop().toLowerCase()] : extmap[fbp.split('.').pop()],

            url = ( error || !src )
                ? (
                    resize
                        ? `${config.images.resizer}/${width*2}x${height*2},${ext}/${fbp}`
                        : fbp
                )
                : (
                    resize
                        ? `${config.images.resizer}/${width*2}x${height*2},${ext}/${source}`
                        : source
                )
            
        return (
            <div className={cls}>
                <img
                    ref = { node => this.view = node }
                    src = { prev === current ? url : fb } 
                    alt = { alt }
                    className = { loading ? 'loading' : '' }
                />
                {
                    !!src && (
                        <img
                            src = { url }
                            alt = ""
                            style = {{ opacity: 0, pointerEvents: 'none', position: 'absolute', left: '-9999px' }}

                            onError = { this.error }
                            onLoad  = { () => this.loaded( fbp === url ) }
                        />
                    )
                }
                {
                    loading && (
                        <div className="image-spinner">
                            <Spinner />
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Image