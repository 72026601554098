import React from 'react'
import classNames from 'classnames'
import { Input } from 'antd'

import './color-picker.scss'

const ColorPicker = ({ className, ...props }) =>
    (
        <Input
            { ...props }
            className   = { classNames( 'ant-color-picker', className ) }
            addonBefore = {
                <Input 
                    { ...props }
                    type = "color"
                />
            }
        />
    )

export default ColorPicker