export const resolveAfter = ( response, time, request ) => 
    {
        return new Promise( resolve =>
            setTimeout( 
                () => {
                    console.log( 'Fake resolved:', request, response )

                    return resolve({ 
                        status: 200, 
                        data: response,
                        config: {
                            data: JSON.stringify( request )
                        }
                    })
                },
                time || 500
            )
        )
    }

export const prePasscodeData = [
	{
		"fullName": "Heidi Montgomery",
		"position": "Advertising",
		"companyName": "Cum Associates",
		"phone": "1-363-695-8508",
		"email": "adipiscing.elit.Curabitur@leoelementum.com",
		"tags": [{ "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"desserts","color":"#990","name":"Networking"}, {"id":"pies","color":"#999","name":"Family"}],
		"id": "8864CD6E-002A-86BF-EADE-B404A42A57D8"
	},
	{
		"fullName": "Piper Burton",
		"position": "Asset Management",
		"companyName": "Natoque Penatibus Corp.",
		"phone": "1-451-492-5819",
		"email": "Integer.id@Cumsociisnatoque.com",
		"tags": [{"id":"sandwiches","color":"#099","name":"Local"}, {"id":"noodles","color":"#0f0","name":"Green"}, {"id":"soups","color":"#09f","name":"Shared"}],
		"id": "13933552-FBC4-0E73-BD5B-CCBAB7B225C2"
	},
	{
		"fullName": "Aladdin Campos",
		"position": "Sales and Marketing",
		"companyName": "Pharetra Inc.",
		"phone": "426-6252",
		"email": "auctor.quis@Fuscealiquam.co.uk",
		"tags": [{"id":"sandwiches","color":"#099","name":"Local"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"desserts","color":"#990","name":"Networking"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "F74A1B5C-4658-F548-F884-ED0C5B623D81"
	},
	{
		"fullName": "Abra Ramirez",
		"position": "Public Relations",
		"companyName": "A Incorporated",
		"phone": "275-6416",
		"email": "nec@mattisInteger.com",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, { "id":"pasta","color":"#f00","name":"Telecom"}],
		"id": "81C3150A-43E8-249D-CCEA-8411BBB6F056"
	},
	{
		"fullName": "Amethyst Franco",
		"position": "Customer Relations",
		"companyName": "Varius Et Euismod Consulting",
		"phone": "636-8075",
		"email": "Integer@lorem.edu",
		"tags": [{"id":"soups","color":"#09f","name":"Shared"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"stews","color":"#00f","name":"Europe"}, {"id":"sandwiches","color":"#099","name":"Local"}, { "id":"pasta","color":"#f00","name":"Telecom"}],
		"id": "64433EAF-0565-27C3-3EC5-5FC6FDFA45C1"
	},
	{
		"fullName": "Christine Chandler",
		"position": "Media Relations",
		"companyName": "Quam LLC",
		"phone": "498-0668",
		"email": "molestie@quisdiam.net",
		"tags": [{"id":"noodles","color":"#0f0","name":"Green"}, {"id":"sandwiches","color":"#099","name":"Local"}, {"id":"salads","color":"#000","name":"Australia"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"pies","color":"#999","name":"Family"}],
		"id": "CD79E646-9592-A695-43F4-7F83B49E8AA1"
	},
	{
		"fullName": "Marshall Watkins",
		"position": "Finances",
		"companyName": "Sem Elit Limited",
		"phone": "1-921-817-9886",
		"email": "faucibus@dui.org",
		"tags": [{"id":"salads","color":"#000","name":"Australia"}],
		"id": "89ED9D97-00D6-FC86-DAA2-CFE863007141"
	},
	{
		"fullName": "Ina Mays",
		"position": "Customer Relations",
		"companyName": "Commodo Auctor Corp.",
		"phone": "582-6340",
		"email": "adipiscing.elit@eget.org",
		"tags": [{"id":"sandwiches","color":"#099","name":"Local"}, { "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"soups","color":"#09f","name":"Shared"}],
		"id": "FE2ABF52-2BBD-C1B2-E145-C71231A5C841"
	},
	{
		"fullName": "Camden Hopper",
		"position": "Media Relations",
		"companyName": "Faucibus Lectus A Industries",
		"phone": "866-3861",
		"email": "in.magna@tortorIntegeraliquam.co.uk",
		"tags": [{"id":"soups","color":"#09f","name":"Shared"}, {"id":"desserts","color":"#990","name":"Networking"}, {"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"pies","color":"#999","name":"Family"}],
		"id": "22404F68-4882-908C-C6E7-4323AC293EAD"
	},
	{
		"fullName": "Catherine Ellis",
		"position": "Finances",
		"companyName": "Semper Consulting",
		"phone": "333-6330",
		"email": "gravida.nunc.sed@ami.net",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "9C7A0A06-3688-B79B-1C23-06566267A5A2"
	},
	{
		"fullName": "Oren Jones",
		"position": "Finances",
		"companyName": "Nunc Mauris Morbi PC",
		"phone": "985-5516",
		"email": "ipsum@euelit.net",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"pies","color":"#999","name":"Family"}, {"id":"salads","color":"#000","name":"Australia"}, {"id":"stews","color":"#00f","name":"Europe"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "0E992CB1-83AC-A223-1B2B-6B7483DAA480"
	},
	{
		"fullName": "Dominique Russo",
		"position": "Legal Department",
		"companyName": "Neque Associates",
		"phone": "1-535-994-2504",
		"email": "fringilla@odio.ca",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"stews","color":"#00f","name":"Europe"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "D609F4D9-ACAB-9769-2B2D-68AFA68D96E6"
	},
	{
		"fullName": "Len Houston",
		"position": "Customer Service",
		"companyName": "Luctus Et Incorporated",
		"phone": "1-574-973-8025",
		"email": "luctus.ut@ut.net",
		"tags": [],
		"id": "4C5145A1-B1EB-5976-8D16-522AF0EC4D58"
	},
	{
		"fullName": "Wendy Morrow",
		"position": "Finances",
		"companyName": "Mauris Eu Turpis Industries",
		"phone": "1-891-830-2919",
		"email": "malesuada@semperegestasurna.com",
		"tags": [{"id":"seafood","color":"#f90","name":"Foodtech"}, { "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"pies","color":"#999","name":"Family"}, {"id":"cereals","color":"#ff0","name":"Asia"}],
		"id": "45A7B3D9-F2FD-2378-B80C-32D05BFEAEED"
	},
	{
		"fullName": "Aaron Bernard",
		"position": "Public Relations",
		"companyName": "Neque In Corporation",
		"phone": "840-6157",
		"email": "fermentum.risus.at@justoPraesent.co.uk",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"desserts","color":"#990","name":"Networking"}, {"id":"noodles","color":"#0f0","name":"Green"}, {"id":"soups","color":"#09f","name":"Shared"}],
		"id": "1D9FF703-5CBE-C485-3CA7-5123000A4589"
	},
	{
		"fullName": "Rina Wagner",
		"position": "Legal Department",
		"companyName": "Sed Consequat Industries",
		"phone": "1-290-587-5745",
		"email": "lacus.Aliquam@Uttinciduntorci.net",
		"tags": [],
		"id": "9CDE0803-963A-54AE-FD1C-8C7F9A29FF96"
	},
	{
		"fullName": "Xanthus Mcdonald",
		"position": "Asset Management",
		"companyName": "Aliquet Proin Ltd",
		"phone": "1-505-630-9985",
		"email": "eget.metus.In@orciUt.edu",
		"tags": [],
		"id": "EE631E6B-F0AE-1CB2-A7B7-319F2A2B9D08"
	},
	{
		"fullName": "Jamal Baird",
		"position": "Asset Management",
		"companyName": "Vestibulum Inc.",
		"phone": "469-1645",
		"email": "interdum.libero.dui@fermentum.co.uk",
		"tags": [{"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"salads","color":"#000","name":"Australia"}, { "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"stews","color":"#00f","name":"Europe"}, {"id":"pies","color":"#999","name":"Family"}],
		"id": "6D27A964-0F25-7052-4D13-566626FE7BB0"
	},
	{
		"fullName": "Christen Grant",
		"position": "Advertising",
		"companyName": "Rutrum Ltd",
		"phone": "1-366-627-7550",
		"email": "metus.Aliquam@DonectinciduntDonec.net",
		"tags": [{"id":"desserts","color":"#990","name":"Networking"}, {"id":"cereals","color":"#ff0","name":"Asia"}],
		"id": "53EF7E65-9F47-EE02-D6BB-109F5D99BB49"
	},
	{
		"fullName": "Cade Ortega",
		"position": "Payroll",
		"companyName": "Curabitur Consequat Lectus Ltd",
		"phone": "1-984-208-9336",
		"email": "Donec@Suspendisse.edu",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, { "id":"pasta","color":"#f00","name":"Telecom"}],
		"id": "55AA84F3-0608-DC65-EBF4-063D54B2F08E"
	},
	{
		"fullName": "Garth Watkins",
		"position": "Customer Relations",
		"companyName": "Integer Institute",
		"phone": "1-550-405-5756",
		"email": "nulla.Integer@etrisus.edu",
		"tags": [{"id":"desserts","color":"#990","name":"Networking"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "4935CFCC-1225-4AAD-3763-B5C8C389CD6D"
	},
	{
		"fullName": "Kelsey Rosario",
		"position": "Finances",
		"companyName": "Massa Corporation",
		"phone": "1-692-493-6646",
		"email": "sollicitudin.adipiscing@variusNam.co.uk",
		"tags": [{"id":"pies","color":"#999","name":"Family"}, {"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"soups","color":"#09f","name":"Shared"}],
		"id": "EB537CC5-BE6D-3876-D610-7F074BD1D118"
	},
	{
		"fullName": "Irma Garrett",
		"position": "Sales and Marketing",
		"companyName": "Enim Commodo Consulting",
		"phone": "1-445-118-6090",
		"email": "lectus@acmieleifend.com",
		"tags": [{"id":"salads","color":"#000","name":"Australia"}, { "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"stews","color":"#00f","name":"Europe"}],
		"id": "3A96E68B-2DD0-4697-2B03-3DD7076AD27F"
	},
	{
		"fullName": "Dominic Rodriquez",
		"position": "Legal Department",
		"companyName": "Ipsum Incorporated",
		"phone": "622-9807",
		"email": "lorem@magnisdis.net",
		"tags": [{"id":"stews","color":"#00f","name":"Europe"}, {"id":"salads","color":"#000","name":"Australia"}, {"id":"desserts","color":"#990","name":"Networking"}],
		"id": "42EDB2D5-380B-7387-E23E-C65FF45925B7"
	},
	{
		"fullName": "Shafira Mckee",
		"position": "Public Relations",
		"companyName": "Pellentesque Tellus Sem PC",
		"phone": "1-537-530-8747",
		"email": "lorem.auctor@nonhendrerit.net",
		"tags": [{"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"desserts","color":"#990","name":"Networking"}, { "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"stews","color":"#00f","name":"Europe"}, {"id":"soups","color":"#09f","name":"Shared"}],
		"id": "4889CBE6-B0A1-2899-26B6-6F8E088D0883"
	},
	{
		"fullName": "Yuli Andrews",
		"position": "Research and Development",
		"companyName": "Orci Phasellus Dapibus Corporation",
		"phone": "1-926-794-4928",
		"email": "aliquet.metus.urna@nisiaodio.com",
		"tags": [{"id":"soups","color":"#09f","name":"Shared"}, {"id":"stews","color":"#00f","name":"Europe"}, {"id":"sandwiches","color":"#099","name":"Local"}, {"id":"desserts","color":"#990","name":"Networking"}, {"id":"seafood","color":"#f90","name":"Foodtech"}],
		"id": "883B16FB-C554-1834-B18E-8571F9746F45"
	},
	{
		"fullName": "Lynn Farmer",
		"position": "Research and Development",
		"companyName": "Odio Nam Consulting",
		"phone": "994-2642",
		"email": "sagittis.augue@lobortisultricesVivamus.org",
		"tags": [{"id":"soups","color":"#09f","name":"Shared"}, {"id":"desserts","color":"#990","name":"Networking"}],
		"id": "836F0BA6-2A2E-CA2D-0F4E-B2FDCB04B04D"
	},
	{
		"fullName": "Kaye King",
		"position": "Tech Support",
		"companyName": "Tristique Ac Company",
		"phone": "444-3391",
		"email": "ornare.Fusce.mollis@aodiosemper.org",
		"tags": [{"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"salads","color":"#000","name":"Australia"}, {"id":"pies","color":"#999","name":"Family"}, { "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"cereals","color":"#ff0","name":"Asia"}],
		"id": "0BE143F3-2596-D1A7-496C-3C5F4EF96986"
	},
	{
		"fullName": "Tashya Greene",
		"position": "Asset Management",
		"companyName": "Orci Ltd",
		"phone": "1-349-795-0966",
		"email": "gravida.nunc@eu.edu",
		"tags": [{"id":"salads","color":"#000","name":"Australia"}, {"id":"sandwiches","color":"#099","name":"Local"}, {"id":"stews","color":"#00f","name":"Europe"}, {"id":"pies","color":"#999","name":"Family"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "6B843EC7-9A06-84A1-8624-F5F948D78A23"
	},
	{
		"fullName": "Judah Curry",
		"position": "Quality Assurance",
		"companyName": "Arcu Company",
		"phone": "767-0670",
		"email": "sed.dictum.eleifend@nibh.net",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}],
		"id": "DC3BC252-8B0B-44C6-9D0D-1FDA60FA8FF7"
	},
	{
		"fullName": "Elizabeth Mckee",
		"position": "Research and Development",
		"companyName": "Tortor Dictum Eu Associates",
		"phone": "1-104-318-4673",
		"email": "Nulla.tincidunt.neque@orciin.com",
		"tags": [],
		"id": "26A7D479-1792-2097-2709-413D2108419B"
	},
	{
		"fullName": "Prescott Mccoy",
		"position": "Asset Management",
		"companyName": "Non Sapien Molestie Consulting",
		"phone": "1-552-242-1954",
		"email": "dolor.sit.amet@miloremvehicula.net",
		"tags": [{"id":"desserts","color":"#990","name":"Networking"}, { "id":"pasta","color":"#f00","name":"Telecom"}],
		"id": "CD51B7E8-15B2-907D-97EE-B8392CE8CEE4"
	},
	{
		"fullName": "Gannon Glenn",
		"position": "Advertising",
		"companyName": "Donec PC",
		"phone": "953-3955",
		"email": "nulla@sit.com",
		"tags": [{"id":"stews","color":"#00f","name":"Europe"}, {"id":"sandwiches","color":"#099","name":"Local"}, {"id":"salads","color":"#000","name":"Australia"}, {"id":"soups","color":"#09f","name":"Shared"}],
		"id": "7D1F040F-1E9F-7CD0-5D58-23CAB9DF6399"
	},
	{
		"fullName": "Guy Pratt",
		"position": "Legal Department",
		"companyName": "Nisl Associates",
		"phone": "583-1563",
		"email": "ut.aliquam.iaculis@cursus.edu",
		"tags": [{"id":"pies","color":"#999","name":"Family"}, {"id":"noodles","color":"#0f0","name":"Green"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"stews","color":"#00f","name":"Europe"}],
		"id": "8E6CD694-1D36-AD92-4830-D3989F8EBA9B"
	},
	{
		"fullName": "Kamal Anthony",
		"position": "Customer Relations",
		"companyName": "Lectus Rutrum Urna Consulting",
		"phone": "1-362-411-8741",
		"email": "nunc.sed@seddictum.org",
		"tags": [{"id":"noodles","color":"#0f0","name":"Green"}, {"id":"soups","color":"#09f","name":"Shared"}],
		"id": "D48BF0FE-730A-78CF-0514-F101F5333F53"
	},
	{
		"fullName": "Rina Dunlap",
		"position": "Asset Management",
		"companyName": "Sagittis Placerat Corp.",
		"phone": "1-405-160-6486",
		"email": "sit.amet.nulla@in.net",
		"tags": [{"id":"desserts","color":"#990","name":"Networking"}],
		"id": "8211CAF7-B98B-4DCD-A4F5-1A0F549B2754"
	},
	{
		"fullName": "Carter Rodriquez",
		"position": "Sales and Marketing",
		"companyName": "Donec Corp.",
		"phone": "1-993-689-8703",
		"email": "dignissim.lacus@malesuadaIntegerid.net",
		"tags": [],
		"id": "C0BE7D70-E8F7-A000-F134-05517A533565"
	},
	{
		"fullName": "Ainsley Fitzgerald",
		"position": "Payroll",
		"companyName": "Felis Institute",
		"phone": "1-167-488-7172",
		"email": "ut.odio.vel@ametluctus.edu",
		"tags": [{"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"desserts","color":"#990","name":"Networking"}, {"id":"stews","color":"#00f","name":"Europe"}, {"id":"cereals","color":"#ff0","name":"Asia"}],
		"id": "043D995E-7BCD-78AD-CDE8-8A3051F08826"
	},
	{
		"fullName": "Yuli Raymond",
		"position": "Finances",
		"companyName": "Lectus Consulting",
		"phone": "386-1080",
		"email": "sagittis.Duis.gravida@Integer.edu",
		"tags": [{"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "CF35A33F-6421-E0C7-503C-538B238F92DE"
	},
	{
		"fullName": "Whoopi Kent",
		"position": "Asset Management",
		"companyName": "Mi Incorporated",
		"phone": "831-4115",
		"email": "eu.elit@vulputateposuere.com",
		"tags": [{"id":"desserts","color":"#990","name":"Networking"}, {"id":"salads","color":"#000","name":"Australia"}],
		"id": "F72DF605-D9F6-545D-ECF4-14900138C5C5"
	},
	{
		"fullName": "Samson Rowland",
		"position": "Quality Assurance",
		"companyName": "Magna Tellus Inc.",
		"phone": "579-8688",
		"email": "rutrum.urna@pellentesquemassa.ca",
		"tags": [{"id":"sandwiches","color":"#099","name":"Local"}, {"id":"soups","color":"#09f","name":"Shared"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"stews","color":"#00f","name":"Europe"}],
		"id": "297C5593-1D59-4C5F-7D94-571C009E6E43"
	},
	{
		"fullName": "Noah Frost",
		"position": "Human Resources",
		"companyName": "Facilisis Magna Industries",
		"phone": "978-5212",
		"email": "diam@cubiliaCurae.com",
		"tags": [{"id":"stews","color":"#00f","name":"Europe"}, {"id":"desserts","color":"#990","name":"Networking"}, {"id":"salads","color":"#000","name":"Australia"}],
		"id": "F0B7A64A-71BA-1CF7-8357-E27CFA30EC18"
	},
	{
		"fullName": "Clinton Melton",
		"position": "Research and Development",
		"companyName": "Cursus Vestibulum Mauris Corp.",
		"phone": "160-0392",
		"email": "ut@Aeneanegestashendrerit.net",
		"tags": [],
		"id": "1B6D750A-66EF-2F32-2E4B-CC6257C81E21"
	},
	{
		"fullName": "Calista Mcfarland",
		"position": "Advertising",
		"companyName": "Eu Ultrices Corp.",
		"phone": "1-741-893-9140",
		"email": "ipsum.Donec@interdum.org",
		"tags": [{"id":"sandwiches","color":"#099","name":"Local"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "5231AECC-51FE-1CA9-F694-7420340BA761"
	},
	{
		"fullName": "Nolan Cline",
		"position": "Customer Service",
		"companyName": "Elit Company",
		"phone": "914-6185",
		"email": "lacus.Aliquam@velit.ca",
		"tags": [{"id":"pies","color":"#999","name":"Family"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "104C06B0-E2A8-D295-3A39-AFB244D9F1E6"
	},
	{
		"fullName": "Aristotle Burch",
		"position": "Media Relations",
		"companyName": "Aliquam Industries",
		"phone": "602-1435",
		"email": "Nunc.laoreet.lectus@consequat.net",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"pies","color":"#999","name":"Family"}, {"id":"soups","color":"#09f","name":"Shared"}],
		"id": "204E5C53-AF4A-F574-25C0-0D379D39D816"
	},
	{
		"fullName": "Shelley Silva",
		"position": "Accounting",
		"companyName": "Risus At LLP",
		"phone": "1-269-258-5446",
		"email": "id@SuspendissesagittisNullam.com",
		"tags": [],
		"id": "346F9458-12CC-9757-3081-DD4DF02AA54C"
	},
	{
		"fullName": "Basil Hays",
		"position": "Media Relations",
		"companyName": "Mollis Nec Cursus Corp.",
		"phone": "395-1074",
		"email": "eu.dui.Cum@vitaenibhDonec.ca",
		"tags": [{ "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"cereals","color":"#ff0","name":"Asia"}],
		"id": "A33105A2-FED1-65DD-F15C-199ED7BE8488"
	},
	{
		"fullName": "Glenna Mcclain",
		"position": "Advertising",
		"companyName": "Proin Non Industries",
		"phone": "1-253-402-1074",
		"email": "Etiam.laoreet.libero@libero.edu",
		"tags": [{"id":"noodles","color":"#0f0","name":"Green"}, {"id":"desserts","color":"#990","name":"Networking"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"pies","color":"#999","name":"Family"}, {"id":"stews","color":"#00f","name":"Europe"}],
		"id": "5595846D-48C2-1E3C-009A-6685E06CBAE5"
	},
	{
		"fullName": "Linda Reilly",
		"position": "Media Relations",
		"companyName": "Scelerisque Dui Corp.",
		"phone": "1-637-332-9149",
		"email": "et@lobortis.net",
		"tags": [{"id":"pies","color":"#999","name":"Family"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"stews","color":"#00f","name":"Europe"}, {"id":"sandwiches","color":"#099","name":"Local"}],
		"id": "C0085074-1DAB-E49E-2B6E-081B49CFA728"
	},
	{
		"fullName": "Mari Atkins",
		"position": "Customer Service",
		"companyName": "Mollis Ltd",
		"phone": "1-224-304-5554",
		"email": "sapien.cursus@Sedcongue.co.uk",
		"tags": [{"id":"stews","color":"#00f","name":"Europe"}, {"id":"pies","color":"#999","name":"Family"}, {"id":"salads","color":"#000","name":"Australia"}, {"id":"seafood","color":"#f90","name":"Foodtech"}],
		"id": "C0C92A79-07F8-1F26-B6FE-469F94E445DB"
	},
	{
		"fullName": "Teagan Cobb",
		"position": "Payroll",
		"companyName": "Mauris Ut Quam Corp.",
		"phone": "1-397-240-1400",
		"email": "semper.dui.lectus@Duisvolutpat.edu",
		"tags": [{"id":"noodles","color":"#0f0","name":"Green"}, {"id":"stews","color":"#00f","name":"Europe"}, {"id":"salads","color":"#000","name":"Australia"}, { "id":"pasta","color":"#f00","name":"Telecom"}],
		"id": "ECE086C7-0073-374A-3D5C-5AA0EB81DB5E"
	},
	{
		"fullName": "Harrison Bowers",
		"position": "Public Relations",
		"companyName": "Ornare Lectus Corporation",
		"phone": "413-3224",
		"email": "ac.nulla@posuere.org",
		"tags": [{"id":"stews","color":"#00f","name":"Europe"}, {"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"salads","color":"#000","name":"Australia"}, {"id":"sandwiches","color":"#099","name":"Local"}],
		"id": "A85FC5EE-7D93-824A-1D23-3760EBA60DC7"
	},
	{
		"fullName": "Bradley Alford",
		"position": "Quality Assurance",
		"companyName": "Cursus Nunc Institute",
		"phone": "106-8006",
		"email": "orci.consectetuer@vehicularisus.co.uk",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}],
		"id": "5CBCF222-152A-0274-D60F-C0FE26540F75"
	},
	{
		"fullName": "Kuame Palmer",
		"position": "Asset Management",
		"companyName": "Eu PC",
		"phone": "1-732-978-7678",
		"email": "pharetra.nibh.Aliquam@vehicula.net",
		"tags": [{ "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"sandwiches","color":"#099","name":"Local"}, {"id":"soups","color":"#09f","name":"Shared"}, {"id":"pies","color":"#999","name":"Family"}, {"id":"salads","color":"#000","name":"Australia"}],
		"id": "7C10BCB0-0CD0-00D6-986E-79EB2FFE3DE4"
	},
	{
		"fullName": "Desirae Haney",
		"position": "Quality Assurance",
		"companyName": "Ullamcorper Nisl Arcu Consulting",
		"phone": "1-980-711-4816",
		"email": "placerat.velit@malesuadaaugueut.ca",
		"tags": [{"id":"soups","color":"#09f","name":"Shared"}],
		"id": "084A1C74-5591-992E-3712-144125A48877"
	},
	{
		"fullName": "Nigel Bowen",
		"position": "Finances",
		"companyName": "Nunc Associates",
		"phone": "994-5735",
		"email": "a@lorem.com",
		"tags": [{"id":"sandwiches","color":"#099","name":"Local"}],
		"id": "2099268E-79B9-2FF0-22A6-C9A8D3C14B8B"
	},
	{
		"fullName": "Maris Matthews",
		"position": "Payroll",
		"companyName": "Nec Diam Duis Limited",
		"phone": "1-483-137-2824",
		"email": "eget.ipsum@amalesuadaid.edu",
		"tags": [{"id":"seafood","color":"#f90","name":"Foodtech"}, { "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"soups","color":"#09f","name":"Shared"}, {"id":"cereals","color":"#ff0","name":"Asia"}],
		"id": "E7F3B0E4-D39A-DDD7-BD6E-AF039D4180CC"
	},
	{
		"fullName": "Clarke Nicholson",
		"position": "Research and Development",
		"companyName": "Taciti Sociosqu Industries",
		"phone": "679-6583",
		"email": "diam.Proin@penatibusetmagnis.edu",
		"tags": [{ "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"soups","color":"#09f","name":"Shared"}],
		"id": "EBAB3A1E-D600-B8E0-488F-5DD7D0C2C364"
	},
	{
		"fullName": "Liberty Nolan",
		"position": "Payroll",
		"companyName": "In Consulting",
		"phone": "1-109-805-6320",
		"email": "nec.enim.Nunc@enim.com",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"desserts","color":"#990","name":"Networking"}],
		"id": "B5C383B9-E3AC-5DD4-8E49-61FB98FAFDFD"
	},
	{
		"fullName": "Geoffrey Chang",
		"position": "Public Relations",
		"companyName": "Amet Risus Donec Foundation",
		"phone": "139-7613",
		"email": "sed.dui.Fusce@vitae.net",
		"tags": [{"id":"salads","color":"#000","name":"Australia"}, {"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"noodles","color":"#0f0","name":"Green"}, {"id":"soups","color":"#09f","name":"Shared"}],
		"id": "1172E371-A83D-10F8-E451-E61C4BA62013"
	},
	{
		"fullName": "Sonia Franklin",
		"position": "Legal Department",
		"companyName": "Eu Limited",
		"phone": "1-254-775-9791",
		"email": "magna.Nam.ligula@ipsum.co.uk",
		"tags": [],
		"id": "9C503C5A-C141-1BE2-B070-22BDF482FEA8"
	},
	{
		"fullName": "Dillon Kane",
		"position": "Sales and Marketing",
		"companyName": "Vitae Semper Incorporated",
		"phone": "1-691-561-2962",
		"email": "sed.est@penatibuset.edu",
		"tags": [{"id":"salads","color":"#000","name":"Australia"}, {"id":"soups","color":"#09f","name":"Shared"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "649F7F21-6BA5-51FB-12E8-D36AC5DB8047"
	},
	{
		"fullName": "Kathleen Bond",
		"position": "Public Relations",
		"companyName": "Eros Turpis Non PC",
		"phone": "1-580-867-8787",
		"email": "Nullam@DonecegestasDuis.com",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, { "id":"pasta","color":"#f00","name":"Telecom"}],
		"id": "D99031D7-981B-38B5-2F62-0D3E3B8F2B8D"
	},
	{
		"fullName": "Jemima Obrien",
		"position": "Advertising",
		"companyName": "Tincidunt Industries",
		"phone": "1-182-203-1657",
		"email": "eget.varius.ultrices@commodoat.edu",
		"tags": [],
		"id": "EDA181C0-8A55-1AF1-9991-D93E7496EE5A"
	},
	{
		"fullName": "Xander Wade",
		"position": "Customer Relations",
		"companyName": "Lectus Quis Limited",
		"phone": "1-502-502-1171",
		"email": "diam.luctus.lobortis@diamvelarcu.com",
		"tags": [],
		"id": "6083201D-4D91-A74D-B7CB-82C48ECBE873"
	},
	{
		"fullName": "Octavia Waters",
		"position": "Accounting",
		"companyName": "Feugiat Limited",
		"phone": "671-8731",
		"email": "ultricies.adipiscing@eratnonummy.ca",
		"tags": [],
		"id": "ACAD8D58-FC7D-9D1C-DCFA-3C2F68DBF5CC"
	},
	{
		"fullName": "Alec Luna",
		"position": "Media Relations",
		"companyName": "Nec Euismod In Corporation",
		"phone": "475-6329",
		"email": "auctor.ullamcorper.nisl@Namtempor.com",
		"tags": [{"id":"pies","color":"#999","name":"Family"}, {"id":"sandwiches","color":"#099","name":"Local"}, { "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"salads","color":"#000","name":"Australia"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "8D5FAEC2-7210-F643-2658-4094448CB1DC"
	},
	{
		"fullName": "Fletcher Haynes",
		"position": "Finances",
		"companyName": "Neque Associates",
		"phone": "1-773-465-2746",
		"email": "vitae.semper@gravidanuncsed.ca",
		"tags": [],
		"id": "0CD3A51A-2EA6-BA96-5B97-408767533A63"
	},
	{
		"fullName": "Craig Berg",
		"position": "Legal Department",
		"companyName": "Natoque Penatibus Foundation",
		"phone": "1-272-189-7278",
		"email": "faucibus@ullamcorperDuis.com",
		"tags": [{"id":"soups","color":"#09f","name":"Shared"}],
		"id": "33B13857-E1F7-3538-30EA-04D336382004"
	},
	{
		"fullName": "Claire Whitehead",
		"position": "Tech Support",
		"companyName": "Justo Proin Inc.",
		"phone": "1-768-516-2520",
		"email": "diam@nibhlaciniaorci.ca",
		"tags": [],
		"id": "A903B815-330F-B6D4-1D24-05A854C09EBB"
	},
	{
		"fullName": "Jerome Bullock",
		"position": "Customer Relations",
		"companyName": "Duis At Industries",
		"phone": "1-652-132-5133",
		"email": "massa.non.ante@eratneque.ca",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, { "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"desserts","color":"#990","name":"Networking"}, {"id":"salads","color":"#000","name":"Australia"}],
		"id": "7997C369-B872-174A-0BE3-89E29BF3FD87"
	},
	{
		"fullName": "Vaughan Whitfield",
		"position": "Payroll",
		"companyName": "Ipsum Curabitur LLC",
		"phone": "1-310-181-2104",
		"email": "posuere@Maurisblandit.ca",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"stews","color":"#00f","name":"Europe"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "742C5BB5-B6BB-A2E5-09CC-95AE698A45F1"
	},
	{
		"fullName": "Kermit Simpson",
		"position": "Sales and Marketing",
		"companyName": "Vel Pede Corp.",
		"phone": "231-4137",
		"email": "cursus@nuncinterdumfeugiat.edu",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"sandwiches","color":"#099","name":"Local"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "179BA0BA-4168-BB3A-6EBE-1CE6A4F639D0"
	},
	{
		"fullName": "Demetrius Mcpherson",
		"position": "Quality Assurance",
		"companyName": "Nullam Enim Sed Incorporated",
		"phone": "646-2217",
		"email": "nisl@dolordapibusgravida.ca",
		"tags": [],
		"id": "09F7285A-4F10-94CE-8C12-EE7EE029D00B"
	},
	{
		"fullName": "Gail Spears",
		"position": "Payroll",
		"companyName": "Ornare Consulting",
		"phone": "1-407-644-6502",
		"email": "natoque.penatibus@est.co.uk",
		"tags": [{"id":"desserts","color":"#990","name":"Networking"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"stews","color":"#00f","name":"Europe"}],
		"id": "52ECEAC7-28E5-EF9C-3092-155EBDEA6ECB"
	},
	{
		"fullName": "Valentine Whitley",
		"position": "Asset Management",
		"companyName": "Phasellus Associates",
		"phone": "1-542-784-8964",
		"email": "Suspendisse.aliquet.molestie@Infaucibus.edu",
		"tags": [{"id":"pies","color":"#999","name":"Family"}],
		"id": "22B00CF5-B256-86D8-E8D1-A580602E1B83"
	},
	{
		"fullName": "Kirk Lyons",
		"position": "Finances",
		"companyName": "Sagittis Duis LLC",
		"phone": "1-544-674-7865",
		"email": "Proin.sed.turpis@eu.org",
		"tags": [],
		"id": "DAF92053-C283-625A-C3CE-3238A5EE9515"
	},
	{
		"fullName": "Elijah Valenzuela",
		"position": "Tech Support",
		"companyName": "Ultricies Ornare Elit Institute",
		"phone": "1-142-570-0005",
		"email": "libero.Proin.sed@pharetraQuisqueac.ca",
		"tags": [],
		"id": "389B7F60-1165-1350-C2EC-8AAAFEE96C62"
	},
	{
		"fullName": "Celeste Fields",
		"position": "Sales and Marketing",
		"companyName": "Eu Limited",
		"phone": "1-440-365-1398",
		"email": "metus.In@faucibusMorbi.org",
		"tags": [{ "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"seafood","color":"#f90","name":"Foodtech"}],
		"id": "36E70307-80C7-CEFB-7E93-01CF04964D0B"
	},
	{
		"fullName": "Tate Wood",
		"position": "Research and Development",
		"companyName": "Non Ltd",
		"phone": "432-7755",
		"email": "litora@acsem.co.uk",
		"tags": [{"id":"pies","color":"#999","name":"Family"}, { "id":"pasta","color":"#f00","name":"Telecom"}],
		"id": "0922047E-DB12-51C9-A3D9-28ADFB5C89E6"
	},
	{
		"fullName": "Erica Neal",
		"position": "Finances",
		"companyName": "Amet Incorporated",
		"phone": "1-131-106-4852",
		"email": "molestie@Donectempus.co.uk",
		"tags": [{ "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"desserts","color":"#990","name":"Networking"}],
		"id": "3368D190-B97B-F3C0-6BFE-6C002DCF85E4"
	},
	{
		"fullName": "Jerry Robertson",
		"position": "Research and Development",
		"companyName": "A Arcu Corporation",
		"phone": "889-6785",
		"email": "iaculis.odio@CuraePhasellusornare.org",
		"tags": [{"id":"stews","color":"#00f","name":"Europe"}, {"id":"salads","color":"#000","name":"Australia"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "950C224D-3744-CB25-EE3E-E1E26833F760"
	},
	{
		"fullName": "Chandler Morrow",
		"position": "Sales and Marketing",
		"companyName": "Sit Amet PC",
		"phone": "223-7328",
		"email": "massa.non.ante@ullamcorpereu.net",
		"tags": [{"id":"stews","color":"#00f","name":"Europe"}, { "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"seafood","color":"#f90","name":"Foodtech"}],
		"id": "3457BFC3-72BE-5FDE-7DE2-793E744D245A"
	},
	{
		"fullName": "Jeanette Salas",
		"position": "Payroll",
		"companyName": "In Foundation",
		"phone": "150-4463",
		"email": "tellus@sollicitudincommodoipsum.co.uk",
		"tags": [{"id":"desserts","color":"#990","name":"Networking"}, {"id":"salads","color":"#000","name":"Australia"}],
		"id": "CCA05BD7-084F-28EB-4BA8-7E24D8C19B05"
	},
	{
		"fullName": "Baker Hays",
		"position": "Payroll",
		"companyName": "Eu Corporation",
		"phone": "579-2466",
		"email": "Duis.a.mi@convallisante.ca",
		"tags": [],
		"id": "38E92025-CC12-6575-57E4-028F942C6B55"
	},
	{
		"fullName": "Linus Dyer",
		"position": "Asset Management",
		"companyName": "Curabitur Dictum Phasellus Foundation",
		"phone": "1-404-538-8391",
		"email": "nisl.Maecenas.malesuada@eu.co.uk",
		"tags": [],
		"id": "FE301342-EDC7-B84E-0C79-EC7DC395FE37"
	},
	{
		"fullName": "Tanisha Long",
		"position": "Media Relations",
		"companyName": "Massa Lobortis Consulting",
		"phone": "989-9980",
		"email": "blandit.Nam.nulla@risusquisdiam.com",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}],
		"id": "712D5FC5-DC38-AB91-BA21-B085AA2A9A62"
	},
	{
		"fullName": "Calvin Mcintosh",
		"position": "Research and Development",
		"companyName": "Justo Incorporated",
		"phone": "701-6522",
		"email": "scelerisque@semperNam.org",
		"tags": [],
		"id": "57493085-0380-F51E-DDAB-3E88A42EF37C"
	},
	{
		"fullName": "Bruce Lott",
		"position": "Media Relations",
		"companyName": "Rutrum Justo Praesent LLC",
		"phone": "259-0354",
		"email": "habitant@vitaeposuere.ca",
		"tags": [{"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"soups","color":"#09f","name":"Shared"}, {"id":"noodles","color":"#0f0","name":"Green"}, {"id":"desserts","color":"#990","name":"Networking"}, {"id":"sandwiches","color":"#099","name":"Local"}],
		"id": "2DB4C848-F8D6-033B-A34F-1D06ED73F939"
	},
	{
		"fullName": "Solomon Branch",
		"position": "Public Relations",
		"companyName": "At Augue Id Company",
		"phone": "753-0035",
		"email": "magnis.dis.parturient@nuncnulla.ca",
		"tags": [{"id":"salads","color":"#000","name":"Australia"}, {"id":"cereals","color":"#ff0","name":"Asia"}, {"id":"noodles","color":"#0f0","name":"Green"}, { "id":"pasta","color":"#f00","name":"Telecom"}],
		"id": "FA7A97BC-5A2D-0652-C81C-3BFCEAB2F5B1"
	},
	{
		"fullName": "Kylan Ochoa",
		"position": "Research and Development",
		"companyName": "Ornare Libero At Corporation",
		"phone": "1-120-168-5984",
		"email": "dictum@cursusaenim.edu",
		"tags": [{"id":"stews","color":"#00f","name":"Europe"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"soups","color":"#09f","name":"Shared"}],
		"id": "1B322B8C-E389-5F3D-6D1B-B819F791CEF4"
	},
	{
		"fullName": "Zelenia Stafford",
		"position": "Asset Management",
		"companyName": "Facilisis Facilisis Magna LLP",
		"phone": "1-852-460-3682",
		"email": "orci@nectellusNunc.ca",
		"tags": [{"id":"salads","color":"#000","name":"Australia"}],
		"id": "9448228E-D5E3-5B41-81D1-378ABB2E3DF1"
	},
	{
		"fullName": "Nasim Russell",
		"position": "Advertising",
		"companyName": "Commodo Auctor Inc.",
		"phone": "1-296-276-7909",
		"email": "Morbi@mieleifend.org",
		"tags": [{"id":"soups","color":"#09f","name":"Shared"}],
		"id": "F84BE73E-F9C3-3175-7599-5CD1AB349B5F"
	},
	{
		"fullName": "Barrett Dawson",
		"position": "Media Relations",
		"companyName": "Nisl Sem Associates",
		"phone": "1-336-382-9113",
		"email": "mauris.blandit.mattis@idsapienCras.ca",
		"tags": [{"id":"salads","color":"#000","name":"Australia"}, {"id":"desserts","color":"#990","name":"Networking"}, {"id":"soups","color":"#09f","name":"Shared"}],
		"id": "46391E92-5D28-BA96-77D7-84405F584E77"
	},
	{
		"fullName": "Otto Buck",
		"position": "Accounting",
		"companyName": "Ultrices Posuere Associates",
		"phone": "724-4151",
		"email": "nisi.nibh@acarcu.edu",
		"tags": [{"id":"salads","color":"#000","name":"Australia"}, { "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"soups","color":"#09f","name":"Shared"}, {"id":"desserts","color":"#990","name":"Networking"}],
		"id": "8ABF02F2-60E2-840E-78C6-8F1479C83AF2"
	},
	{
		"fullName": "Sybill Meadows",
		"position": "Quality Assurance",
		"companyName": "Sed Industries",
		"phone": "1-294-752-5789",
		"email": "dolor@ipsum.com",
		"tags": [{"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "725069E4-D5EA-8C06-74C8-A0C529016F4A"
	},
	{
		"fullName": "Angelica Bright",
		"position": "Quality Assurance",
		"companyName": "Rutrum Non Foundation",
		"phone": "301-0122",
		"email": "id@odioNam.com",
		"tags": [{"id":"pies","color":"#999","name":"Family"}, {"id":"noodles","color":"#0f0","name":"Green"}, {"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"sandwiches","color":"#099","name":"Local"}],
		"id": "EE137E0F-C9FB-F386-FAF8-012A810040A5"
	},
	{
		"fullName": "Nathaniel Harrison",
		"position": "Human Resources",
		"companyName": "Libero Integer Foundation",
		"phone": "1-488-178-9098",
		"email": "nibh.dolor.nonummy@varius.co.uk",
		"tags": [{"id":"desserts","color":"#990","name":"Networking"}, {"id":"soups","color":"#09f","name":"Shared"}, { "id":"pasta","color":"#f00","name":"Telecom"}, {"id":"noodles","color":"#0f0","name":"Green"}],
		"id": "C9D18819-5574-909E-1844-C86448A6BC26"
	},
	{
		"fullName": "May Kaufman",
		"position": "Accounting",
		"companyName": "Sem Vitae Aliquam Institute",
		"phone": "1-171-403-7429",
		"email": "nunc.nulla.vulputate@orcilacusvestibulum.edu",
		"tags": [{"id":"seafood","color":"#f90","name":"Foodtech"}, {"id":"sandwiches","color":"#099","name":"Local"}],
		"id": "BFC70B66-2BA0-A338-EA8B-73E038B50423"
	}
].sort( () => Math.random() - .5 )

export const accounts = [
    {
        id: 1,
        fullName: 'Yandex',
        linkTitle: 'Yandex Management Team',
        image: '//i.imgur.com/MerzyLI.png',
    },
    {
        id: 2,
        fullName: 'Alexander Shulgin',
        image: '//i.imgur.com/8JkhsKr.jpg',
    },
]

export const tags = [
    {
        id: 1,
        name: 'IT Management',
        color: '#26b7ff',
    },
    {
        id: 2,
        name: 'Clients',
        color: '#7dd921',
    },
    {
        id: 3,
        name: 'Business Partners',
        color: '#ffbf40',
    },
]

export const sorting = {
    sortBy: [
        {
            id: 1,
            name: 'Link strength',
            slug: 'linkStrength',
        },
        {
            id: 2,
            name: 'First name',
            slug: 'firstName',
        },
        {
            id: 3,
            name: 'Last name',
            slug: 'lastName',
        },
        {
            id: 4,
            name: 'Company',
            slug: 'company',
        },
        {
            id: 5,
            name: 'Title',
            slug: 'title',
        },
    ],
    orderBy: [
        {
            id: 1,
            name: 'Ascending',
            slug: 'asc',
        },
        {
            id: 2,
            name: 'Descending',
            slug: 'desc',
        },
    ],
}  