export const exclude = keys =>
    (
        Array.isArray( keys )
            ? keys.forEach( key => exclude( key ) )
            : apply( { ...extract(), [keys]: null }, true )
    )

export const append = ( payload, check ) => apply( { ...extract(), ...payload }, check )

export const apply = ( payload, check ) =>
    {
        const
            vars = []

        Object.keys( payload )
            .filter( key => !check || !!payload[key] )
            .forEach( key => vars.push( `${key}=${payload[key].trim().replace( / /g, '+' )}` ) )

        const
            loc = vars.length < 1
                ? window.location.pathname
                : window.location.pathname + '?' + vars.join( '&' )

        window.history.replaceState( {}, document.title, loc )
    }

export const join = obj =>
    {
        const
            res = []

        Object.keys( obj ).forEach( key => !!obj[key] && res.push( key ) )

        return res.join( ',' )
    }

export const extract = specific =>
    {
        const
            split = window.location.search.replace( '?', '' ).split( '&' ),
            vars = {}

        if ( !split[0] ) return specific ? null : vars

        split.forEach( param => {
            const
                s = param.split( '=' ),
                key = s[0]

            let
                value = !!s[1] ? decodeURI( s[1].replace( /\+/g, ' ' ) ) : true

            vars[key] = value
        })

        return specific 
            ? vars[specific]
            : vars
    }

export const router = {
    push: url => window.history.pushState( {}, document.title, url ), 
    replace: url => window.history.replaceState( {}, document.title, url ), 
}