import React      from 'react'
import PropTypes  from 'prop-types'
import classNames from 'classnames'
import { Link }   from "react-router-dom"

import './list-item.scss'

class ListItem extends React.Component {
    static propTypes = {
        extraClassName: PropTypes.string,
        link:  PropTypes.string,
        url:   PropTypes.string,
        theme: PropTypes.string
    }

    render () {
        const 
            { extraClassName, theme, link, url, children } = this.props,
            className = classNames( 'list-item', extraClassName, theme && `${theme}-theme` )

        if ( link ) {
            return (
                <Link to={link} className={className}>
                    <span>{children}</span>
                </Link>
            )
        }

        if ( url ) {
            return (
                <a href={url} className={className}>
                    <span>{children}</span>
                </a>
            )
        }

        return (
            <span className={className}>
                <span>{children}</span>
            </span>
        )
    }
}

export default ListItem
