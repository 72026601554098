import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Table, Empty } from 'antd'

import { AdminContent, Spinner } from 'components'
import { generate, t, i18next, sorter, hash } from 'tools'

import columns from './columns'
import './todo-table.scss'

class TodoTable extends React.Component {

    static propTypes = {
        todos:  PropTypes.array,
        search: PropTypes.string
    }

    constructor ( props ) {
        super( props )

        this.state = {
            open: []
        }

        this.set = generate.set( this )
        this.columns = columns( this )

        i18next.on( 'languageChanged', this.updateColumns )
    }

    updateColumns = () => this.columns = columns( this )

    prepare = todos =>
        {
            const
                { search } = this.props,
                raw = (
                    todos
                        .filter( item => item.previous_task === null && item.parent_task === null )
                        .sort( sorter.weight( 'priority_type' ) )
                        .reverse()
                ),
                res = []

            raw.forEach( this.addSequence( todos, res ) )

            const
                filtered = search
                    ? res.filter( item => item.title.toLowerCase().indexOf( search.toLowerCase() ) > -1 )
                    : res

            return filtered
        }

    addSequence = ( todos, res, parent ) => item =>
        {
            const
                all = todos.filter( task => task.previous_task === item.id ),
                previous = todos.find( task => task.id === item.previous_task ),
                next = all[0]

            if ( previous && !!previous.completed_date ) {
                item.previous_done = true
            }
                
            if ( !!parent ) {
                item.first_task = parent.id
                item.is_next = true
            }

            this.addSubtasks( todos, res )( item )

            if ( !next ) return

            item.sequence = true
            this.addSequence( todos, res, parent || item )( next )
        }

    addSubtasks = ( todos, res ) => item => 
        {
            const
                subtasks = todos.filter( sub => sub.parent_task === item.id )

            item.subtasks = subtasks.length > 0

            res.push( item )

            ;( subtasks.length > 0 ) && ( 
                subtasks.forEach( sub => {
                    sub.parent = item
                    sub.is_sub = true
                    ;( item.first_task ) && ( sub.first_task = item.first_task )
                    res.push( sub )
                })
            )
            
            /*
            res.push({
                id: `add-subtask-${item.id}`,
                is_sub_add: true,
                previous_task: item.id,
                parent: item.id,
                is_sub: true,
                title: t( 'todo.todoAddSubTask' )
            })
            */
        }

    findTop = ( task, all ) =>
        {
            let
                current = task,
                searcher = t => t.id === current.previous_task

            while ( current.previous_task !== null ) {
                current = all.find( searcher )
            }

            return current.id
        }

    open = task => () =>
        {
            const
                { open } = this.state

            ;( open.indexOf( task.id ) > -1 )
                ? open.splice( open.indexOf( task.id ), 1 )
                : open.push( task.id )

            this.set.open([ ...open ])
        }

    onSort = ( p, f, sorter ) =>
        {
            const
                raw = hash.extract().sort,
                current = raw ? raw.split( ',' ) : ''

            if ( current !== sorter.field ) {
                ;( sorter.order )
                    ? hash.append({ sort: `${sorter.field},${sorter.order.replace( 'end', '' )}` })
                    : hash.exclude( 'sort' )
            }
        }

    content = ordered =>
        (
            <Table
                sticky
                rowKey     = "id"
                pagination = { false }
                showSorterTooltip = { false }
                scroll = {{ x: '100%' }}

                dataSource = { ordered }
                columns    = { this.columns }
                className  = "todo-table"
                
                locale     = {{
                    emptyIcon: null,
                    emptyText: this.empty()
                }}

                onChange = { this.onSort }

                onRow = {
                    row => ({
                        className: classNames(
                            row.is_sub && 'todo-sub-row',
                            row.is_next && 'todo-next-row',
                            row.previous_done && 'previous-done',
                            ( 
                                this.state.open.indexOf( row.parent_task ) > -1 || 
                                this.state.open.indexOf( row.first_task ) > -1 || 
                                this.props.search 
                            ) && 'visible'
                        )
                    })
                }
            />
        )

    empty = () =>
        (
            <Empty image={null} description={ t('global.noItemsMatchingFilters') }>
                {/*<Button type="primary" onClick={this.props.onReset}>Reset Filters</Button>*/}
            </Empty>
        )
    
    render () {
        const 
            { todos } = this.props
        
        return (
            <AdminContent>
                { 
                    todos
                        ? this.content( this.prepare( todos ) )
                        : (
                            <div className="center-container">
                                <Spinner />
                            </div>
                        )
                }
            </AdminContent>
        )
    }
}

export default TodoTable