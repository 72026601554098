import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { Input } from 'antd'

import { t } from 'tools'
import config from 'config'

import './edit-info-popup.scss'

const
    publicEmailCompany = '19f2ebdc-e0a8-11e6-a630-6a13bef2453b'

const DesignCard = ({ config, icon, onAction }) => {
    const        
        bg =  config.company_bg_color,
        text = config.company_bg_color === config.company_text_color 
            ? '#111' 
            : config.company_text_color,
        border = [ '#000', '#000000', 'black', 'rgb(0,0,0)'].includes( config.company_bg_color?.toLowerCase() )
            ? `1px solid ${config.company_bg_color !== config.company_custom_text_color ? config.company_custom_text_color : '#111111'}66`
            : (
                config.company_bg_color === config.company_text_color 
                    ? '1px solid #111' 
                    : `1px solid ${config.company_text_color}`
            )
            
    return (
        <div className="custom-design-line" onClick={onAction}>
            <div className='custom-design-card' style={{ backgroundColor: bg, border: border }}>
                <h4 style={{ color: text }}>{t('publicCard.MAIN_TEXT_COLOR')}</h4>
                <p style={{ color: text }}>{t('publicCard.SECONDARY_TEXT_COLOR')}</p>
            </div>
            {
                !!icon && (
                    <div className="custom-design-icon">
                        { icon }
                    </div>
                )
            }
        </div>
    )
}

const SelectDesignPopup = ({ visible, list, current, onSelect, onClose }) => {
    return (
        <div className={classNames( "edit-contact-popup", 'full-popup', visible && 'is-visible' )}>
            <div className='full-popup-header'>
                <span className='full-popup-close' onClick={onClose} />
                <h4>{ t('publicCard.CHOOSE_DESIGN') }</h4>
                <span className='full-popup-space' />
            </div>

            <ul className='designs-list'>
                {
                    list?.map( config => (
                        <DesignCard
                            key    = { config.id }

                            config = { config }
                            icon   = { config.id === current ? <div className='cdi-check'></div> : <div className='cdi-add'></div> }

                            onAction = { config.id === current ? null : () => onSelect( config.id )}
                        />
                    ))
                }
            </ul>
        </div>
    )
}

const EditPopup = ({ visible, data, designs, onSave, onClose }) => {
    const
        [ error, $error ] = useState({
            firstName: false,
            lastName: false,
            jobRole: false,
            company: false
        }),
        [ editData, $editData ] = useState( null ),
        [ selectDesign, $selectDesign ] = useState( null ),
        [ current, $current ] = useState( config.defaultDesign.id ),

        updateData = ( key, value ) =>
            $editData({
                ...editData,
                [key]: value
            })

    useEffect(() => {
        if ( visible ) {
            document.body.classList.add( 'scroll-lock' )
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }, 200)
        } else {
            document.body.classList.remove( 'scroll-lock' )
        }
    }, [visible])

    useEffect(() => {
        if ( data ) {
            const
                isPublic = !data.company_owner || data.company_owner.id === publicEmailCompany

            $editData({
                id: data.id,
                firstName: data.first_name,
                lastName: data.last_name,
                jobRole: data.position,
                company: isPublic ? data.company : data.company_owner?.name,
                editCompany: isPublic
            })

            $current( data.company_design?.id || 'default' )
        }
    }, [data])

    return (
        <>
            <div className={classNames( "edit-contact-popup", 'full-popup', visible && 'is-visible' )}>
                <div className='full-popup-header'>
                    <span className='full-popup-close' onClick={onClose} />
                    <h4>{ t('publicCard.EDIT_CARD') }</h4>
                    <span className='full-popup-save' onClick={() => onSave( editData, current )} />
                </div>

                <div className="edit-contact-content">
                    <h5>{t('publicCard.MANAGE_PERSONAL_DETAILS')}</h5>
                    <div className='edit-info-form'>         

                        <Input
                            value       = { editData?.firstName }
                            placeholder = { t(`publicCard.EDIT_FIRST_NAME`) }
                            className   = { error?.firstName && 'has-error' }

                            onChange    = { e => { $error({ ...error, firstName: false }); updateData( 'firstName', e.target.value ) }}
                        />           

                        <Input
                            value       = { editData?.lastName }
                            placeholder = { t(`publicCard.EDIT_LAST_NAME`) }
                            className   = { error?.lastName && 'has-error' }

                            onChange    = { e => { $error({ ...error, lastName: false }); updateData( 'lastName', e.target.value ) }}
                        />

                        <Input
                            value       = { editData?.jobRole }
                            placeholder = { t(`publicCard.EDIT_JOB_ROLE`) }
                            className   = { error?.jobRole && 'has-error' }

                            onChange    = { e => { $error({ ...error, jobRole: false }); updateData( 'jobRole', e.target.value ) }}
                        />

                        <Input
                            value       = { editData?.company }
                            placeholder = { t(`publicCard.EDIT_COMPANY_NAME`) }
                            className   = { error?.company && 'has-error' }
                            disabled    = { !editData?.editCompany }

                            onChange    = { e => { $error({ ...error, company: false }); updateData( 'company', e.target.value ) }}
                        />
                    </div>
                    {/*<Button className="save-card-button" onClick={() => onSave( editData )}>
                        {t('publicCard.SAVE_CHANGES')}
                    </Button>   */}
                </div>
                
                {
                    editData?.editCompany && (
                        <div className="edit-contact-content">
                            <h5>{t('publicCard.CUSTOMIZE_DESIGN')}</h5>
                            <DesignCard
                                config = { current === config.defaultDesign.id ? config.defaultDesign : ( designs?.find( config => config.id === current ) || config.defaultDesign ) }
                                icon = { <div className="cdi-right"></div> }

                                onAction = {() => $selectDesign( true )}
                            />
                        </div>
                    )
                }
            </div>
            <SelectDesignPopup
                visible = { selectDesign }
                list    = { designs ? [ config.defaultDesign, ...designs ] : [ config.defaultDesign ] }
                current = { current }

                onClose = { () => $selectDesign( false ) }
                onSelect = { id => { $current( id ); $selectDesign( false ) }}
            />
        </>
    )
}

export default EditPopup