/* IMPORT */
import { connect } from 'react-redux'
import { authActions, userActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    logged:  state.auth.logged,
    token:   state.auth.info.token,
    lastTry: state.auth.lastTry,
    msg:     state.auth.msg,
    info:    state.user.info
})

const allActions = {
    ...authActions,
    ...userActions
}

export default connect( mapStateToProps, allActions )