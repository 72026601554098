import React from 'react'
import { withRouter } from "react-router"
import { Button } from 'antd'
import classNames from 'classnames'
import Adjust from '@adjustcom/adjust-web-sdk'

import { StaticPage, Spinner, PublicCardHeader, PublicLoader } from 'components'
import { generate, hash, t, adjust, ui } from 'tools'
import config from 'config'

import AuthForm         from './components/AuthForm'
import CardContent      from './components/CardContent'
import Contacts         from './components/Contacts'
import LiteForm         from './components/LiteForm'
//import LoginForm        from './components/LoginForm/LoginForm'
import MailForm         from './components/MailForm'
import Onboarding       from './components/Onboarding'
import PublicCardFooter from './components/PublicCardFooter'
import TabeeAd          from './components/TabeeAd'

import AppleWallet from './applewallet.svg'

import connector from './connector'
import './public-card.scss'

class PublicCard extends React.Component {

    uploadRef = React.createRef()

    singleTimer = null

    constructor ( props ) {
        super( props )

        this.state = {
            name: null,
            email: '',
            personal: false,
            formOpen: false,
            userCard: null,
            sharing: false,
            loading: false,
            addOnload: null,
            avatarUpload: false,
            avatarCallback: null,
            singleRedirect: false,
            singleTime: 0
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        const
            { match, account, user } = this.props

        if ( !match.params.id ) {
            if ( window.location.pathname.indexOf( '/identity/' ) < 0 || !account ) {
                window.location = '/'
                return
            }
        }

        document.documentElement.classList.add('public-card-theme')

        if ( window.location.pathname.indexOf( '/identity/' ) === 0 && !!account && user?.cards?.length > 0 ) {
            window.location.href = `/identity/${user.cards[0].id}`
        } else {
            if ( match.params.id?.toLowerCase() !== 'new' ) {    
                this.props.getProjectConfig(t('global.projectID'))
                this.props.getPublicCard( match.params.id )
                //;( !!user ) && ( this.props.getCustomDesigns() )
            }
        }

        const
            preemail = window.location.search?.match(/(\?|&)?mail=([^@]+@[^.]+.[a-z]+)/)?.[2]

        ;( preemail ) && ( this.set.email( preemail ) )
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            { user, data, active, match, userCards, verify, search, migrated, needSave, activated, precreated, reloadContacts, shared, location, account } = this.props,
            { addOnload, singleRedirect, userCard } = this.state,
            isPromo = location.pathname.indexOf( '/promo/' ) === 0 || location.pathname.indexOf( '/digital/' ) === 0,
            isMine = !!userCard && userCard.id === data?.id

        if ( shared !== prevProps.shared && shared ) {
            setTimeout(() => {
                document.querySelector( '.public-card-info' ).scrollIntoView({ behavior: 'smooth', block: 'nearest' })
            }, 300)
        }

        if ( reloadContacts !== prevProps.reloadContacts ) {
            console.log( reloadContacts )
            this.props.getUserContacts()
            ;( activated )
                ? this.props.getActivated( match.params.id )
                : this.props.getPublicCard( match.params.id )
        }

        if ( needSave !== prevProps.needSave && needSave ) {
            ;( activated )
                ? (
                    this.props.updateCardReload({
                        id: activated.id,
                        shared_contacts: activated.shared_contacts.map( c => c.id )
                    })
                )
                : (
                    this.props.updateCardReload({
                        id: data.id,
                        shared_contacts: data.shared_contacts.map( c => c.id )
                    })
                )
        }

        if ( precreated !== prevProps.precreated && precreated ) {
            window.location.href = isPromo ? `/promo/${precreated.card.id}` : `/identity/${precreated.card.id}`
            return
        }

        if ( userCards !== prevProps.userCards && userCards ) {
            const
                filtered = userCards.filter( card => !card?.is_archive && !card?.is_deleted && !card?.parent && !card?.migrated_to )

            ;( filtered[0] ) && ( this.setState({ userCard: filtered[0] }) )
        }

        if ( user !== prevProps.user && user && user.id && !data?.is_lite ) {
            this.set.formOpen( true )
            
            Adjust.addGlobalCallbackParameters([
                { key: 'userID', value: user.id }, 
            ])
        }

        if ( data !== prevProps.data ) { 
            if ( data.migrated_to ) {
                window.location.href = isPromo ? `/promo/${data.migrated_to}` : `/identity/${data.migrated_to}`
            }

            if ( isMine ) {
                this.set.state({
                    formOpen: false,
                    avatarUpload: false,
                })

                if ( data.single_contact !== prevProps.data?.single_contact ) {
                    if ( !data.single_contact && !!singleRedirect ) {
                        this.set.singleRedirect( false )
                    } else if ( !!data.single_contact && !singleRedirect ) {
                        this.set.singleRedirect( true )
                    }
                }
            }
        }

        if ( userCard !== prevState.userCard ) {
            if ( data?.single_contact && !singleRedirect && userCard ) {
                if ( isMine ) {
                    this.set.singleRedirect( true )   
                } else {
                    this.set.state({
                        singleRedirect: true,
                        singleTime: 5
                    })
                    this.singleTimer = setInterval( this.singleCountdown, 1000 )
                }
            }
            
            //;( !!user && !!account && isMine ) && ( this.props.getCustomDesigns() )
        }

        if ( active !== prevProps.active ) {
            if ( active?.card ) {
                this.props.updateCard({
                    id: active.card.id,
                    shared_contacts: [ active.card.email?.id, active.card.phone?.id ].filter( v => !!v )
                })
            }
            this.props.getActivated( match.params.id )
            //;( !!user ) && ( this.props.getCustomDesigns() )
        }

        if ( activated !== prevProps.activated && activated ) {
            if ( addOnload ) {  
                this.props.createContact({
                    type:    "email",
                    contact: addOnload,
                    subtype: ""
                })

                this.set.addOnload( null )
            }

            if ( activated.single_contact !== prevProps.activated?.single_contact ) {
                if ( !activated.single_contact && !!singleRedirect ) {
                    this.set.singleRedirect( false )
                } else if ( !!activated.single_contact && !singleRedirect ) {
                    this.set.singleRedirect( true )
                }
            }

            this.set.avatarUpload( false )
        }

        if ( ( shared !== prevProps.shared && shared ) || ( activated !== prevProps.activated && activated ) ) {
            const
                addSocial = localStorage.getItem( 'tabee-social-contact' )

            if ( addSocial ) {
                this.props.createContact({
                    type: 'other',
                    contact: addSocial
                })
                localStorage.removeItem( 'tabee-social-contact' )
            }
        }

        if ( verify !== prevProps.verify || search !== prevProps.search ) {
            this.set.formOpen( true )
        }

        if ( migrated !== prevProps.migrated && migrated ) {
            window.location.href = isPromo ? `/promo/${migrated.card.id}?migrated` : `/identity/${migrated.card.id}?migrated`
        }

        if ( search !== prevProps.search && search && search.length > 0 ) {
            console.log( search[0], data )
            if ( search[0].owner_user && search[0].owner_user.id !== data?.owner_user?.id ) {
                adjust.track( 'form_existing' )
                
                this.props.sharePublicContacts({
                    "owner": search[0].id,
                    "recipient": match.params.id
                })

                this.setState({ sharing: true })
            } else {                              
                adjust.track( 'form_self' )  
                window.location.search = window.location.search[0] === '?'
                    ? `${window.location.search}&own_card`
                    : '?own_card'
            }
        }
    }

    singleCountdown = () =>
        {
            const
                { data } = this.props,
                { singleRedirect, singleTime } = this.state,
                time = singleTime - 1
                
            if ( singleRedirect ) {
                this.set.singleTime( time )
                
                if ( time <= 0 ) {
                    clearInterval( this.singleTimer )
                    window.location.href = data.single_contact.contact
                }
            }
        }

    stopRedirect = () =>
        {
            clearInterval( this.singleTimer )

            this.set.state({
                singleTime: 0,
                singleRedirect: false
            })
        }

    getErrorContent = () =>
        {
            const
                { error, data } = this.props,
                status = data?.error ? data.status : error.status

            switch ( status ) {
                case 404:
                    return <p>{t('publicCard.UserWithSpecifiedIDNotFound')}</p>
                default:
                    return <p>{t('publicCard.TryToReloadAPageOrComeBackLater')}</p>
            }
        }

    setMail = ( email, isPublic ) =>
        {
            const
                { verify, search, data } = this.props

            this.set.state({
                email,
                personal: isPublic,
                formOpen: !!verify && !!search && !data?.is_lite
            })
        }

    onShare = () =>
        {
            const
                { data } = this.props,
                { userCard } = this.state

            this.props.shareContacts({
                "owner_card": userCard.id,
                "recipient": data.id,
                "is_send": true, // всегда
                "is_hold": false, // всегда
                "source_type": "v2", // всегда
            })

            this.setState({ sharing: true })
            adjust.track( 'form_completed' )
        }

    createNewCard = () =>
        {
            this.props.precreateCard()
            this.set.loading( true )
        }

    checkAndActivate = ({ personal, ...data }) =>
        {
            if ( personal ) {
                this.set.addOnload( personal )
            }

            this.props.activateUser( data )
        }

    uploadAvatar = cb =>
        {
            const
                { user } = this.props

            ;( !!user ) && ( this.uploadRef.current.click() )
            this.set.avatarCallback( cb )
        }

    processUploadAvatar = e =>
        {
            const
                { user } = this.props,
                { avatarCallback } = this.state,
                file = e.target.files[0]

            if ( !!file ) {
                const
                    formData = new FormData()
                    
                formData.append( "avatar", file )
                formData.append( "id", user.id )
                
                this.props.updateAvatar( formData )
                this.set.avatarUpload( true )
            }

            if ( !!avatarCallback ) {
                avatarCallback()
                this.set.avatarCallback( null )
            }
        }

    setDesign = design =>
        {
            const
                { data } = this.props

            this.props.updateCard({
                id: data.id,
                company_design: design.id === 'default' ? null : design.id,
                is_branded: design.id === 'default' ? false : true
            })
        }

    render () {
        const
            { data, match, verify, user, steps, search, active, activated, shared, types, contacts, location, account, socials, onboarding, justCreated, customDesigns } = this.props,
            { email, personal, formOpen, userCard, sharing, loading, avatarUpload, singleRedirect } = this.state,
            name = `${data?.first_name} ${data?.last_name}`,
            isBeta = true,
            isMine = !!userCard && userCard.id === data?.id,
            isFound = location.pathname.indexOf( '/found/' ) === 0 && !isMine,
            isPromo = location.pathname.indexOf( '/promo/' ) === 0 || location.pathname.indexOf( '/digital/' ) === 0,
            isDigital = location.pathname.indexOf( '/digital/' ) === 0,
            isOwnMigrated = hash.extract( "migrated" ),
            selfSharing = hash.extract( "own_card" ) || ( data?.owner_user?.id === user?.id && !isMine ),
            showWallet = ( justCreated ) && ( steps?.data?.exchange?.owner_card || steps?.data?.exchange?.card?.id ) && ui.getCurrentOs() === 'ios',
            error = this.props.error || data?.error || shared?.error,
            showOnboard = ( isOwnMigrated || window.location.search.indexOf( '__show_onboard__' ) > -1 || ( isMine && !!userCard && !!user?.id && !isFound && data?.is_lite )),
            userEmail = ( Array.isArray( contacts) ? contacts : []).find( c => c.type === 'email' )?.contact

        if ( match.params?.id?.toLowerCase() === 'new' ) {
            return (
                <StaticPage extraClassName={classNames('public-card-page', isBeta && 'is-beta', document.body.className.indexOf( 'flow-lock') > -1 && 'flow-lock')}>
                    <div className="public-card-container">
                        <div className="public-card-wrap">
                            <div className={classNames("public-card-content", ( ( formOpen || ( user && user.id ) ) && !data?.is_lite ) && 'form-open', user && 'with-user-card', user && userCard?.id === data?.id && 'is-my-card' )}>
                                <PublicCardHeader />
                                <div className="big-create-button center-container">
                                    <Button type="primary" onClick={this.createNewCard}>
                                        {
                                            loading
                                                ? <Spinner size="small" />
                                                : t('publicCard.CREATE_NEW_CARD')
                                        }
                                    </Button>
                                </div>
                            </div>
                            <TabeeAd
                                data   = { data }
                                id     = { data?.id }
                                isLite = { data?.is_lite }
                            />
                            <PublicCardFooter />
                        </div>
                    </div>
                </StaticPage>
            )
        }
            
        return (
            <StaticPage extraClassName={classNames('public-card-page', isBeta && 'is-beta', singleRedirect && 'with-redirect')}>
                <div className="public-card-container">
                    <div className="public-card-wrap">
                        <div className={classNames("public-card-content", ( ( formOpen || ( user && user.id ) ) && !data?.is_lite ) && 'form-open', user && 'with-user-card', (( user && userCard?.id === data?.id) || isOwnMigrated ) && 'is-my-card' )}>
                            { ( !isMine && !isOwnMigrated ) && ( <PublicCardHeader /> ) }
                            {
                                data && !data.migrated_to && !data.error
                                    ? (
                                        data.is_lite
                                            ? (
                                                <>
                                                    <LiteForm
                                                        key     = 'form'
                                                        id      = { match.params.id }
                                                        done    = { !!active }
                                                        data    = { activated }
                                                        search  = { search }
                                                        company = { verify }
                                                        email   = { email }
                                                        socials = { socials }
                                                        loading = { avatarUpload }
                                                        
                                                        canUpload = { isMine }
                                                        isDigital = { isDigital }
                                                        isMine    = { isMine || isOwnMigrated }
                                                        onAvatar  = { isMine ? this.uploadAvatar : null }
                                                        
                                                        searchCard   = { this.props.searchCard }
                                                        clearErrors  = { this.props.clearStepsErrors }
                                                        clearCompany = { this.props.flushVerifyDomain } 
                                                        getCompany   = { this.props.verifyDomain }
                                                        onCreate     = { this.checkAndActivate }
                                                        onMigrate    = { this.props.migrateCard }
                                                    />
                                                    <input
                                                        key    = "avatarUpload"
                                                        ref    = { this.uploadRef } 
                                                        type   = "file" 
                                                        accept = ".png,.jpg,.jpeg"
                                                        style  = {{ opacity: 0, height: '0px', width: '0px' }}

                                                        onChange = { this.processUploadAvatar }
                                                    />
                                                </>
                                            )
                                            : ([
                                                    !isFound && (
                                                        <React.Fragment key="info">
                                                            <CardContent
                                                                key       = "info"
                                                                isMine    = { isMine || isOwnMigrated }
                                                                data      = { data }
                                                                name      = { name }
                                                                id        = { data?.id }
                                                                className = { userCard || !user?.id ? '' : 'is-loading' }
                                                                loading   = { avatarUpload }
                                                                canUpload = { isMine }

                                                                onAvatar = { isMine ? this.uploadAvatar : null }
                                                                onUpdate = { isMine ? this.props.updateCardReload : null }
                                                            />
                                                            {
                                                                isMine && (
                                                                    <input
                                                                        key    = "avatarUpload"
                                                                        ref    = { this.uploadRef } 
                                                                        type   = "file" 
                                                                        accept = ".png,.jpg,.jpeg"
                                                                        style  = {{ opacity: 0, height: '0px', width: '0px' }}

                                                                        onChange = { this.processUploadAvatar }
                                                                    />
                                                                )
                                                            }
                                                        </React.Fragment>
                                                    ),
                                                    ( data && isBeta && !data.is_lite && !isOwnMigrated ) && (
                                                        shared || selfSharing
                                                            ? (
                                                                <div className={classNames("public-card-form success-send", showWallet && 'with-wallet-btn')} key='form'>
                                                                    <h2>{
                                                                        selfSharing
                                                                            ? t('publicCard.NO_SHARING_SELF')
                                                                            : t('publicCard.THANKS_FOR_SHARING')
                                                                    }</h2>
                                                                    {
                                                                        ( showWallet ) && (
                                                                            <div className='wallet-container'>
                                                                                <p>{t('publicCard.DOWNLOAD_PREVIOUSLY_SAVED_CARD')}</p>
                                                                                <a href={`${config.api.url}/pb/${steps.data.exchange.owner_card}`} target="_blank" rel="noreferrer">
                                                                                    <img src={AppleWallet} alt="Add to Apple Wallet" />
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            ) 
                                                            : (
                                                                ( !!user?.id || ( email && search && search.length === 0 ) )
                                                                    ? (
                                                                        user && user.id
                                                                            ? (
                                                                                userCard
                                                                                    ? (
                                                                                        userCard.id !== data.id && !isFound
                                                                                            ? (
                                                                                                <CardContent
                                                                                                    withShare
                                                                                                    key  = 'form'
                                                                                                    data = { userCard }
                                                                                                    name = { `${userCard?.first_name} ${userCard?.last_name}` }
                                                                                                    id   = { userCard.id }
                                                                                                    working = { sharing }
                                                                                                    onShare = { this.onShare }
                                                                                                />
                                                                                            )
                                                                                            : null
                                                                                    )
                                                                                    : (
                                                                                        <Spinner key='form' />
                                                                                    )
                                                                            )
                                                                            : (
                                                                                <AuthForm
                                                                                    key       = 'form'
                                                                                    data      = { data }
                                                                                    company   = { verify }
                                                                                    user      = { user }
                                                                                    steps     = { steps }
                                                                                    target_id = { match.params.id }
                                                                                    event_id  = { hash.extract( 'event' ) }
                                                                                    email     = { email }
                                                                                    personal  = { personal }
                                                                                    socials   = { socials }

                                                                                    clearErrors  = { this.props.clearStepsErrors }
                                                                                    clearCompany = { this.props.flushVerifyDomain } 
                                                                                    onStep       = { this.props.stepHandlers }
                                                                                    onRegister   = { this.props.oneRegForAll }
                                                                                    getCompany   = { this.props.verifyDomain }
                                                                                />
                                                                            )
                                                                    )
                                                                    : (
                                                                        <MailForm
                                                                            key     = 'form'
                                                                            user    = { user }
                                                                            company = { verify }
                                                                            card    = { search }
                                                                            email   = { email }

                                                                            clearErrors = { this.props.clearStepsErrors }
                                                                            clearCard   = { this.props.flushSearchCard }
                                                                            searchCard  = { this.props.searchCard }
                                                                            getCompany  = { this.props.verifyDomain }
                                                                            setMail     = { this.setMail }
                                                                        />
                                                                    )
                                                            )
                                                    )
                                                ]
                                            )
                                    )
                                    : (
                                        error
                                            ? (
                                                <div className="error-content">
                                                    <h1>{t('publicCard.SOMETHING_WENT_WRONG')}</h1>
                                                    {
                                                        this.getErrorContent()
                                                    }
                                                </div>
                                            )
                                            : (
                                                <div className="public-card-center">
                                                    <Spinner />
                                                </div>
                                            )
                                    )
                            }
                        </div>
                        {
                            ((( data && !data.is_lite ) || activated ) && !isFound ) && (
                                <Contacts
                                    types    = { types }
                                    editable = { isMine }
                                    data     = { activated && userCard && userCard.id === data?.id ? activated : data }
                                    id       = { activated && userCard && userCard.id === data?.id ? activated?.id : data?.id }
                                    contacts = { contacts }
                                    shared   = { activated && userCard && userCard.id === data?.id ? activated?.shared_contacts : data?.shared_contacts }

                                    updateCard    = { this.props.updateCardReload }
                                    updateContact = { this.props.updateContact }
                                    createContact = { this.props.createContact }
                                />
                            )
                        }
                        {
                            ( !data || !data.is_lite || !!verify || search?.length > 0 ) && (
                                <TabeeAd
                                    data    = { data }
                                    id      = { data?.id }
                                    company = { verify }
                                    isLite  = { data?.is_lite || !!verify || search?.length > 0 }
                                    isMine  = { isMine || isOwnMigrated }
                                />
                            )
                        }
                        <PublicCardFooter />
                    </div>
                </div>
                {
                    ( onboarding && isPromo ) && (
                        <Onboarding
                            visible = { showOnboard }
                            config  = { onboarding }

                            user = {{
                                email: userEmail,
                                user_id: user?.id,
                                card_id: userCard?.id ?? data?.id,
                                logo: data?.company_design?.square_logo || data?.company_owner?.square_logo
                            }}

                            checks = {{
                                loggedin: !isOwnMigrated,
                                design: !!data?.company_design,
                                digital: isDigital,
                                promo: isPromo
                            }}

                            onAvatar = { this.uploadAvatar }
                            requestCorporate = { this.props.updateClaimCorp }
                            setDesign = { this.setDesign }
                            onFinish = {() => {
                                window.history.replaceState( {}, '', window.location.origin + window.location.pathname )
                                this.props.reloadCard( generate.uniq() )
                            }}
                        />
                    )
                }
                <PublicLoader
                    visible = { !error && !active && ( !data || ( account && !user ) || ( !!user?.id && !userCard && user?.cards?.length > 0 )) }
                />
                {
                    isMine
                        ? (
                            <div className={classNames( 'single-redirect-plate', singleRedirect && 'visible' )}>
                                { t('publicCard.SINGLE_REDIRECT')}
                                <span className='link' onClick={() => window.location.href = data.single_contact.contact}>
                                    {t('publicCard.OPEN_REDIRECT')}
                                </span>
                            </div>
                        )
                        : (
                            <div className={classNames( 'single-redirect-plate', singleRedirect && 'visible' )}>
                                <Spinner/>
                                { t('publicCard.REDIRECTING')}
                                <span className='link' onClick={this.stopRedirect}>
                                    {t('publicCard.STOP_REDIRECT')}
                                </span>
                            </div>
                        )
                }
            </StaticPage>
        )
    }
}

export default connector( withRouter( PublicCard ) )