import React, { Component } from 'react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import Adjust from '@adjustcom/adjust-web-sdk'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import TabeeAuth from '@tabee/frontend-auth'

import { Spinner } from 'components'
import { compare, users, request, i18next } from 'tools'
import config from 'config'

import PublicCard from 'pages/PublicCard/PublicCard'
import DigitalWallet from 'pages/DigitalWallet/DigitalWallet'
import AuthCheck from 'pages/AuthCheck/AuthCheck'

import connector from './appConnector'
import './app.scss'
import './styles/index.scss'

const checkPublic = ( location, rawCompare, initial = true ) => {
    const
        pages = [ 'identity', 'promo', 'found', 'digital' ],
        compare = rawCompare || (( res, key ) => res && location.pathname.toLowerCase().indexOf( `/${key}/` ) !== 0 )

    return pages.reduce( compare, initial )
}

class App extends Component {

    componentDidMount () {
        const
            { account, user, domains, location } = this.props,
            id = request.storage.get( 'user_id' )
            
        ;( id ) && ( this.props.getSelfUser() )

        if ( checkPublic( location )) {
            ;( !!account && !!user && account !== user.id && !domains[account] ) && ( this.props.getCompanyDomains( account ) )
        }

        i18next.on( 'languageChanged', () => this.forceUpdate() )

        TabeeAuth.setApi( config.api.url )
        TabeeAuth.setProject( config.api.project )
    }

    componentDidUpdate ( prevProps ) {
        const
            { user, current, account, companies, domains, tags, corpTags, location } = this.props
            
        if ( user !== prevProps.user && !!user ) {
            Adjust.addGlobalCallbackParameters([
                { key: 'userID', value: user.id }, 
                { key: 'language_local', value: i18next.language }, 
                { key: 'language', value: window.navigator.language }, 
                { key: 'email', value: user.email }
            ])
            
            ;( !current && account === user.id ) && ( this.props.setAccount( account ) )
            ;( !!account && account !== 'undefined' ) && ( users.load( this.props, !checkPublic( location ) ) )
        }

        ;( 
            ( companies !== prevProps.companies || user !== prevProps.user ) && 
            companies && user && 
            !current && !!account && account !== 'undefined' &&
            account !== user.id
        ) && (
            this.props.setAccount( account )
        )

        if ( checkPublic( location ) ) {
            ;( 
                ( companies !== prevProps.companies || user !== prevProps.user ) &&
                companies && user &&
                companies.length > 0
            ) && (
                this.props.getCompanyWallets()
            )

            if ( ( account !== prevProps.account && !!account && account !== 'undefined' && account === user.id ) ) {
                ;( !tags ) && ( this.props.getUserTags() )
            }

            if ( ( account !== prevProps.account || user !== prevProps.user ) && !!account && account !== 'undefined' && user && account !== user.id ) {
                ;( !domains[account] ) && ( this.props.getCompanyDomains( account ) )
                ;( !corpTags[account] ) && ( this.props.getCompanyTags( account ))
            }
        }
    }

    render () {
        const
            { token, current } = this.props,
            all = [
                ...config.navigation.admin,
                ...config.navigation.settings
            ],
            aliases = [],
            publicPage =  checkPublic( window.location, ( res, key ) => res || window.location.pathname.toLowerCase().indexOf( `/${key}/` ) > -1, false ),
            makeRoute = item => {
                if ( !users.free( item.path ) && ( !item.component || !current ) ) return null
                if ( !users.allowed( current, item ) ) {
                    if ( compare.path( window.location.pathname, item.path ) ) {
                        return (
                            <Route exact path={item.path} key="redirect">
                                <Redirect to={config.indexPage} />
                            </Route>
                        )
                    }
                    return null
                }

                if ( item.aliases ) {
                    item.aliases.forEach( alias => {
                        aliases.push({
                            ...item,
                            name: alias,
                            path: alias
                        })
                    })
                }
                
                return (
                    <Route
                        key = { item.name }
                        { ...item }
                    />
                )
            }

        if ( !token && !users.free( window.location.pathname.toLowerCase() ) && !publicPage ) {
            window.location = '/login'
            return null
        }

        if ( !current && !users.free( window.location.pathname.toLowerCase() ) && window.location.pathname !== '/' ) {
            return <div className="center-container"><Spinner/></div>
        }
            
        return (
            <div className="app-page">
                <Switch>
                    <Route exact path="/">
                        <Redirect to={config.indexPage} />
                    </Route>
                    <Route exact path="/identity">
                        <PublicCard />
                    </Route>
                    <Route exact path="/identity/:id">
                        <PublicCard />
                    </Route>
                    <Route exact path="/promo/:id">
                        <PublicCard />
                    </Route>
                    <Route exact path="/found/:id">
                        <PublicCard />
                    </Route>
                    <Route exact path="/digital/:id">
                        <PublicCard />
                    </Route>
                    <Route exact path="/identity/:id/*">
                        <PublicCard />
                    </Route>
                    <Route exact path="/promo/:id/*">
                        <PublicCard />
                    </Route>
                    <Route exact path="/found/:id/*">
                        <PublicCard />
                    </Route>
                    <Route exact path="/digital/:id/*">
                        <PublicCard />
                    </Route>
                    <Route exact path="/digital">
                        <DigitalWallet />
                    </Route>
                    <Route exact path="/auth/:mode">
                        <AuthCheck />
                    </Route>
                    {                        
                        config.navigation.global.map( makeRoute )
                    }
                    {
                        all.map( makeRoute )
                    }
                    {
                        aliases.filter(( item, index ) => aliases.findIndex( i => i.name === item.name ) === index ).map( item => (
                            <Route
                                key = { item.name }
                                { ...item }
                            />
                        ))
                    }
                    <Route exact path="/linkedin" component={LinkedInCallback} />
                </Switch>
            </div>
        )
    }
}

export default connector( withRouter( App ) )
