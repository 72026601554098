/* IMPORT */
import { connect } from 'react-redux'
import { authActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    resetSent: state.auth.resetSent,
    msg:       state.auth.msg,
    lastTry:   state.auth.lastTry
})

const allActions = {
    ...authActions
}

export default connect( mapStateToProps, allActions )