import React from 'react'

const Tel = ({ number, hide }) => {

    if ( !number ) return '—'

    const 
        phoneNumber = number.toString().replace( /\D+/g, '' ),
        countryCode = phoneNumber.slice( 0, phoneNumber.length - 10 ),
        local = [
            phoneNumber.slice( phoneNumber.length - 7, phoneNumber.length - 4 ),
            phoneNumber.slice( phoneNumber.length - 4 ),
        ],
        areaCode = phoneNumber.slice( phoneNumber.length - 10, phoneNumber.length - 7 ),
        formatted = `+${countryCode} ${areaCode} ${local[0]} ${local[1]}`
  
    return formatted.trim() === '+' && hide
        ? null
        : <a href={`tel:+${phoneNumber}`}>{ formatted.trim() === '+' ? '—' : formatted }</a>
}
  
export default Tel