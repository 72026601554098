import React from 'react'
import PropTypes from 'prop-types'
import clickOutside from 'react-onclickoutside'
import classNames from 'classnames'

import { generate } from 'tools'

import './slider-content.scss'

class SliderContent extends React.Component {

    static propTypes = {
        iconClassName:    PropTypes.string,
        contentClassName: PropTypes.string,

        onCollapse: PropTypes.func,
        onExpand:   PropTypes.func
    }

    constructor( props ) {
        super( props )

        this.state = {
            expanded: false
        }

        this.set = generate.set( this )
    }

    collapse = () =>
        {
            this.set.expanded( false )
            ;( this.props.onCollapse ) && ( this.props.onCollapse() )
        }

    handleClickOutside = this.collapse

    toggle = () =>
        {
            const
                { expanded } = this.state,
                newstate = !expanded 

            this.set.expanded( newstate )

            ;( newstate && this.props.onExpand ) && ( this.props.onExpand() )
            ;( !newstate && this.props.onCollapse ) && ( this.props.onCollapse() )
        }

    render() {
        const 
            { iconClassName, contentClassName, children } = this.props,
            { expanded } = this.state,
            controlClass = classNames( 'control-inner', expanded && 'expanded' ),
            iconClass    = classNames( 'icon', iconClassName ),
            contentClass = classNames( 'content', contentClassName, expanded && 'expanded' )

        return (
            <div className="slider-content">
                <div className="control">
                    <span
                        className = { controlClass }
                        onClick   = { this.toggle }
                    >
                        <span className={iconClass} />
                    </span>
                </div>

                <div className={contentClass}>
                    <div className="content-innter">
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}

export default clickOutside(SliderContent)
