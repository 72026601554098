import React from 'react'
import PropTypes from 'prop-types'
import { Input, Button, Badge } from 'antd'
import Icon, { SearchOutlined } from '@ant-design/icons'

import { 
    AdminHeader,
    //WalletsSharing, 
    ToolsRow,
    Spinner,
    Icons
} from 'components'

import { strings, t } from 'tools'

class WalletsHeader extends React.Component {

    static propTypes = {
        noFilters: PropTypes.bool,
        noSearch:  PropTypes.bool,

        loading:  PropTypes.bool,
        filters:  PropTypes.bool,
        filtered: PropTypes.bool,

        account:  PropTypes.object,
        search:   PropTypes.string,

        total:  PropTypes.number,
        count:  PropTypes.number,
        hidden: PropTypes.number,
        
        onTogglePanel: PropTypes.func,
        onSearch:      PropTypes.func,
        onFilters:     PropTypes.func,
        onReset:       PropTypes.func
    }

    render() {
        const 
            { account, count, total, hidden, loading, search, filtered, filters } = this.props,
            showFilters = !this.props.noFilters,
            showSearch = !this.props.noSearch

        return (
            <AdminHeader account={account} onTogglePanel={this.props.onTogglePanel}>
                <div className="title-fix">
                    { loading && <Spinner style={{ marginRight: 20 }} /> }
                    {
                        loading
                            ? (
                                count > 0 && !!total
                                    ? t( 'wallets.contactsLoaded', { count: Math.round(count/total*100) } )
                                    : t('wallets.loadingContacts')
                            )
                            : (
                                !!hidden
                                    ? t( 'global.outOfTotal', { count: count - hidden, total: count } )
                                    : (
                                        hidden === null
                                            ? t('global.noResult')
                                            : strings.encount( count, t('counters.countersContact') )
                                    )
                            )
                    }
                </div>
                
                <ToolsRow filters={filters}>
                    {
                        showSearch && (
                            <Input
                                allowClear
                                prefix = { <SearchOutlined /> }
                                value  = { search } 
                                placeholder = { t('global.search') }

                                onChange = { this.props.onSearch }
                            />
                        )
                    }
                    {
                        showFilters && (
                            <Badge dot={filtered} >
                                <Button 
                                    className = "smaller filters-btn"
                                    icon      = { <Icon component={Icons.Filter} /> } 
                                    onClick   = { this.props.onFilters }
                                />
                            </Badge>
                        )
                    }
                    {/*<WalletsSharing />*/}
                </ToolsRow>
            </AdminHeader>
        )
    }
}

export default WalletsHeader