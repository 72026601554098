import React from 'react'
import PropTypes from 'prop-types'
import { Drawer, Form, Input, Button } from 'antd'

import { FormSection, ContactCardViewer } from 'components'
import { normalize, t } from 'tools'
import config from 'config'

import './edit-member-form.scss'

class EditMemberForm extends React.Component {

    static propTypes = {
        open:     PropTypes.bool,
        editable: PropTypes.bool,

        data: PropTypes.object,

        onClose:  PropTypes.func
    }

    render () {
        const 
            { open, editable, data } = this.props,
            normalized = data ? normalize.member( data ) : {}

        return (
            <Drawer
                title        = ""
                placement    = "right"
                closable     = { false }
                getContainer = { false }
                className    = "drawer-form edit-member-form"

                visible = { open }
                style   = {{ position: 'absolute' }}
                width   = { 640 }

                onClose = { this.props.onClose }
            >
                <div className="edit-contact-form" style={{ width: 640 }}>
                    <header className="form-header">
                        <h3>{ editable ? t('team.teamEditMemberDetails') : t('team.teamMemberDetails') }</h3>
                        <Button onClick={this.props.onClose}>
                            { t('global.close') }
                        </Button>
                        {
                            editable && (
                                <Button type="primary" onClick={this.props.onSave}>
                                    { t('global.save') }
                                </Button>
                            )
                        }
                    </header>
                    <section className="form-content">
                        <ContactCardViewer
                            file    = { normalized.file }
                            photo   = { normalized.photo }
                            logo    = { normalized.companyLogo }
                        />
                        <Form { ...config.forms.layout } fields={normalize.form( normalized )}>

                            <FormSection>
                                <Form.Item name="firstName" label={t('columns.columnsFirstName')}>
                                    <Input disabled={!editable} />
                                </Form.Item>
                                <Form.Item name="lastName" label={t('columns.columnsLastName')}>
                                    <Input disabled={!editable} />
                                </Form.Item>
                            </FormSection>

                            <FormSection>
                                <Form.Item name="companyName" label={t('columns.columnsCompanyName')}>
                                    <Input disabled={!editable} />
                                </Form.Item>
                                <Form.Item name="position" label={t('columns.columnsPosition')}>
                                    <Input disabled={!editable} />
                                </Form.Item>
                            </FormSection>

                            <FormSection title={t('columns.columnsContacts')}>
                                <Form.Item name="email" label={t('columns.columnsEmail')}>
                                    <Input disabled={!editable} />
                                </Form.Item>
                                <Form.Item name="phone" label={t('columns.columnsPhone')}>
                                    <Input disabled={!editable} />
                                </Form.Item>
                            </FormSection>

                            <FormSection title={t('team.teamMemberSettings')}>
                                <Form.Item name="username" label={t('team.teamUsername')}>
                                    <Input disabled={!editable} />
                                </Form.Item>
                                <Form.Item name="role" label={t('team.teamAccountRole')}>
                                    <Input disabled={!editable} />
                                </Form.Item>
                                <Form.Item name="department" label={t('team.teamDepartment')}>
                                    <Input disabled={!editable} />
                                </Form.Item>
                            </FormSection>

                            <FormSection>
                                <Button className="fit-container" type="text">{t('team.archive')}</Button>
                                <Button className="fit-container" type="link" danger>{t('team.remove')}</Button>
                            </FormSection>
                        </Form>
                    </section>
                </div>
            </Drawer>
        )
    }
}

export default EditMemberForm