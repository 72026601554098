import React from 'react'
import { Link } from "react-router-dom"
import moment from 'moment'

import { t } from 'tools'
import config from 'config'

import './site-navigation.scss'

class SiteNavigation extends React.Component {

    item = item => (
        <li className="item" key={item.name}>
            <Link to={item.path} className="item-link">
                <span className="item-name">
                    { t(`navigation.${item.name}`) }
                </span>
            </Link>
        </li>
    )

    render () {
        return (
            <nav className="site-navigation">
                <ul className="items">
                    {
                        config.navigation.site.map( this.item )
                    }    
                </ul>
                <div className="legal">
                    <span>{ t('global.copyright', { year: moment().format( 'YYYY' ) }) }</span>
                </div>
            </nav>
        )
    }
}

export default SiteNavigation