import React from 'react'

import tabeeLogo from './tabeeLogo.png'
import './tabee-logo.scss'

const TabeeLogo = () => (
    <div className="tabee-logo">
        <img src={tabeeLogo} width="180" height="32" alt="Tabee" className="image" />
    </div>
)

export default TabeeLogo