import React from 'react'
import classNames from 'classnames'

import './tools-row.scss'

const ToolsRow = ({ children, filters, align }) => (
    <div className={classNames( "tools-row", align && `${align}-alignment`, filters && `filters-open` )}>
        { children }
    </div>
)

export default ToolsRow